import React, { useState, useRef } from 'react';

import { BELLINO_BASE_URL } from '../../Classes/Utilities';
import { MusicItemType } from '../../Types/MusicItemType';

import Button, { ButtonViewMode } from '../Button/Button';

export type SingleFileUploaderProps = {
    buttonText?: string;
    buttonViewMode?: ButtonViewMode;
    uploadType?: 'all' | 'image/*';
    itemType?: MusicItemType | 'User';
    itemId?: string;
    onFileUploaded?: (filePath: string) => void;
    onFileUploadError?: (error: string) => void;
};

const SingleFileUploader = (props: SingleFileUploaderProps) => {
    const { buttonText, buttonViewMode, uploadType, itemType, itemId, onFileUploaded, onFileUploadError } = props;

    const savethumb = uploadType === 'image/*' ? true : false;
    const uploadFileType = uploadType ?? 'all';
    const uploadDestURL = `${BELLINO_BASE_URL}/PHPLib/FileUpload${savethumb ? '?savethumb' : ''}`; //'https://httpbin.org/post';
    const inputFileElementRef = useRef(null);

    //const [file, setFile] = useState<File | null>(null);
    const [status, setStatus] = useState<'initial' | 'uploading' | 'success' | 'fail'>('initial');

    const handleFileSelection = () => {
        const inputFileElement = inputFileElementRef?.current ? (inputFileElementRef.current as HTMLInputElement) : null;

        if (inputFileElement) {
            inputFileElement.click();
        }
    };

    const handleSelectedFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setStatus('initial');
            //setFile(e.target.files[0]);
            handleUpload(e.target.files[0]);
        }
    };

    const handleUpload = async (fileToUpload: File) => {
        if (fileToUpload) {
            setStatus('uploading');
            //console.log('uploading: ', fileToUpload.name, fileToUpload.type, ` ${fileToUpload.size} bytes`);

            const formData = new FormData();
            formData.append('file', fileToUpload);

            if (itemId && itemType) {
                formData.append('itemtype', itemType);
                formData.append('itemid', itemId);
            }

            try {
                const result = await fetch(uploadDestURL, {
                    method: 'POST',
                    body: formData
                });

                const data = await result.text();

                if (data?.toLowerCase()?.startsWith('error:')) {
                    onFileUploadError?.(data);
                    setStatus('fail');
                } else if (data && onFileUploaded) {
                    onFileUploaded(data);
                    setStatus('success');
                }

                //console.log(data);
            } catch (error) {
                console.error(error);
                setStatus('fail');
            }
        }
    };

    return (
        <>
            <input
                ref={inputFileElementRef}
                id="file"
                type="file"
                className="hidden"
                accept={uploadFileType}
                onChange={handleSelectedFileChange}
            />
            <Button viewMode={buttonViewMode} isDisabled={status === 'uploading'} onClick={handleFileSelection}>
                {status === 'uploading' ? (
                    <>
                        <span>Uploading</span>
                        <i className="ms-50 spin fa-solid fa-circle-notch"></i>
                    </>
                ) : (
                    buttonText
                )}
            </Button>
            <Result status={status} />
        </>
    );
};

const Result = ({ status }: { status: string }) => {
    return null;

    /*  if (status === 'success') {
        return <p>✅ File uploaded successfully!</p>;
    } else if (status === 'fail') {
        return <p>❌ File upload failed!</p>;
    } else if (status === 'uploading') {
        return <p>⏳ Uploading selected file...</p>;
    } else {
        return null;
    }*/
};

export default SingleFileUploader;
