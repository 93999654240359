import React, { useEffect, useRef, useState } from 'react';

import { User } from '../../Classes/User';
import { getUser, updateUserDetails, updateUserPassword } from '../../Services/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Tabs, { TabItem } from '../../Components/Tabs/Tabs';

import SettingsTab from './partials/SettingsTab';
import PasswordTab from './partials/PasswordTab';

import classes from './Account.module.css';

function AccountPage(props: any) {
    usePageMetdata({ title: 'Account' });

    const { userSessionData } = useUserSessionData();

    const [selectedTab, setSelectedTab] = useState('Account Settings');
    const [user, setUser] = useState<User>();

    const isLoading = useRef<boolean>();

    useEffect(() => {
        const loadUser = async () => {
            if (userSessionData.userInfo && !isLoading.current) {
                isLoading.current = true;
                const userInfo = await getUser();
                setUser(new User(userInfo ?? userSessionData.userInfo));
                isLoading.current = false;
            }
        };

        loadUser();
    }, [userSessionData.userInfo]);

    if (userSessionData.isReady && !userSessionData.userInfo) {
        return userSessionData.isReady ? <NotFound></NotFound> : <></>;
    }

    const tabItems: TabItem[] = [
        {
            text: 'Account Settings',
            isSelected: selectedTab === 'Account Settings',
            onClick: () => {
                setSelectedTab('Account Settings');
            }
        },
        {
            text: 'Change Password',
            isSelected: selectedTab === 'Change Password',
            onClick: () => {
                setSelectedTab('Change Password');
            }
        }
    ];

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={!user}>
            <Section backgroundColor="off-white" withPadding={false}>
                <Tabs tabsItems={tabItems} viewMode="classic"></Tabs>
                {user &&
                    (selectedTab === 'Account Settings' ? (
                        <SettingsTab
                            user={user}
                            className={classes.settings}
                            onSave={userInfo => {
                                updateUserDetails(userInfo);
                            }}
                        ></SettingsTab>
                    ) : (
                        <PasswordTab
                            className={classes.settings}
                            onUpdatePassword={(oldPassword, newPassword) => {
                                return updateUserPassword(oldPassword, newPassword);
                            }}
                        ></PasswordTab>
                    ))}
            </Section>
        </AppMainContainer>
    );
}

export default AccountPage;
