import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { addUserFriendRequest, removeUserFriend, removeUserFriendRequest } from '../../redux/userSlice';

import { User } from '../../Classes/User';
import { sendFriendshipRequest, cancelFriendship, cancelFriendshipRequest } from '../../Services/User';

import Dialog from '../../Containers/Dialog/Dialog';
import Button from '../../Components/Button/Button';

export type FriendshipDialogMode = 'hidden' | 'add-friend' | 'unfriend' | 'cancel-request';

export type FriendshipDialogProps = {
    mode: FriendshipDialogMode;
    friend: User;

    onCloseDialog: () => void;
};

function FriendshipDialog(props: FriendshipDialogProps) {
    const { mode, friend, onCloseDialog } = props;

    const dispatch = useDispatch<AppDispatch>();

    const [isProcessing, setIsProcessing] = useState(false);

    const onConfirm = async () => {
        setIsProcessing(true);

        if (mode === 'add-friend') {
            if (await sendFriendshipRequest(friend.id)) {
                dispatch(addUserFriendRequest(friend.id));
            }
        } else if (mode === 'unfriend') {
            if (await cancelFriendship(friend.id)) {
                dispatch(removeUserFriend(friend.id));
            }
        } else if (mode === 'cancel-request') {
            if (await cancelFriendshipRequest(friend.id)) {
                dispatch(removeUserFriendRequest(friend.id));
            }
        }

        setIsProcessing(false);
        onCloseDialog();
    };

    const dialogTitle = mode === 'add-friend' ? 'Send Friend Request' : mode === 'unfriend' ? 'Cancel Friendship' : 'Cancel Friend Request';
    const dialogMessage =
        mode === 'add-friend'
            ? 'Do you want to make friend with '
            : mode === 'unfriend'
              ? 'Are you sure you want to unfriend '
              : 'Are you sure you want to cancel friend request to ';

    return (
        <Dialog isVisible={mode !== 'hidden'} title={dialogTitle} onCloseDialog={onCloseDialog}>
            <p className="mb-100">
                {dialogMessage}
                <b>{friend.fullName}</b>?
            </p>
            <div>
                <Button viewMode="fancy" className="me-75" isDisabled={isProcessing} onClick={onConfirm}>
                    Yes
                </Button>
                <Button viewMode="fancy" isDisabled={isProcessing} onClick={onCloseDialog}>
                    No
                </Button>
            </div>
        </Dialog>
    );
}

export default FriendshipDialog;
