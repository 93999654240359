import React from 'react';
import classes from './Button.module.css';

export type ButtonViewMode = 'decorationLink' | 'link' | 'classic' | 'fancy';

interface BasicButtonProps {
    viewMode?: ButtonViewMode;
    isDisabled?: boolean;
    className?: string;
    ariaLabel?: string;
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

type LinkViewProps = BasicButtonProps & {
    viewMode: 'decorationLink' | 'link';
    textColor?: 'green' | 'blue' | 'dark-blue';
};

type FancyViewProps = BasicButtonProps & {
    viewMode: 'fancy';
    btnColor?: 'blue' | 'green' | 'gray';
};

export type ButtonProps = LinkViewProps | FancyViewProps | BasicButtonProps;

function Button(props: ButtonProps) {
    const { className, isDisabled, ariaLabel, onClick } = props;

    const viewMode = props.viewMode ?? 'classic';
    let viewModeClassName = classes[`${viewMode}ViewMode`];

    if (viewMode === 'link' || viewMode === 'decorationLink') {
        const { textColor } = props as LinkViewProps;

        if (textColor) {
            viewModeClassName = `${viewModeClassName} text-${textColor}`;
        }
    } else if (viewMode === 'fancy') {
        const { btnColor = 'blue' } = props as FancyViewProps;
        viewModeClassName = `${viewModeClassName} ${classes[`${btnColor}FancyViewMode`]}`;
    }

    return (
        <button disabled={isDisabled} className={`${className ?? ''} ${viewModeClassName}`} aria-label={ariaLabel} onClick={onClick}>
            {props.children}
        </button>
    );
}

export default Button;
