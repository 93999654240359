import React, { useEffect, useRef, useState } from 'react';

import { BELLINO_BASE_URL } from '../../../Classes/Utilities';
import { BasicAlbumInfo } from '../../../Types/Album';

import Dialog from '../../../Containers/Dialog/Dialog';
import Preloader from '../../../Components/Preloader/Preloader';
import GenericList, { GenericListItem } from '../../../Components/GenericList/GenericList';

//import classes from './MoreReleasesDialog.module.css';

function MoreReleasesDialog(props: { albums: BasicAlbumInfo[]; isVisible: boolean; onCloseDialog: () => void }) {
    const { albums, isVisible, onCloseDialog } = props;

    const isLoading = useRef<boolean>();
    const [albumsInfo, setAlbumsInfo] = useState<BasicAlbumInfo[]>(albums);

    const validReleases = albumsInfo.filter(a => a.name && a.artists?.length);
    const idsToFetchData = albumsInfo.filter(a => !a.name || (a.artists?.length ?? 0) <= 0).map(a => a.id);

    useEffect(() => {
        if (isVisible) {
            if (!isLoading.current && idsToFetchData.length > 0) {
                isLoading.current = true;

                const fetches = idsToFetchData.map(releasesId =>
                    fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetAlbumBaseDetails&itemID=${releasesId}`).then(response =>
                        response.ok
                            ? response.json().then(response => {
                                  return { ...response, releasesId };
                              })
                            : null
                    )
                );

                Promise.allSettled(fetches).then(results => {
                    const releases = validReleases;

                    results.forEach(response => {
                        if (response?.status === 'fulfilled') {
                            releases.push({
                                id: response.value.releasesId,
                                name: response.value.Name,
                                artists: response.value.Artist?.split(',')?.map((artistName: any) => {
                                    return { name: artistName };
                                }),
                                coverImgPath: `${BELLINO_BASE_URL}/${response.value.Photo}`,
                                date: response.value.Year
                            });
                        }

                        isLoading.current = false;
                    });

                    setAlbumsInfo(releases);
                });
            } else {
                isLoading.current = false;
            }
        }
    }, [isVisible, validReleases, idsToFetchData]);

    const albumAsListItems = albumsInfo.map(album => {
        return {
            titleStr: album.name,
            title: album.name,
            subTitle: album?.artists?.map(a => a.name)?.join(', '),
            subInfo: album.date,
            href: `/album?aid=${album.id}`,
            image: album.coverImgPath
        } as GenericListItem;
    });

    return (
        <Dialog isVisible={isVisible} title="Other Releases" onCloseDialog={onCloseDialog}>
            {isLoading.current !== false ? <Preloader viewMode="Inline"></Preloader> : <GenericList items={albumAsListItems}></GenericList>}
        </Dialog>
    );
}

export default MoreReleasesDialog;
