import React from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playPlaylist } from '../../redux/musicPlayerSlice';

import { TrackInfo } from '../../Types/Track';
import { BELLINO_BASE_URL } from '../../Classes/Utilities';
import { buildPlaylistBy, buildPlaylistByTag } from '../../Services/Playlists';

import Card from '../Card/Card';

import classes from './PlaylistCard.module.css';

export type PlaylistCardProps = {
    title: string;
    imgName: string;
    tagName?: string;
    genres?: string[];
    minYear?: number;
    maxYear?: number;
};

function PlaylistCard(props: PlaylistCardProps) {
    const { title, imgName, tagName, genres, minYear = -1, maxYear = -1 } = props;
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Card
            imgPath={`${BELLINO_BASE_URL}/Images/Covers/${imgName}.jpg`}
            imgOverlays={[
                {
                    showMode: 'always',
                    className: classes.playlistImgOverlay,
                    overlayChildren: (
                        <div className={classes.PlaylistInfo}>
                            <i className="me-75 fa fa-play-circle"></i>
                            {title}
                        </div>
                    )
                }
            ]}
            onImageClick={async () => {
                let playlist: TrackInfo[] = [];

                if (tagName) {
                    playlist = await buildPlaylistByTag(tagName);
                } else {
                    let level: number | undefined = undefined;

                    if (title.toLowerCase() === 'all time hits') {
                        level = 100;
                    } else if (title.toLowerCase() === 'now hits') {
                        level = 40;
                    } else if (title.toLowerCase().includes('hits')) {
                        level = 60;
                    } else if (title.toLowerCase().includes('best')) {
                        level = 75;
                    }

                    playlist = await buildPlaylistBy(genres, minYear, maxYear, level);
                }

                dispatch(playPlaylist(playlist));
            }}
        ></Card>
    );
}

export default PlaylistCard;
