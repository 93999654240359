import React from 'react';

import Dialog from '../../Containers/Dialog/Dialog';
import Link from '../../Components/Link/Link';

import classes from './GuestDialog.module.css';

export type GuestDialogProps = {
    isVisible: boolean;
    userActionDescription?: string;
    onCloseDialog: () => void;
};

function GuestDialog(props: GuestDialogProps) {
    const { isVisible, onCloseDialog } = props;
    const userActionDescription = props.userActionDescription ?? 'do this action';

    return (
        <Dialog isVisible={isVisible} title={'Hello Guest'} onCloseDialog={onCloseDialog}>
            <span className="me-75">{`Only registered users can ${userActionDescription}.`}</span>
            <div className={classes.footer}>
                <Link viewMode={'Button'} href="/">
                    Sign In
                </Link>
            </div>
        </Dialog>
    );
}

export default GuestDialog;
