import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../redux/store';
import { setPageMetdata } from '../redux/pageMetadataSlice';

import { PageMetadata } from '../Types/PageMetadata';
import { BuildTrackFullName } from '../Classes/Utilities';

function usePageMetdata(metadata?: PageMetadata) {
    const dispatch = useDispatch<AppDispatch>();

    const pageMetadata = useSelector((state: RootState) => state.pageMetadata);
    const { playStatus, currentTrackIndex, tracklist } = useSelector((state: RootState) => state.musicPlayer);

    const updatePageMetadataTags = (metadata: PageMetadata) => {
        const updateMetaTag = (selector: string, attrName: string, attrValue: string, content: string) => {
            let metaTag = document.querySelector(selector);

            if (!metaTag) {
                metaTag = document.createElement('meta');
                metaTag.setAttribute(attrName, attrValue);
                document.head.appendChild(metaTag);
            }

            metaTag.setAttribute('content', content);
        };

        // Update title
        if (metadata.title) {
            document.title = metadata.title;
            updateMetaTag('meta[name="title"]', 'name', 'title', metadata.title);
            updateMetaTag('meta[property="og:title"]', 'property', 'og:title', metadata.title);
        }

        // Update description
        if (metadata.description) {
            updateMetaTag('meta[name="description"]', 'name', 'description', metadata.description);
            updateMetaTag('meta[property="og:description"]', 'property', 'og:description', metadata.description);
        }

        // Update keywords if provided
        if (metadata.keywords) {
            updateMetaTag('meta[name="keywords"]', 'name', 'keywords', metadata.keywords);
        }

        // Update image URL if provided
        if (metadata.imageURL) {
            updateMetaTag('meta[property="og:image"]', 'property', 'og:image', metadata.imageURL);
        }

        // Update current page URL
        updateMetaTag('meta[property="og:url"]', 'property', 'og:url', window.location.href);
    };

    const updatePageMetdata = useCallback(
        (metadata: PageMetadata) => {
            dispatch(setPageMetdata(metadata));
        },
        [dispatch]
    );

    useEffect(() => {
        if (metadata) {
            dispatch(setPageMetdata(metadata));
        }
    }, [metadata, dispatch]);

    useEffect(() => {
        if (playStatus === 'playing') {
            const nowPlayingTrack = tracklist[currentTrackIndex];
            const nowPlayingTitle = `${BuildTrackFullName(nowPlayingTrack?.name, nowPlayingTrack.artists)} | Bellino`;
            updatePageMetadataTags({ title: nowPlayingTitle });
        } else {
            updatePageMetadataTags(pageMetadata);
        }
    }, [playStatus, pageMetadata, tracklist, currentTrackIndex]);

    return { updatePageMetdata };
}

export default usePageMetdata;
