import React, { useState } from 'react';

import Button from '../../../Components/Button/Button';
import Dialog from '../../../Containers/Dialog/Dialog';
import CountriesSelection from '../../../Components/CountriesSelection/CountriesSelection';

import classes from './CountriesFilterDialog.module.css';

export type CountriesFilterDialogProps = {
    isVisible: boolean;
    selectedCountries?: string[];
    onFilterValueChanged: (value: string[]) => void;
    onCloseDialog: () => void;
};

function CountriesFilterDialog(props: CountriesFilterDialogProps) {
    const { isVisible, selectedCountries, onCloseDialog, onFilterValueChanged } = props;

    const [newSelectedCountries, setNewSelectedCountries] = useState(selectedCountries ?? []);

    const onSubmit = () => {
        onFilterValueChanged(newSelectedCountries);
        onCloseDialog();
    };

    const onCancelAndClose = () => {
        onCloseDialog();
        setNewSelectedCountries(selectedCountries ?? []);
    };

    return (
        <Dialog isVisible={isVisible} title="Filter By Countries" onCloseDialog={onCancelAndClose}>
            <CountriesSelection
                selectionMode="Multi-Selection"
                selectedCountries={newSelectedCountries}
                onSelectionChange={val => {
                    setNewSelectedCountries(val);
                }}
            ></CountriesSelection>
            <div className={classes.footer}>
                <div>
                    <Button
                        viewMode="link"
                        textColor="dark-blue"
                        onClick={() => {
                            setNewSelectedCountries([]);
                        }}
                    >
                        Clear Selection
                    </Button>
                </div>
                <div>
                    <Button viewMode="fancy" onClick={onSubmit}>
                        Ok
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default CountriesFilterDialog;
