import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Tag.module.css';

export type TagProps = {
    text: string;
    href: string;
    viewMode: 'default' | 'link';
};

function Tag(props: TagProps) {
    const viewMode = props.viewMode ?? 'default';

    return (
        <Link to={props.href} className={viewMode === 'default' ? classes.tagView : classes.linkView}>
            <span>{props.text}</span>
        </Link>
    );
}

export default Tag;
