import { BELLINO_BASE_URL } from '../Classes/Utilities';
import { ChatInfo, ChatData, MessageStatus } from '../Types/Chat';
import { MusicItemType } from '../Types/MusicItemType';

export const getUserChats = async () => {
    const conversions: ChatInfo[] = [];

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/api/messages?action=GetUserConversions`, {
            method: 'GET'
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData.success && typeof responseData.result === 'object') {
                conversions.push(...responseData.result);
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return conversions;
};

export const getChatData = async (friendID: string) => {
    let chatData: ChatData = { participants: [], messages: [] };

    if (friendID.toString().trim()?.length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/messages?action=GetConversion&friendID=${friendID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData.success && typeof responseData.result === 'object') {
                    chatData = responseData.result;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return chatData;
};

export const sendMessage = async (friendID: string, message: string) => {
    let messageId: string | undefined;

    if (friendID.toString().trim()?.length > 0 && message.toString().trim()?.length > 0) {
        const formData = new FormData();
        formData.append('action', 'SendPM');
        formData.append('uid', friendID);
        formData.append('msg', message);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                messageId = await response.text();
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return messageId;
};

export const deleteMessage = async (messageID: string) => {
    let isSuccess: boolean = false;

    if (messageID.toString().trim()?.length > 0) {
        const formData = new FormData();
        formData.append('action', 'DeleteUserMessage');
        formData.append('mid', messageID);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData);
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const updateMessageStatus = async (messageID: string, status: MessageStatus) => {
    let isSuccess: boolean = false;

    if (messageID.toString().trim()?.length > 0 && status) {
        const formData = new FormData();
        formData.append('action', 'UpdateUserMessageStatus');
        formData.append('mid', messageID);
        formData.append('status', status);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData);
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const sendRecommendationMessage = async (
    friendID: string,
    itemType: MusicItemType,
    itemID: string,
    specified: string = '',
    message: string = ''
) => {
    let isSuccess: boolean = false;

    if (friendID.toString().trim()?.length > 0 && itemType.trim()?.length > 0 && itemID.trim()?.length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=RecommendItem&fid=${friendID}&type=${itemType}&itemID=${itemID}&data=${specified}&msg=${message}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData);
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};
