import React, { useMemo, useState } from 'react';

import { Track } from '../../Classes/Track';
import { TrackInfo } from '../../Types/Track';
import { xPosition } from '../../Types/Position';

import MenuButton, { MenuButtonView } from './MenuButton';
import RateDialog from '../../Pages/_partials/RateDialog';
import RecommendDialog from '../../Pages/_partials/RecommendDialog';
import ImproveMetadataDialog from '../../Pages/_partials/ImproveMetadataDialog';
import AddTrackToMyPlaylistsDialog from '../../Pages/_partials/AddTrackToMyPlaylistsDialog';

export type TrackMenuButtonProps = {
    track: Track | TrackInfo;
    buttonSize?: 'small' | 'medium' | 'large';
    buttonView?: MenuButtonView;
    buttonCustomClass?: string;
    buttonChildren?: React.ReactNode;
    containerCustomClass?: string;
    menuXPosition?: xPosition;
    parentType?: 'relative' | 'fixed';
};

function TrackMenuButton(props: TrackMenuButtonProps) {
    const { buttonSize, buttonView, buttonCustomClass, buttonChildren, containerCustomClass, menuXPosition, parentType } = props;

    const [isImproveMetadataDialogVisible, setIsImproveMetadataDialogVisible] = useState(false);
    const [isRateDialogVisible, setIsRateDialogVisible] = useState(false);
    const [isRecommendDialogVisible, setIsRecommendDialogVisible] = useState(false);
    const [isAddTrackToMyPlaylistsDialogVisible, setIsAddTrackToMyPlaylistsDialogVisible] = useState(false);

    const track = useMemo(() => new Track(props.track), [props.track]);

    return (
        <>
            <MenuButton
                buttonSize={buttonSize}
                buttonView={buttonView}
                buttonCustomClass={buttonCustomClass}
                buttonChildren={buttonChildren}
                containerCustomClass={containerCustomClass}
                parentType={parentType}
                menuXPosition={menuXPosition}
                menuSections={[
                    {
                        menuItems: [
                            { text: 'Buy track on Amazon', href: track.amazonBuyLink, hrefTarget: '_blank' },
                            {
                                text: 'Rate track',
                                onClick: () => {
                                    setIsRateDialogVisible(true);
                                }
                            },
                            {
                                text: 'Recommend to friends',
                                onClick: () => {
                                    setIsRecommendDialogVisible(true);
                                }
                            },
                            {
                                text: 'Add to my playlist',
                                onClick: () => {
                                    setIsAddTrackToMyPlaylistsDialogVisible(true);
                                }
                            },
                            {
                                text: 'Improve metadata',
                                onClick: async () => {
                                    setIsImproveMetadataDialogVisible(true);
                                }
                            }
                        ]
                    }
                ]}
            ></MenuButton>

            <RateDialog
                itemType="Track"
                itemId={track.id}
                isVisible={isRateDialogVisible}
                onCloseDialog={() => setIsRateDialogVisible(false)}
            ></RateDialog>

            <RecommendDialog
                itemType="Track"
                itemId={track.id}
                isVisible={isRecommendDialogVisible}
                onCloseDialog={() => setIsRecommendDialogVisible(false)}
            ></RecommendDialog>

            <ImproveMetadataDialog
                itemType="Track"
                itemId={track.id}
                isVisible={isImproveMetadataDialogVisible}
                track={track}
                onSave={async (metdaDataType, data) => {
                    //console.log(metdaDataType, data);
                }}
                onCloseDialog={() => setIsImproveMetadataDialogVisible(false)}
            ></ImproveMetadataDialog>

            <AddTrackToMyPlaylistsDialog
                trackId={track.id}
                isVisible={isAddTrackToMyPlaylistsDialogVisible}
                onCloseDialog={() => {
                    setIsAddTrackToMyPlaylistsDialogVisible(false);
                }}
            ></AddTrackToMyPlaylistsDialog>
        </>
    );
}

export default TrackMenuButton;
