import React, { useEffect, useMemo, useState } from 'react';

//import SimpleSelectionList from '../SelectionList/SimpleSelectionList';
import RichSelectionList from '../SelectionList/RichSelectionList';
import { MultiSearchSelection } from '../MultiSearchSelection/MultiSearchSelection';

import { KeyValue } from '../../Types/KeyValue';

interface BaseGenresSelectionProps {
    selectionMode: 'Single-Selection' | 'Multi-Selection' | 'Rich-Selection';
    showPreloader?: boolean;
}

type SingleSelectionProps = BaseGenresSelectionProps & {
    selectionMode: 'Single-Selection';
    selectedGenres?: string[];
    onSelectionChange: (newSelectedGenres: string) => void;
};

type MultiSelectionProps = BaseGenresSelectionProps & {
    selectionMode: 'Multi-Selection';
    selectedGenres?: string[];
    onSelectionChange: (newSelectedGenres: string[]) => void;
};

type RichSelectionProps = BaseGenresSelectionProps & {
    selectionMode: 'Rich-Selection';
    highlightGenres?: string[];
    onSelectionChange: (newSelectedGenres: KeyValue) => void;
};

export type GenresSelectionProps = SingleSelectionProps | MultiSelectionProps | RichSelectionProps;

function GenresSelection(props: GenresSelectionProps) {
    const { selectionMode, showPreloader } = props;
    const { highlightGenres, selectedGenres } =
        selectionMode === 'Rich-Selection'
            ? { ...(props as RichSelectionProps), selectedGenres: [] as string[] }
            : { ...(props as MultiSelectionProps), highlightGenres: undefined };

    const [genres, setGenres] = useState<string[]>([]);
    const genresItems = useMemo(
        () =>
            genres?.map(genre => {
                return { id: genre, name: genre };
            }),
        [genres]
    );

    const selectedGenresItems = useMemo(
        () =>
            selectedGenres?.map(genre => {
                return { id: genre, name: genre };
            }),
        [selectedGenres]
    );

    useEffect(() => {
        if ((window as any).genres_list) {
            setGenres((window as any).genres_list.filter((g: string) => g));
        } else {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json; charset=UTF-8');

            fetch('/genres.json', { headers })
                .then(response => response.json())
                .then(response => {
                    if (response) {
                        (window as any).genres_list = response.sort();
                        setGenres((window as any).genres_list.filter((g: string) => g));
                    }
                });
        }
    }, []);

    if (selectionMode === 'Rich-Selection') {
        const { onSelectionChange } = props as RichSelectionProps;

        return (
            <RichSelectionList
                showPreloader={showPreloader || genres.length === 0}
                onSelectionChange={onSelectionChange}
                selectionOptions={genres}
                highlightOptions={highlightGenres}
            ></RichSelectionList>
        );
    }

    if (selectionMode === 'Single-Selection') {
        const { onSelectionChange } = props as SingleSelectionProps;

        return (
            <select
                value={selectedGenres?.length ? selectedGenres[0] : ''}
                onChange={event => {
                    onSelectionChange(event.target.value);
                }}
            >
                <option key="empty"></option>
                {genres.map((genre, index) => (
                    <option key={index} value={genre}>
                        {genre}
                    </option>
                ))}
            </select>
        );

        /*return (
            <SimpleSelectionList
                selectionMode="Single-Selection"
                showPreloader={genres.length === 0}
                //showOptionsSearch={true}
                selectionOptions={genres}
                selectedOptions={selectedGenres}
                onSelectionChange={val => {
                    onSelectionChange(val?.[0]);
                }}
            ></SimpleSelectionList>
        );*/
    }

    // Multi-Selection
    const { onSelectionChange } = props as MultiSelectionProps;

    return (
        <MultiSearchSelection
            width="100%"
            placeholder="Search Genre"
            searchItems={genresItems}
            selectedItems={selectedGenresItems}
            onSelectionChange={selectedSearchItems => {
                onSelectionChange(selectedSearchItems.map(s => s.name));
            }}
        ></MultiSearchSelection>
    );

    /*return (
        <SimpleSelectionList
            showPreloader={genres.length === 0}
            showOptionsSearch={true}
            selectionMode="Multi-Selection"
            selectionOptions={genres}
            selectedOptions={selectedGenres}
            onSelectionChange={onSelectionChange}
        ></SimpleSelectionList>
    );*/
}

export default GenresSelection;
