import React from "react";
import classes from "./ChartBar.module.css";

export type ChartBarProps = {
  score: number;
  text?: string;
  onClick?: () => void;
};

function ChartBar(props: ChartBarProps) {
  const cardImageStyle = {
    width: `${props.score}%`,
    cursor: props.onClick ? "pointer" : "",
  };

  return (
    <div style={cardImageStyle} className={classes.chartBar}>
      {props.text}
    </div>
  );
}

export default ChartBar;
