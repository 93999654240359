import { BELLINO_BASE_URL } from '../Classes/Utilities';
import { TrackInfo } from '../Types/Track';
import { BasicVideoInfo } from '../Types/VideoInfo';

export const getAlbumTracksPlaylist = async (albumId: string) => {
    let albumTracksPlaylist: TrackInfo[] = [];

    if (albumId?.toString().trim()?.length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&type=album&itemID=${albumId}`, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();

                if (data?.length) {
                    const albumTracksData = data;

                    albumTracksPlaylist = albumTracksData.map((trackData: any) => {
                        return {
                            albumId,
                            id: trackData.trackID,
                            name: trackData.trackName,
                            artists: trackData.artists ?? [],
                            videos: [
                                {
                                    duration: trackData.duration,
                                    externalId: trackData.videoID
                                } as BasicVideoInfo
                            ]
                        } as TrackInfo;
                    });
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return albumTracksPlaylist;
};
