import { configureStore } from '@reduxjs/toolkit';

import uiSliceReducer from './uiSlice';
import userSliceReducer from './userSlice';
import musicPlayerReducer from './musicPlayerSlice';
import pageMetadataSlice from './pageMetadataSlice';

const store = configureStore({
    reducer: {
        pageMetadata: pageMetadataSlice,
        ui: uiSliceReducer,
        user: userSliceReducer,
        musicPlayer: musicPlayerReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
