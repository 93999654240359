import { useState, useEffect, useCallback } from 'react';

export type userDeviceData = {
    screenWidth: number;
    screenHeight: number;
    isMobile: boolean;
    isMobileView: boolean;
    hasTouchScreen: boolean;
};

function useDeviceData() {
    const [userDeviceData, setUserDeviceData] = useState<userDeviceData>();

    const updateDeviceData = useCallback(() => {
        const isMobileDevice = () => {
            const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

            // Check for common mobile user agents
            if (/android/i.test(userAgent)) {
                return true;
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
                return true;
            }

            if (/Windows Phone/i.test(userAgent)) {
                return true;
            }

            return false;
        };

        const isMobile = isMobileDevice();

        setUserDeviceData({
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            isMobileView: isMobile || window.innerWidth <= 768,
            isMobile: isMobile,
            hasTouchScreen: 'ontouchstart' in window || navigator.maxTouchPoints > 0
        });
    }, []);

    useEffect(() => {
        updateDeviceData();
        window.addEventListener('resize', updateDeviceData);
        return () => window.removeEventListener('resize', updateDeviceData);
    }, [updateDeviceData]);

    return userDeviceData ?? { isMobile: false, isMobileView: false, hasTouchScreen: false };
}

export default useDeviceData;
