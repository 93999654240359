import React from 'react';

import usePageMetdata from '../../hooks/usePageMetdata';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';

import classes from './NotFound.module.css';

function NotFound() {
    usePageMetdata({ title: 'Not Found' });

    return (
        <AppMainContainer backgroundColor="off-white">
            <div className={classes.notFound}>
                <svg height="100" width="100">
                    <polygon
                        points="50,25 17,80 82,80"
                        strokeLinejoin="round"
                        style={{ fill: 'none', stroke: '#ff8a00', strokeWidth: 8 }}
                    ></polygon>
                    <text x="42" y="74" fill="#ff8a00" fontFamily="sans-serif" fontWeight="900" fontSize="42px">
                        !
                    </text>
                </svg>
                <div className="fs-250">Sorry, this page isn't available</div>
                <div className="fs-135 mt-100">The link you followed may be broken, or the page may have been removed.</div>
            </div>
        </AppMainContainer>
    );
}

export default NotFound;
