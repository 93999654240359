import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, FetchPageData } from '../../Classes/Utilities';
import { TrackInfo } from '../../Types/Track';
import { User } from '../../Classes/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Link from '../../Components/Link/Link';
import Breadcrumbs, { BreadcrumbsItem } from '../../Components/Breadcrumbs/Breadcrumbs';
import LiveDate from '../../Components/LiveDate/LiveDate';
import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import Preloader from '../../Components/Preloader/Preloader';

function UserListeningsPage(props: any) {
    const [searchParams] = useSearchParams();
    const { userSessionData } = useUserSessionData();
    const { updatePageMetdata } = usePageMetdata();

    const itemtype = searchParams.get('type');
    const itemID = searchParams.get('itemID');
    const userID = searchParams.get('uid') ?? userSessionData.userInfo?.id;

    const [user, setUser] = useState<User>(new User());
    const [recentlyPlayed, setRecentlyPlayed] = useState<TrackInfo[]>([]);
    const [recentlyPlayedOffset, setRecentlyPlayedOffset] = useState(0);
    const [isMoreToScroll, setIsMoreToScroll] = useState(true);

    const isLoading = useRef<boolean>();
    const numOfPlayedItemsChunk = useRef(25);
    const numOfPlayedItems = recentlyPlayed.length;

    useEffect(() => {
        const handlePageScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;

            if (isMoreToScroll && scrollPos / document.body.clientHeight > 0.8) {
                setRecentlyPlayedOffset(numOfPlayedItems);
                numOfPlayedItemsChunk.current = 50;
            }
        };

        window.addEventListener('scroll', handlePageScroll);
        return () => window.removeEventListener('scroll', handlePageScroll);
    }, [numOfPlayedItems, isMoreToScroll]);

    useEffect(() => {
        if (!isLoading.current && userID) {
            isLoading.current = true;

            FetchPageData(
                `${BELLINO_BASE_URL}/api/pages/userlistenings?id=${userID}&itemType=${itemtype}&itemID=${itemID}&offset=${recentlyPlayedOffset}`
            ).then((response: any) => {
                let moreToScroll = true;

                if (response.success) {
                    if (recentlyPlayedOffset > 0) {
                        setRecentlyPlayed(oldArray => [...oldArray, ...response.result?.recentlyPlayed]);
                    } else {
                        const user = new User(response.result?.userInfo);

                        updatePageMetdata({
                            title: `${user.fullName} - Played Tracks`,
                            description: `View ${user.fullName}'s music profile on Bellino. Join Bellino to know music lovers like ${user.fullName} from all over the world`
                        });

                        setUser(user);
                        setRecentlyPlayed(response.result?.recentlyPlayed);
                    }

                    if (response.result?.recentlyPlayed?.length === 0) {
                        moreToScroll = false;
                    }
                } else {
                    setUser(new User());
                }

                setTimeout(() => {
                    isLoading.current = false;
                    setIsMoreToScroll(moreToScroll);
                }, 100);
            });
        }
    }, [userID, itemtype, itemID, recentlyPlayedOffset, updatePageMetdata]);

    const tracksAsListItems = useMemo(
        () =>
            recentlyPlayed.map(track => {
                return {
                    titleStr: track.name,
                    title: <Link href={`/track?tid=${track.id}`}>{track.name}</Link>,
                    subTitle: track?.artists?.map((artist, index) => (
                        <span key={index}>
                            <Link href={`/artist?aid=${artist.id}`}>{artist.name}</Link>
                            {index < track.artists.length - 1 ? <span>, </span> : <></>}
                        </span>
                    )),
                    image: track.imgPath,
                    imageHref: `/track?tid=${track.id}`,
                    endSection: (
                        <div className="text-gray me-75 nowrap">
                            <LiveDate utcDate={track.date ?? ''}></LiveDate>
                        </div>
                    )
                } as GenericListItem;
            }),
        [recentlyPlayed]
    );

    if (!userID || (!user.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    let extraBreadcrumbs: BreadcrumbsItem[] = [];

    if (itemtype && itemID) {
        let artistName = recentlyPlayed?.[0]?.artists?.[0]?.name ?? '';

        if (itemtype === 'track') {
            const trackName = recentlyPlayed?.[0]?.name;
            extraBreadcrumbs = [{ text: `${artistName} - ${trackName}`, href: `/track?tid=${itemID}`, textColor: 'dark-gray' }];
        } else if (itemtype === 'album') {
            const albumName = recentlyPlayed?.[0]?.appearsInAlbums?.find(a => a.id.toString() === itemID)?.name ?? '';
            extraBreadcrumbs = [{ text: `${artistName} - ${albumName}`, href: `/album?aid=${itemID}`, textColor: 'dark-gray' }];
        } else if (itemtype === 'artist') {
            artistName = recentlyPlayed?.[0]?.artists?.find(a => a.id?.toString() === itemID)?.name ?? '';
            extraBreadcrumbs = [
                {
                    text: artistName,
                    href: `/artist?aid=${itemID}`,
                    textColor: 'dark-gray'
                }
            ];
        }
    }

    return (
        <AppMainContainer showPreloader={!user.isInit}>
            <Section backgroundColor="white">
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[
                            { text: user.fullName, href: `/user?uid=${user.id}` },
                            { text: 'Played Tracks' },
                            ...extraBreadcrumbs
                        ]}
                    ></Breadcrumbs>
                </Title>
                <GenericList items={tracksAsListItems} spaceBetweenItems={false}></GenericList>
                {Boolean(isLoading.current !== false) && <Preloader viewMode="Inline"></Preloader>}
            </Section>
        </AppMainContainer>
    );
}

export default UserListeningsPage;
