import React, { useMemo } from 'react';

import { MusicItemType } from '../../../Types/MusicItemType';

import ItemPlaybar from '../../ItemPlaybar/ItemPlaybar';
import ChartBar from '../ChartBar/ChartBar';

import classes from './ChartTable.module.css';

export type ChartItem = {
    id: string;
    type: MusicItemType;
    position: number;
    score: number;
    children?: React.ReactNode;
    onChartItemPlay?: () => void;
    onChartItemPlus?: () => void;
};

export type ChartTableProps = {
    chartItems: ChartItem[];
};

function ChartTable(props: ChartTableProps) {
    const maxScore = useMemo(() => Math.max(...props.chartItems.map(i => i.score)), [props.chartItems]);
    const isArtistsChart = useMemo(() => props.chartItems.filter(i => i.type !== 'Artist').length === 0, [props.chartItems]);

    return (
        <table className={classes.chartTable}>
            <tbody>
                {props.chartItems.map(chartItem => (
                    <tr key={chartItem.id}>
                        <td>{chartItem.position}</td>
                        <td>
                            {isArtistsChart ? (
                                chartItem.children
                            ) : (
                                <ItemPlaybar
                                    itemId={chartItem.id}
                                    itemType={chartItem.type}
                                    viewMode="inline"
                                    customCSS={{ color: '#777' }}
                                    onPlayClick={chartItem.onChartItemPlay}
                                    onPlusClick={chartItem.onChartItemPlus}
                                >
                                    {chartItem.children}
                                </ItemPlaybar>
                            )}
                        </td>
                        <td>
                            <ChartBar score={(chartItem.score / maxScore) * 100} text={chartItem.score.toString()}></ChartBar>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default ChartTable;
