import { ArtistInfo } from '../Types/Artist';
import { BasicAlbumInfo } from '../Types/Album';
import { VideoInfo } from '../Types/VideoInfo';
import { DEFAULT_RELEASE_IMG, BuildAmazonBuyLink, BuildTrackFullName, getImageFullPath } from './Utilities';
import { getTrackTags, getTrackGenres } from '../Services/Tracks';

export class Track {
    public isInit: boolean = false;
    public isTagsLoaded: boolean = false;
    public isGenresLoaded: boolean = false;

    public id: string = '';
    public name: string = '';
    public fullName: string = '';
    public year?: number;
    public artists: ArtistInfo[] = [];
    public coverImgPath: string = DEFAULT_RELEASE_IMG;
    public genres: string[] = [];
    public tags: string[] = [];
    public videos: VideoInfo[] = [];
    public appearsInAlbums?: BasicAlbumInfo[] = [];
    public amazonBuyLink: string = '';

    public constructor(info?: any) {
        this.init(info);
    }

    public init(info: any) {
        if (info) {
            this.id = info.id?.toString();
            this.name = info.name;
            this.year = info.year;
            this.genres = info.genres ?? [];
            this.artists = info.artists ?? [];
            this.tags = info.tags ?? [];
            this.appearsInAlbums = info.appearsInAlbums ?? [];
            this.fullName = BuildTrackFullName(this.name, this.artists);
            this.coverImgPath = getImageFullPath(info.coverImgPath ?? info.imgPath, 'Track');
            this.amazonBuyLink = BuildAmazonBuyLink(this.fullName);

            info.videos?.forEach((video: any) => {
                this.videos.push({
                    ...video,
                    usersLikeInfo: {
                        ...video?.usersLikeInfo,
                        isUserLike:
                            video?.usersLikeInfo?.isUserLike === -1 || typeof video?.usersLikeInfo?.isUserLike !== 'number'
                                ? undefined
                                : video?.usersLikeInfo?.isUserLike
                    }
                });
            });

            this.isInit = true;
        }
    }

    public DeleteVideoByIndex(index: number) {
        this.videos = this.videos.slice(index, index);
    }

    public async LoadTags(reload: boolean = false) {
        if (!this.isTagsLoaded || reload) {
            this.tags = await getTrackTags(this.id);
            this.isTagsLoaded = true;
        }

        return this.tags;
    }

    public async LoadGenres(reload: boolean = false) {
        if ((this.genres.length === 0 && !this.isGenresLoaded) || reload) {
            this.genres = await getTrackGenres(this.id);
            this.isGenresLoaded = true;
        }

        return this.genres;
    }
}
