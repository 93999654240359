import { BELLINO_BASE_URL, handleSearchText, SecondsToTime } from '../Classes/Utilities';
import { TrackInfo } from '../Types/Track';
import { BasicVideoInfo, VideoInfo } from '../Types/VideoInfo';
import { SearchItemInfo } from '../Components/MultiSearchSelection/MultiSearchSelection';

export const getTrackVideoInfo = async (trackID: string) => {
    let trackVideoInfo: TrackInfo | undefined;

    if (trackID.toString().trim()?.length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/tracks?action=GetTrackVideoInfo&trackID=${trackID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const data = await response.json();

                if (data?.success && data?.result) {
                    const trackData = data.result;

                    trackVideoInfo = {
                        id: trackData.ID,
                        name: trackData.Name,
                        artists: trackData?.ArtistID?.map((artistId: any, index: number) => {
                            return {
                                id: artistId,
                                name: trackData?.ArtistName?.[index] ?? ''
                            };
                        }),
                        videos: trackData.Video
                            ? [
                                  {
                                      duration: trackData.Duration,
                                      externalId: trackData.Video
                                  } as BasicVideoInfo
                              ]
                            : []
                    } as TrackInfo;
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return trackVideoInfo;
};

export const addTrackVideo = async (trackID: string, videoID: string, videoType: string, duration: number, uploaderId: string) => {
    let videoInfo: VideoInfo | undefined;

    if (trackID.toString().length > 0 && videoID.trim().length > 0 && duration > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/tracks`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'AddVideo',
                    trackID,
                    video: videoID,
                    itemType: videoType,
                    duration
                })
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.success && responseData?.result) {
                    videoInfo = {
                        id: responseData.result.VideoID,
                        duration,
                        durationStr: SecondsToTime(duration),
                        externalId: videoID,
                        thumbnailImgPath: `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`,
                        uploaderId
                    };
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return videoInfo;
};

export const deleteTrackVideo = async (videoID: string) => {
    let isDeleted: boolean = false;

    if (videoID.trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=DeleteVideo&vid=${videoID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isDeleted = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isDeleted;
};

export const flagTrackVideo = async (videoID: string, videoIdType: 'Bellino' | 'External' = 'Bellino') => {
    let isSuccess: boolean = false;

    if (videoID.trim().length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=FlagVideo&data=${videoIdType === 'Bellino' ? 'bellino_id' : 'youtube_id'}&vid=${videoID}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const updateTrackVideoDuration = async (videoID: string, duration: number) => {
    let isSuccess: boolean = false;

    if (videoID.trim().length > 0 && duration > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=UpdateVideoDuration&vid=${videoID}&length=${duration}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.text();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const searchTracks = async (serachText: string, exludedTrackId?: string) => {
    const searchResults: SearchItemInfo[] = [];

    if (serachText.trim().length > 0) {
        const finalSearchText = handleSearchText(serachText);

        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchFullTracks&search=${finalSearchText}&data=${exludedTrackId ?? ''}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    responseData.forEach((trackResult: any) => {
                        const trackId = trackResult.ID;
                        const trackName = trackResult.Name;
                        const trackArtistName = trackResult.ArtistName.replaceAll(';', ', ');

                        searchResults.push({
                            id: trackId,
                            name: `${trackArtistName} - ${trackName}`
                        });
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return searchResults;
};

export const searchArtistTracks = async (serachText: string, artistId: string, exludedTrackIds?: string) => {
    const searchResults: SearchItemInfo[] = [];

    if (serachText.trim().length > 0 && artistId.trim().length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchArtistTracks&itemID=${artistId}&search=${serachText}&data=${exludedTrackIds ?? ''}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    responseData.forEach((trackResult: any) => {
                        const trackId = trackResult.ID;
                        const trackName = trackResult.Name;
                        const trackArtistName = trackResult.ArtistName.replaceAll(';', ', ');

                        searchResults.push({
                            id: trackId,
                            name: `${trackArtistName} - ${trackName}`
                        });
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return searchResults;
};

export const searchAlbumTracks = async (serachText: string, albumId: string, exludedTrackIds?: string) => {
    const searchResults: SearchItemInfo[] = [];

    if (serachText.trim().length > 0 && albumId.trim().length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchAlbumTracks&itemID=${albumId}&search=${serachText}&data=${exludedTrackIds ?? ''}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    responseData.forEach((trackResult: any) => {
                        const trackId = trackResult.ID;
                        const trackName = trackResult.Name;

                        searchResults.push({
                            id: trackId,
                            name: trackName
                        });
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return searchResults;
};

export const addTrackLyrics = async (trackID: string, lyrics: string) => {
    let isSuccess: boolean = false;

    if (trackID.trim().length > 0 && lyrics.trim().length > 0) {
        const formData = new FormData();
        formData.append('action', 'AddLyrics');
        formData.append('tid', trackID);
        formData.append('lyrics', lyrics.replace(/'/g, "''"));

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const getTrackTags = async (trackID: string) => {
    const tags: string[] = [];

    if (trackID.toString().trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetTrackTags&tid=${trackID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (typeof responseData === 'object') {
                    tags.push(...(responseData as string[]));
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return tags;
};

export const getTrackGenres = async (trackID: string) => {
    const genres: string[] = [];

    if (trackID.toString().trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetTrackGenres&tid=${trackID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (typeof responseData === 'object') {
                    genres.push(...(responseData as string[]));
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return genres;
};
