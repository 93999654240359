import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';

import useUserSessionData from '../../hooks/useUserSessionData';

function LogoutPage() {
    const { userSessionData, logout } = useUserSessionData();

    const isLoggingOut = useRef<boolean>(false);

    useEffect(() => {
        if (!isLoggingOut.current) {
            logout();
            isLoggingOut.current = true;
        }
    }, [logout]);

    if (userSessionData.isReady && !userSessionData.sessionId) {
        return <Navigate to="/" />;
    }

    return null;
}

export default LogoutPage;
