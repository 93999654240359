import React, { useEffect, useRef, useState } from 'react';

import { searchTracks } from '../../../Services/Tracks';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import { MultiSearchSelection, SearchItemInfo } from '../../../Components/MultiSearchSelection/MultiSearchSelection';

import classes from './PlaylistEditorDialog.module.css';

export type PlaylistEditorMode = 'hidden' | 'create' | 'rename' | 'delete' | 'add-tracks';

export type PlaylistEditorDialogProps = {
    mode: PlaylistEditorMode;
    playlistName?: string;
    onCloseDialog: () => void;
    onCreate?: (newPlaylistName: string, newPlaylistTracksIds?: string[]) => void;
    onUpdate?: (newPlaylistName: string, newPlaylistTracksIds?: string[]) => void;
    onDelete?: () => void;
};

function PlaylistEditorDialog(props: PlaylistEditorDialogProps) {
    const { mode, playlistName, onCreate, onUpdate, onCloseDialog } = props;

    const playlistNameElementRef = useRef(null);

    const [playlistTracks, setPlaylistTracks] = useState<SearchItemInfo[]>([]);
    const [resetSearch, setResetSearch] = useState(false);

    const onSave = () => {
        const playlistNameElement = playlistNameElementRef?.current ? (playlistNameElementRef.current as HTMLInputElement) : null;
        const newPlaylistTracksIds = playlistTracks.length > 0 ? playlistTracks.map(pt => pt.id) : undefined;

        if (mode === 'create' && playlistNameElement?.value && playlistNameElement.value.trim().length > 0) {
            onCreate?.(playlistNameElement.value, newPlaylistTracksIds);
            onCloseDialog();
            setTimeout(() => {
                setResetSearch(false);
            }, 100);
        } else if (mode === 'rename' && playlistNameElement?.value && playlistNameElement.value.trim().length > 0) {
            onUpdate?.(playlistNameElement.value);
            onCloseDialog();
            setTimeout(() => {
                setResetSearch(false);
            }, 100);
        } else if (mode === 'add-tracks' && playlistName) {
            onUpdate?.(playlistName, newPlaylistTracksIds);
            onCloseDialog();
            setTimeout(() => {
                setResetSearch(false);
            }, 100);
        } else {
            playlistNameElement?.focus();
        }
    };

    const onDelete = () => {
        props.onDelete?.();
        onCloseDialog();
    };

    useEffect(() => {
        const playlistNameElement = playlistNameElementRef?.current ? (playlistNameElementRef.current as HTMLInputElement) : null;

        if (playlistNameElement) {
            if (playlistName && mode !== 'create') {
                playlistNameElement.value = playlistName;
                playlistNameElement.focus();
            } else {
                playlistNameElement.value = '';
            }
        }
    }, [mode, playlistName]);

    const dialogTitle = `${mode === 'create' ? 'New' : mode === 'rename' ? 'Rename' : mode === 'delete' ? 'Delete' : 'Add Tracks to'} Playlist`;

    return (
        <Dialog isVisible={mode !== 'hidden'} title={dialogTitle} onCloseDialog={onCloseDialog}>
            {mode === 'delete' ? (
                <p className="fs-105 mb-100">
                    Are you sure you want to delete <b>{playlistName}</b> playlist?
                </p>
            ) : (
                <>
                    {mode === 'create' && <div className={classes.title}>Playlist name</div>}
                    {mode !== 'add-tracks' && <input ref={playlistNameElementRef} placeholder="Playlist Name"></input>}
                    {(mode === 'create' || mode === 'add-tracks') && (
                        <>
                            {mode === 'create' && <div className={`${classes.title} mt-75`}>Add tracks to playlist (optinal)</div>}
                            <MultiSearchSelection
                                width="100%"
                                placeholder="Search Tracks..."
                                reset={resetSearch}
                                selectedItems={playlistTracks}
                                onSearch={serachText => {
                                    return searchTracks(serachText);
                                }}
                                onSelectionChange={selectedSearchItems => {
                                    setPlaylistTracks(selectedSearchItems);
                                }}
                            ></MultiSearchSelection>
                        </>
                    )}
                </>
            )}

            <div className={classes.footer}>
                {mode === 'delete' ? (
                    <>
                        <Button viewMode="fancy" className="me-75" onClick={onDelete}>
                            Yes
                        </Button>
                        <Button viewMode="fancy" onClick={onCloseDialog}>
                            No
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            viewMode="fancy"
                            className="me-75"
                            isDisabled={mode === 'add-tracks' && playlistTracks.length <= 0}
                            onClick={onSave}
                        >
                            {mode === 'create' ? 'Create' : 'Save'}
                        </Button>
                        <Button viewMode="fancy" btnColor="gray" onClick={onCloseDialog}>
                            Cancel
                        </Button>
                    </>
                )}
            </div>
        </Dialog>
    );
}

export default PlaylistEditorDialog;
