import React, { useState } from 'react';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import { MultiSearchSelection, SearchItemInfo } from '../../../Components/MultiSearchSelection/MultiSearchSelection';

import { searchTracks } from '../../../Services/Tracks';
import { addSimilarItem } from '../../../Services/Common';

function AddSimilarTracksDialog(props: { trackId: string; isVisible: boolean; onCloseDialog: () => void }) {
    const { trackId, isVisible, onCloseDialog } = props;

    const [similarTracks, setSimilarTracks] = useState<SearchItemInfo[]>([]);
    const [resetSearch, setResetSearch] = useState(false);

    const onAddSimilarTracks = () => {
        if (similarTracks.length > 0) {
            //console.log('similarTracks = ', similarTracks);

            similarTracks.forEach(similarItem => {
                addSimilarItem('Track', trackId, similarItem.id);
            });

            setSimilarTracks([]);
            onCloseDialog();
            setResetSearch(true);
            setTimeout(() => {
                setResetSearch(false);
            }, 100);
        }
    };

    return (
        <Dialog isVisible={isVisible} title="Add Similar Tracks" onCloseDialog={onCloseDialog}>
            <MultiSearchSelection
                width="100%"
                placeholder="Type similar track name..."
                reset={resetSearch}
                selectedItems={similarTracks}
                onSearch={serachText => {
                    return searchTracks(serachText, trackId);
                }}
                onSelectionChange={selectedSearchItems => {
                    setSimilarTracks(selectedSearchItems);
                }}
            ></MultiSearchSelection>
            <div className="mt-150">
                <Button viewMode="fancy" onClick={onAddSimilarTracks} isDisabled={similarTracks.length <= 0}>
                    Add
                </Button>
            </div>
        </Dialog>
    );
}

export default AddSimilarTracksDialog;
