import React from "react";
import classes from "./Section.module.css";

export type SectionProps = {
  backgroundColor?: string;
  blurdedBackgroundImg?: string;
  withPadding?: boolean;
  children: any;
};

function Section(props: SectionProps) {
  const padding = props.withPadding === false ? classes.noPaddingSection : "";
  const bgColor = props.backgroundColor ? "bg-" + props.backgroundColor : "";

  if (!props.blurdedBackgroundImg) {
    return (
      <section className={`${classes.section} ${bgColor} ${padding}`}>
        {props.children}
      </section>
    );
  }

  const blurdedBackgroundStyle: React.CSSProperties = {
    backgroundImage: `url(${props.blurdedBackgroundImg})`,
  };

  return (
    <section
      className={classes.sectionBlurdedBackground}
      style={blurdedBackgroundStyle}
    >
      <div className={classes.sectionBlurdedBackgroundInner}>
        {props.children}
      </div>
    </section>
  );
}

export default Section;
