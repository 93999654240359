import React from 'react';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../Button/Button';

import classes from './UploadErrorDialog.module.css';

export type UploadErrorDialogProps = {
    isVisible: boolean;
    onCloseDialog: () => void;
};

function UploadErrorDialog(props: UploadErrorDialogProps) {
    const { isVisible, onCloseDialog } = props;
    const message = 'Sorry! An error occurred while uploading the file.';

    return (
        <Dialog isVisible={isVisible} title={':('} onCloseDialog={onCloseDialog}>
            <div style={{ textAlign: 'left' }}>{message}</div>
            <div className={classes.footer}>
                <Button viewMode="fancy" onClick={onCloseDialog}>
                    OK
                </Button>
            </div>
        </Dialog>
    );
}

export default UploadErrorDialog;
