import { BELLINO_BASE_URL } from '../Classes/Utilities';
import { SearchItemInfo } from '../Components/MultiSearchSelection/MultiSearchSelection';

export const searchArtists = async (serachText: string, exludedArtistId?: string) => {
    const searchResults: SearchItemInfo[] = [];

    if (serachText.trim().length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchArtists&search=${serachText}&data=${exludedArtistId ?? ''}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    responseData.forEach((artistResult: any) => {
                        const artistId = artistResult.ID;
                        const artistName = artistResult.Name;

                        searchResults.push({
                            id: artistId,
                            name: artistName
                        });
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return searchResults;
};
