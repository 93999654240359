import React, { useRef, useState } from 'react';

import { UsersLikeInfo } from '../../Types/UsersLikeInfo';

import classes from './UsersLikes.module.css';
import { updateLikeItem } from '../../Services/Common';

export type UsersLikesProps = {
    itemType: string;
    itemId: string;
    usersLikeInfo?: UsersLikeInfo;
};

function UsersLikes(props: UsersLikesProps) {
    const { itemType, itemId } = props;
    const [userLikeStatus, setUserLikeStatus] = useState(props.usersLikeInfo?.isUserLike);

    const likesCount = useRef(props.usersLikeInfo?.likesCount ?? 0);
    const unlikesCount = useRef(props.usersLikeInfo?.unlikesCount ?? 0);

    const onUserLike = () => {
        let action: 'Like' | 'Unlike' | 'DeleteLike' = 'Like';

        if (userLikeStatus === 1) {
            // Cancel like
            likesCount.current--;
            setUserLikeStatus(undefined);
            action = 'DeleteLike';
        } else if (userLikeStatus === 0) {
            // Replace unlike with like
            likesCount.current++;
            unlikesCount.current--;
            setUserLikeStatus(1);
        } else {
            likesCount.current++;
            setUserLikeStatus(1);
        }

        if (itemType && itemId) {
            updateLikeItem(itemType, itemId, action);
        }
    };

    const onUserUnlike = () => {
        let action: 'Like' | 'Unlike' | 'DeleteLike' = 'Unlike';

        if (userLikeStatus === 0) {
            // Cancel unlike
            unlikesCount.current--;
            setUserLikeStatus(undefined);
            action = 'DeleteLike';
        } else if (userLikeStatus === 1) {
            // Replace like with unlike
            unlikesCount.current++;
            likesCount.current--;
            setUserLikeStatus(0);
        } else {
            unlikesCount.current++;
            setUserLikeStatus(0);
        }

        if (itemType && itemId) {
            updateLikeItem(itemType, itemId, action);
        }
    };

    return (
        <div className={classes.usersLikes}>
            <button className={`fa fa-lg fa-thumbs-up ${userLikeStatus === 1 ? classes.green : ''}`} title="Like" onClick={onUserLike}></button>
            <span className="ml-050">{likesCount.current}</span>
            <button
                className={`fa fa-lg fa-thumbs-down ml-100 ${userLikeStatus === 0 ? classes.red : ''}`}
                title="Unlike"
                onClick={onUserUnlike}
            ></button>
            <span className="ml-050">{unlikesCount.current}</span>
        </div>
    );
}

export default UsersLikes;
