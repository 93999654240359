import React from 'react';
import classes from './Preloader.module.css';

interface PreloaderProps {
    viewMode?: 'Page' | 'Inline' | 'InlineBox';
    animation?: 'Bars' | 'Spinner' | 'MultiSpinner' | 'CrossSpinner' | 'Circle' | 'Line' | 'Search';
}

function Preloader(props: PreloaderProps) {
    const viewMode = props.viewMode ?? 'Page';
    const animation = props.animation ?? 'Bars';

    return (
        <div
            className={
                viewMode === 'Page' ? classes.pagePreloader : viewMode === 'InlineBox' ? classes.preloaderInlineBox : classes.preloaderInline
            }
        >
            <div className={classes[`animation${animation}`]}>
                {animation === 'Bars' && (
                    <>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Preloader;
