import React from 'react';

import { GetAbsoluteURL, IsLocalUrl, Validate, htmlToText } from './Utilities';
import Link from '../Components/Link/Link';

export const AnalyzedText = (text: string): JSX.Element => {
    const lines = htmlToText(text.replaceAll('<br/>', '\n')).split('\n');
    let fixedWords: JSX.Element[] = [];

    for (let l = 0; l < lines.length; l++) {
        const lineWords = lines[l].split(/[\s,]+/);
        let messageText = '';

        for (var w = 0; w < lineWords.length; w++) {
            const currentWord = lineWords[w];

            if (Validate.URL(currentWord)) {
                const url = GetAbsoluteURL(currentWord);

                if (messageText.length > 0) {
                    fixedWords.push(<span key={`link_${l}_${w}`}>{messageText} </span>);
                    messageText = '';
                }

                if (!IsLocalUrl(url)) {
                    fixedWords.push(
                        <Link key={`${l}_${w}`} href={url} target="_blank" textColor="dark-blue">
                            {currentWord}
                        </Link>
                    );
                } else {
                    const urlObj = new URL(url);
                    const localURL = urlObj.href.replace(urlObj.origin, '');

                    fixedWords.push(
                        <Link key={`${l}_${w}`} href={localURL} textColor="dark-blue">
                            {currentWord}
                        </Link>
                    );
                }
            } else {
                messageText += `${currentWord} `;
            }
        }

        if (messageText.length > 0) {
            fixedWords.push(<span key={`last_${l}`}>{messageText} </span>);
        }

        if (l < lines.length - 1) {
            fixedWords.push(
                <React.Fragment key={`br_${l}`}>
                    <br />
                </React.Fragment>
            );
        }
    }

    return <div>{fixedWords}</div>;
};
