import React, { useEffect, useRef } from 'react';

interface RecaptchaProps {
    className?: string;
}

function Recaptcha(props: RecaptchaProps) {
    const recaptchaContainerRef = useRef<HTMLDivElement | null>(null);
    const recaptchaWidgetIdRef = useRef<number | null>(null);

    useEffect(() => {
        const loadRecaptchaScript = () => {
            return new Promise<void>((resolve, reject) => {
                let script: HTMLScriptElement = document.getElementById('recaptcha-script') as HTMLScriptElement;

                if (!script) {
                    script = document.createElement('script');
                    script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
                    script.async = true;
                    script.defer = true;
                    script.id = 'recaptcha-script';
                    document.body.appendChild(script);
                }

                script.onload = () => {
                    if ((window as any).grecaptcha) {
                        resolve();
                    } else {
                        reject(new Error('reCAPTCHA script loaded, but grecaptcha is not available.'));
                    }
                };
                script.onerror = () => reject(new Error('Failed to load reCAPTCHA script'));
            });
        };

        const renderRecaptcha = () => {
            (window as any).grecaptcha.ready(() => {
                recaptchaWidgetIdRef.current = (window as any).grecaptcha.render(recaptchaContainerRef.current, {
                    sitekey: '6Lfo9yIpAAAAACwPZy6ICZlBgijFjp50VmIKTMxV'
                    /*callback: (response: string) => {
                        console.log('reCAPTCHA response:', response);
                    }*/
                });
            });
        };

        if (recaptchaContainerRef.current) {
            loadRecaptchaScript()
                .then(() => renderRecaptcha())
                .catch(err => console.error(err));
        }

        return () => {
            if (recaptchaWidgetIdRef.current !== null && (window as any).grecaptcha) {
                (window as any).grecaptcha.reset(recaptchaWidgetIdRef.current);
            }
        };
    }, []);

    return <div ref={recaptchaContainerRef} className={props.className ?? undefined}></div>;
}

export default Recaptcha;
