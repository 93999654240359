import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuSection } from '../Components/Menu/Menu';

export type DialogMode = 'NONE' | 'NO_VIDEOS' | 'UPLOAD_ERROR';

export type UiState = {
    mainSearchText: string;
    mainSearchResults: MenuSection[];
    activeDialog: DialogMode;
    activeDialogData: any;
};

export type setMainSearchDataAction = { type: 'SET_MAIN_SERACH_DATA'; payload: { searchText: string; searchResults?: any } };
export type setActiveDialogAction = { type: 'SET_ACTIVE_DIALOG'; payload: { activeDialog: DialogMode; activeDialogData?: any } };

export type UiAction = setMainSearchDataAction | setActiveDialogAction;

const defaultUserState: UiState = {
    mainSearchText: '',
    mainSearchResults: [],
    activeDialog: 'NONE',
    activeDialogData: undefined
};

const uiSlice = createSlice({
    name: 'ui',
    initialState: defaultUserState,
    reducers: {
        setMainSearchData(state, action: PayloadAction<{ searchText: string; searchResults?: any }>) {
            state.mainSearchText = action.payload.searchText;
            state.mainSearchResults = action.payload.searchResults ?? [];
        },
        setActiveDialog(state, action: PayloadAction<{ activeDialog: DialogMode; activeDialogData?: any }>) {
            state.activeDialog = action.payload.activeDialog;
            state.activeDialogData = action.payload.activeDialogData;
        }
    }
});

export const { setMainSearchData, setActiveDialog } = uiSlice.actions;

export default uiSlice.reducer;
