import { BELLINO_BASE_URL } from '../Classes/Utilities';

export const getUserItemRating = async (itemType: string, itemID: string) => {
    let rating: number | undefined;

    if (itemType.toString().trim().length > 0 && itemID.toString().trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetItemRating&type=${itemType}&itemID=${itemID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    rating = responseData;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return rating;
};

export const rateItem = async (itemType: string, itemID: string, rating: number, isFirstUserRating: boolean) => {
    let isSuccess: boolean = false;

    if (itemType.trim().length > 0 && itemID.trim().length > 0 && rating >= 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=${isFirstUserRating ? 'RateItem' : 'UpdateRateItem'}&type=${itemType}&itemID=${itemID}&rating=${rating}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const unrateItem = async (itemType: string, itemID: string) => {
    let isSuccess: boolean = false;

    if (itemType.trim().length > 0 && itemID.trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=UnrateItem&type=${itemType}&itemID=${itemID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};
