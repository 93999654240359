import React, { useState } from 'react';
import classes from './SelectionList.module.css';

export type SimpleSelectionListProps = {
    selectionMode?: 'Single-Selection' | 'Multi-Selection';
    selectionOptions: string[];
    selectedOptions?: string[];
    showPreloader?: boolean;
    showOptionsSearch?: boolean;
    onSelectionChange: (newSelectedOptions: string[]) => void;
};

function SimpleSelectionList(props: SimpleSelectionListProps) {
    const { selectionOptions, selectedOptions, showOptionsSearch, onSelectionChange } = props;
    const selectionMode = props.selectionMode ?? 'Single-Selection';
    const showPreloader = props.showPreloader ?? false;

    const [searchText, setSearchText] = useState('');

    const onOptionSelect = (optionValue: string) => {
        let newSelectedOptions = null;

        if (selectionMode === 'Multi-Selection') {
            if (selectedOptions?.includes(optionValue)) {
                // Unselect option
                newSelectedOptions = selectedOptions.filter(s => s !== optionValue);
            } else {
                // Select option
                newSelectedOptions = (selectedOptions ?? []).concat([optionValue]);
            }
        } else {
            if (!selectedOptions?.includes(optionValue)) {
                // Change selected option
                newSelectedOptions = [optionValue];
            }
        }

        if (newSelectedOptions) {
            onSelectionChange(newSelectedOptions);
        }
    };

    const onUserInputChange = (event: React.ChangeEvent) => {
        if (event) {
            const searchInputElement = event?.target as HTMLInputElement;
            const serachText = searchInputElement.value.toLocaleLowerCase().trim();
            setSearchText(serachText);
        }
    };

    if (showPreloader) {
        return <div className={classes.preloader}></div>;
    }

    return (
        <>
            {showOptionsSearch && <input className="mb-100" placeholder="Search" onChange={onUserInputChange}></input>}
            <ul className={classes.selectionList}>
                {selectionOptions
                    .filter(op => op.toLocaleLowerCase().includes(searchText))
                    .map((option, index) => (
                        <li
                            key={index}
                            className={`${classes.simpleSelectionItem} ${selectedOptions?.includes(option) ? classes.selectedSimpleSelectionItem : ''}`}
                        >
                            <button
                                onClick={() => {
                                    onOptionSelect(option);
                                }}
                            >
                                {option}
                            </button>
                        </li>
                    ))}
            </ul>
        </>
    );
}

export default SimpleSelectionList;
