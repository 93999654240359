import React from 'react';
import classes from './Score.module.css';

export type ScoreProps = {
    score: number;
    viewSize?: 'small' | 'medium' | 'large';
};

function Score(props: ScoreProps) {
    const { score, viewSize } = props;

    const scoreSizeClass = viewSize === 'large' ? classes.scoreLarge : viewSize === 'medium' ? classes.scoreMedium : classes.scoreSmall;
    let scoreRankClass = classes.scoreRank1;

    if (85 <= score && score <= 100) {
        scoreRankClass = classes.scoreRank5;
    } else if (70 <= score && score < 85) {
        scoreRankClass = classes.scoreRank4;
    } else if (56 <= score && score < 70) {
        scoreRankClass = classes.scoreRank3;
    } else if (40 <= score && score < 56) {
        scoreRankClass = classes.scoreRank2;
    }

    return <div className={`${classes.score} ${scoreSizeClass} ${scoreRankClass}`}>{score}</div>;
}

export default Score;
