import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Link.module.css';

export type LinkProps = {
    href: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    viewMode?: 'Button' | 'See-More-Hover';
    textColor?: string;
    bgColor?: 'blue' | 'green';
    children?: React.ReactNode;
};

function LinkComp(props: LinkProps) {
    const target = props?.target ?? '_self';
    let className = props.textColor ? 'text-' + props.textColor : '';

    if (props.viewMode === 'Button') {
        const bgColor = props?.bgColor ?? 'blue';
        className += ` ${classes.buttonView} ${classes[`${bgColor}ButtonView`]}`;
    } else if (props.viewMode === 'See-More-Hover') {
        className += ` ${classes[`SeeMoreView`]}`;
    }

    return (
        <Link to={props.href} target={target} className={className} rel={target === '_blank' ? 'noreferrer' : ''}>
            {props.children}
        </Link>
    );
}

export default LinkComp;
