import React, { useState } from 'react';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import GenresSelection from '../../../Components/GenresSelection/GenresSelection';

import classes from './ChartFiltersDialog.module.css';

export type ChartFiltersDialogProps = {
    isVisible: boolean;
    selectedMinYear?: number;
    selectedMaxYear?: number;
    selecteGenres?: string[];
    onCloseDialog: () => void;
    onFiltersChange: (minYear: number | undefined, maxYear: number | undefined, genres: string[]) => void;
};

function ChartFiltersDialog(props: ChartFiltersDialogProps) {
    const { isVisible, onCloseDialog, onFiltersChange } = props;

    const minYear = 1700;
    const maxYear = new Date().getFullYear();

    const [selectedMinYear, setSelectedMinYear] = useState(props.selectedMinYear);
    const [selectedMaxYear, setSelectedMaxYear] = useState(props.selectedMaxYear);
    const [selecteGenres, setSelecteGenres] = useState(props.selecteGenres ?? []);

    const onApplyFilters = () => {
        if (selectedMinYear && selectedMaxYear && selectedMinYear > selectedMaxYear) {
            onFiltersChange(selectedMaxYear, selectedMinYear, selecteGenres);
            setSelectedMinYear(selectedMaxYear);
            setSelectedMaxYear(selectedMinYear);
        } else {
            onFiltersChange(selectedMinYear, selectedMaxYear, selecteGenres);
        }

        onCloseDialog();
    };

    const onClose = () => {
        onCloseDialog();

        setTimeout(() => {
            setSelectedMinYear(props.selectedMinYear);
            setSelectedMaxYear(props.selectedMaxYear);
            setSelecteGenres(props.selecteGenres ?? []);
        }, 100);
    };

    return (
        <Dialog isVisible={isVisible} title="Chart Filters" onCloseDialog={onClose}>
            <div className="mb-50">Genre:</div>
            <GenresSelection
                selectionMode="Single-Selection"
                selectedGenres={selecteGenres}
                onSelectionChange={selectedOptions => {
                    setSelecteGenres(selectedOptions ? [selectedOptions] : []);
                }}
            ></GenresSelection>
            <div className="mt-100">
                <span className=" me-75">Music From</span>
                <select
                    style={{ width: '65px' }}
                    value={selectedMinYear ?? ''}
                    onChange={event => {
                        setSelectedMinYear(event.target.value ? parseInt(event.target.value) : undefined);
                    }}
                >
                    <option key="empty" value=""></option>
                    {[...Array<number>(maxYear - minYear + 1).keys()].map(index => {
                        const value = new Date().getFullYear() - index;
                        return (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        );
                    })}
                </select>
                <span className="ms-75 me-75">To</span>
                <select
                    style={{ width: '65px' }}
                    value={selectedMaxYear ?? ''}
                    onChange={event => {
                        setSelectedMaxYear(event.target.value ? parseInt(event.target.value) : undefined);
                    }}
                >
                    <option key="empty" value=""></option>
                    {[...Array<number>(maxYear - minYear + 1).keys()].map(index => {
                        const value = new Date().getFullYear() - index;
                        return (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className={classes.footer}>
                <Button viewMode="fancy" onClick={onApplyFilters}>
                    Ok
                </Button>
                <Button viewMode="fancy" onClick={onCloseDialog}>
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
}

export default ChartFiltersDialog;
