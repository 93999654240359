import React, { useEffect, useRef } from 'react';

import { User } from '../../Classes/User';
import { sendMessage } from '../../Services/Messages';

import Dialog from '../../Containers/Dialog/Dialog';
import Button from '../../Components/Button/Button';

import classes from './NewMessageDialog.module.css';

export type NewMessageDialogProps = {
    isVisible: boolean;
    friend?: User;
    onCloseDialog: () => void;
};

function NewMessageDialog(props: NewMessageDialogProps) {
    const { isVisible, friend, onCloseDialog } = props;

    const friendsInputElementRef = useRef(null);
    const messageInputElementRef = useRef(null);

    const onSendMessage = () => {
        const friendsInputElement = friendsInputElementRef?.current ? (friendsInputElementRef.current as HTMLInputElement) : null;
        const messageInputElement = messageInputElementRef?.current ? (messageInputElementRef.current as HTMLTextAreaElement) : null;

        const userFriendName = friendsInputElement ? friendsInputElement.value.trim() : '';
        const userMessageContent = messageInputElement ? messageInputElement.value?.trim() : '';

        const friendId = friend?.id;

        if (friendId && userMessageContent.length > 0) {
            sendMessage(friendId, userMessageContent);
            onCloseDialog();
        } else {
            if (userFriendName.length <= 0) {
                friendsInputElement?.focus();
            } else {
                messageInputElement?.focus();
            }
        }
    };

    useEffect(() => {
        if (isVisible && friend?.id) {
            const messageInputElement = messageInputElementRef?.current ? (messageInputElementRef.current as HTMLTextAreaElement) : null;
            messageInputElement?.focus();
        }
    }, [isVisible, friend?.id]);

    return (
        <Dialog isVisible={isVisible} title={`New Message`} onCloseDialog={onCloseDialog}>
            {friend?.fullName ? (
                <p className="fs-105 mb-100">
                    To: <b>{friend.fullName}</b>
                </p>
            ) : (
                <input ref={friendsInputElementRef} className="mb-75" placeholder="Friend Name"></input>
            )}
            <textarea ref={messageInputElementRef} className={classes.messageInput} placeholder="Message"></textarea>
            <div className={classes.footer}>
                <Button viewMode="fancy" onClick={onSendMessage}>
                    Send
                </Button>
            </div>
        </Dialog>
    );
}

export default NewMessageDialog;
