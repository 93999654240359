import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playPlaylist } from '../../redux/musicPlayerSlice';

import { UserInfo } from '../../Types/User';
import { TrackInfo } from '../../Types/Track';
import { RatedItemInfo } from '../../Types/RatedItem';
import { User } from '../../Classes/User';
import { BELLINO_BASE_URL, FetchPageData, FormatDate } from '../../Classes/Utilities';
import { buildPlaylistFromUserLibrary } from '../../Services/Playlists';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Button from '../../Components/Button/Button';
import Link from '../../Components/Link/Link';
import UserAvatar from '../../Components/UserAvatar/UserAvatar';
import ChartTable, { ChartItem } from '../../Components/Charts/ChartTable/ChartTable';
import Score from '../../Components/Score/Score';
import LiveDate from '../../Components/LiveDate/LiveDate';
import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import { UserStatistics, UserStatisticsProps } from '../../Components/MusicStatistics/MusicStatistics';
import ArtistLink from '../../Components/ArtistLink/ArtistLink';

import NewMessageDialog from '../_partials/NewMessageDialog';
import FriendshipDialog, { FriendshipDialogMode } from '../_partials/FriendshipDialog';

import classes from './User.module.css';

function UserPage(props: any) {
    const [searchParams] = useSearchParams();
    const userID = searchParams.get('uid');

    const { userSessionData, friendsIds, friendsRequestsIds } = useUserSessionData();
    const { updatePageMetdata } = usePageMetdata();

    const dispatch = useDispatch<AppDispatch>();

    const isLoading = useRef<boolean>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [user, setUser] = useState<User>(new User());
    const [userStatistics, setUserStatistics] = useState<UserStatisticsProps>();
    const [recentlyPlayed, setRecentlyPlayed] = useState<TrackInfo[]>([]);
    const [recentlyRated, setRecentlyRated] = useState<RatedItemInfo[]>([]);
    const [userTopArtists, setUserTopArtists] = useState<ChartItem[]>([]);
    const [userTopAlbums, setUserTopAlbums] = useState<ChartItem[]>([]);
    const [userTopTracks, setUserTopTracks] = useState<ChartItem[]>([]);

    const [friendshipDialogMode, setFriendshipDialogMode] = useState<FriendshipDialogMode>('hidden');
    const [isNewMessageDialogOpen, setIsNewMessageDialogOpen] = useState(false);

    const isMyUser = useMemo(() => userSessionData.userInfo?.id?.toString?.() === userID, [userID, userSessionData.userInfo?.id]);
    const isMyFriend = useMemo(() => (userID ? friendsIds.includes(userID.toString()) : false), [friendsIds, userID]);
    const isSentFriendRequest = useMemo(() => (userID ? friendsRequestsIds.includes(userID.toString()) : false), [friendsRequestsIds, userID]);

    useEffect(() => {
        if (!isLoading.current && userID) {
            isLoading.current = true;
            setUser(new User());
            setIsLoaded(false);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/user?id=${userID}`).then((response: any) => {
                if (response.success) {
                    updatePageMetdata(response.result?.metadata);
                    setUser(new User(response.result?.userInfo as UserInfo));
                    setUserStatistics(response.result?.userStatistics);
                    setRecentlyPlayed(response.result?.recentlyPlayed);
                    setRecentlyRated(response.result?.recentlyRated);
                    setUserTopArtists(
                        response.result?.userTopArtists?.map((artist: any, index: number) => {
                            return {
                                type: 'Artist',
                                id: artist.id,
                                position: index + 1,
                                score: artist.score,
                                children: (
                                    <Link key={artist.id} href={`/artist?aid=${artist.id}`}>
                                        {artist.name}
                                    </Link>
                                )
                            };
                        })
                    );
                    setUserTopAlbums(
                        response.result?.userTopAlbums?.map((album: any, index: number) => {
                            return {
                                type: 'Album',
                                id: album.id,
                                position: index + 1,
                                score: album.score,
                                children: (
                                    <span key={album.id}>
                                        {album.artists.map((albumArtist: any, index: number) => (
                                            <span key={albumArtist.id}>
                                                <Link href={`/artist?aid=${albumArtist.id}`}>{albumArtist.name}</Link>
                                                {index + 1 < album.artists.length && <span>, </span>}
                                            </span>
                                        ))}
                                        <span> - </span>
                                        <Link key={album.id} href={`/album?aid=${album.id}`}>
                                            {album.name}
                                        </Link>
                                    </span>
                                )
                            };
                        })
                    );
                    setUserTopTracks(
                        response.result?.userTopTracks?.map((track: any, index: number) => {
                            return {
                                type: 'Track',
                                id: track.id,
                                position: index + 1,
                                score: track.score,
                                children: (
                                    <span key={track.id}>
                                        <ArtistLink artists={track.artists}></ArtistLink>
                                        <span> - </span>
                                        <Link key={track.id} href={`/track?tid=${track.id}`}>
                                            {track.name}
                                        </Link>
                                    </span>
                                )
                            };
                        })
                    );
                }

                isLoading.current = false;
                setIsLoaded(true);
            });
        }
    }, [userID, updatePageMetdata]);

    if (!userID || (!user.isInit && isLoaded)) {
        return <NotFound></NotFound>;
    }

    const tracksAsListItems = recentlyPlayed.map(track => {
        return {
            titleStr: track.name,
            title: <Link href={`/track?tid=${track.id}`}>{track.name}</Link>,
            subTitle: track?.artists?.map((artist, index) => (
                <span key={index}>
                    <Link href={`/artist?aid=${artist.id}`}>{artist.name}</Link>
                    {index < track.artists.length - 1 ? <span>, </span> : <></>}
                </span>
            )),
            subInfo: <LiveDate utcDate={track.date ?? ''}></LiveDate>,
            image: track.imgPath,
            imageHref: `/track?tid=${track.id}`
        } as GenericListItem;
    });

    const ratedItemsAsListItems = recentlyRated.map(item => {
        return {
            titleStr: item.name,
            title: (
                <Link
                    href={
                        item.type === 'Track'
                            ? `/track?tid=${item.id}`
                            : item.type === 'Album'
                              ? `/album?aid=${item.id}`
                              : `/artist?aid=${item.id}`
                    }
                >
                    {item.name}
                </Link>
            ),
            subTitle: item?.artists?.map((artist, index) => (
                <span key={index}>
                    <Link href={`/artist?aid=${artist.id}`}>{artist.name}</Link>
                    {index < item.artists.length - 1 ? <span>, </span> : <></>}
                </span>
            )),
            subInfo: <LiveDate utcDate={item.date}></LiveDate>,
            image: item.imgPath,
            imageHref: `/track?tid=${item.id}`,
            endSection: (
                <div className="me-75">
                    <Score score={item.rating}></Score>
                </div>
            )
        } as GenericListItem;
    });

    return (
        <AppMainContainer showPreloader={!user.isInit}>
            <Section blurdedBackgroundImg={user.userImg}>
                <div className={classes.userInfoContainer}>
                    <div>
                        {user.rawUserInfo && (
                            <UserAvatar
                                userInfo={user.rawUserInfo}
                                viewMode="image-only"
                                avatarSize="large"
                                avatarURL={`/photos?type=user&id=${user.id}`}
                            ></UserAvatar>
                        )}
                    </div>
                    <div>
                        <div className={classes.userName}>{user.fullName}</div>
                        <div className="fs-120 text-gray">{user.userDetails}</div>
                        <div className={classes.userMusicStatistics}>
                            <UserStatistics
                                numOfPlays={userStatistics?.numOfPlays ?? 0}
                                numOfArtists={userStatistics?.numOfArtists ?? 0}
                                registrationDate={user.registrationDate ? FormatDate(user.registrationDate, 'Normal', '-') : ''}
                            ></UserStatistics>
                        </div>
                        <div>
                            {isMyUser ? (
                                <>
                                    <Link href="/account" viewMode={'Button'}>
                                        Edit Profile
                                    </Link>
                                    <Button
                                        className="ml-100"
                                        viewMode="fancy"
                                        onClick={async () => {
                                            const userLibraryPlaylist = await buildPlaylistFromUserLibrary(userID);
                                            dispatch(playPlaylist(userLibraryPlaylist));
                                        }}
                                    >
                                        ► Play Library
                                    </Button>
                                </>
                            ) : userSessionData.userInfo?.id ? (
                                <>
                                    <Button
                                        viewMode="fancy"
                                        onClick={() => {
                                            setFriendshipDialogMode(
                                                isMyFriend ? 'unfriend' : isSentFriendRequest ? 'cancel-request' : 'add-friend'
                                            );
                                        }}
                                    >
                                        {isMyFriend ? 'Unfriend' : isSentFriendRequest ? 'Cancel Request' : 'Add Friend'}
                                    </Button>
                                    <Button
                                        className="ml-100"
                                        viewMode="fancy"
                                        onClick={() => {
                                            setIsNewMessageDialogOpen(true);
                                        }}
                                    >
                                        Message
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </Section>

            <Section backgroundColor="white">
                <div className={classes.sideBySide}>
                    <div>
                        <Title>
                            <Link href={`/listenings?uid=${user.id}`} viewMode="See-More-Hover">
                                Recently Played Tracks
                            </Link>
                        </Title>
                        {tracksAsListItems.length ? (
                            <GenericList items={tracksAsListItems} spaceBetweenItems={false}></GenericList>
                        ) : (
                            <div>No Played Tracks</div>
                        )}
                    </div>
                    <div>
                        <Title>
                            <Link href={`/ratings?uid=${user.id}`} viewMode="See-More-Hover">
                                Recently Rated Items
                            </Link>
                        </Title>
                        {ratedItemsAsListItems.length ? (
                            <GenericList items={ratedItemsAsListItems} spaceBetweenItems={false}></GenericList>
                        ) : (
                            <div>No Rated Items</div>
                        )}
                    </div>
                </div>
            </Section>

            {userTopArtists.length > 0 && (
                <Section backgroundColor="white">
                    <Title>
                        <Link href={`/usercharts?type=artist&uid=${user.id}`} viewMode="See-More-Hover">
                            Top Artists
                        </Link>
                    </Title>
                    <ChartTable chartItems={userTopArtists}></ChartTable>
                </Section>
            )}

            {userTopTracks.length > 0 && (
                <Section backgroundColor="white">
                    <Title>
                        <Link href={`/usercharts?type=track&uid=${user.id}`} viewMode="See-More-Hover">
                            Top Tracks
                        </Link>
                    </Title>
                    <ChartTable chartItems={userTopTracks}></ChartTable>
                </Section>
            )}

            {userTopAlbums.length > 0 && (
                <Section backgroundColor="white">
                    <Title>
                        <Link href={`/usercharts?type=album&uid=${user.id}`} viewMode="See-More-Hover">
                            Top Albums
                        </Link>
                    </Title>
                    <ChartTable chartItems={userTopAlbums}></ChartTable>
                </Section>
            )}

            <FriendshipDialog
                mode={friendshipDialogMode}
                friend={user}
                onCloseDialog={() => setFriendshipDialogMode('hidden')}
            ></FriendshipDialog>

            <NewMessageDialog
                isVisible={isNewMessageDialogOpen}
                friend={user}
                onCloseDialog={() => {
                    setIsNewMessageDialogOpen(false);
                }}
            ></NewMessageDialog>
        </AppMainContainer>
    );
}

export default UserPage;
