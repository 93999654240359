import React from 'react';

import { User } from '../../Classes/User';
import useUserSessionData from '../../hooks/useUserSessionData';

import MenuButton from '../MenuButton/MenuButton';

import classes from './Hamburger.module.css';

export type HamburgerProps = {
    children?: React.ReactNode;
};

function Hamburger(props: HamburgerProps) {
    const { userSessionData } = useUserSessionData();
    const user = userSessionData.userInfo ? new User(userSessionData.userInfo) : null;

    const loggedInUserMenuOptions = user
        ? [
              {
                  menuItems: [
                      { text: 'My Profile', href: `/user?uid=${user.id}`, iconName: 'fa-user' },
                      {
                          text: '» Playlists',
                          href: '/playlists',
                          isSubItem: true
                      },
                      {
                          text: '» Photos',
                          href: `/photos?type=user&id=${user.id}`,
                          isSubItem: true
                      },
                      {
                          text: '» Friends',
                          href: '/friends',
                          isSubItem: true
                      },
                      {
                          text: '» Messages',
                          href: '/messages',
                          isSubItem: true
                      }
                  ]
              },
              {
                  menuItems: [
                      { text: 'Acount', href: '/account', iconName: 'fa-gear' },
                      { text: 'Logout', href: '/logout', iconName: 'fa-right-from-bracket' }
                  ]
              }
          ]
        : [];

    return (
        <>
            <MenuButton
                buttonCustomClass={classes.hamburger}
                buttonSize="medium"
                buttonChildren={<i className="fa fa-light fa-bars"></i>}
                menuSections={[
                    {
                        menuItems: [
                            { text: 'Home', href: '/home', iconName: 'fa-house' },
                            { text: 'Charts', href: '/charts', iconName: 'fa-chart-simple' },
                            { text: 'Radio', href: '/radio', iconName: 'fa-radio' }
                        ]
                    },
                    ...loggedInUserMenuOptions
                ]}
            ></MenuButton>
        </>
    );
}

export default Hamburger;
