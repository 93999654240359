import React, { useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { Validate } from '../../Classes/Utilities';
import { signinUser } from '../../Services/User';
import { fbInit, fbLogIn } from '../../Services/External/Facebook';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Button from '../../Components/Button/Button';
import Link from '../../Components/Link/Link';
import Logo from '../../Components/Logo/Logo';

import classes from './Index.module.css';

function IndexPage(props: any) {
    usePageMetdata({ title: '' });

    const emailInputElementRef = useRef(null);
    const passwordInputElementRef = useRef(null);
    const errorDivElementRef = useRef(null);

    const { userSessionData, updateUserSessionData } = useUserSessionData();

    const [isLoggedinUser, setIsLoggedinUser] = useState(false);

    const onSigninClick = async (event: any) => {
        event.preventDefault();

        const emailInputElement = emailInputElementRef?.current ? (emailInputElementRef.current as HTMLInputElement) : null;
        const passwordInputElement = passwordInputElementRef?.current ? (passwordInputElementRef.current as HTMLInputElement) : null;
        const errorDivElement = errorDivElementRef?.current ? (errorDivElementRef.current as HTMLElement) : null;

        if (errorDivElement) {
            // Reset and hide error div
            errorDivElement.innerHTML = '';
            errorDivElement.classList.add('hidden');
        }

        if (emailInputElement?.value?.trim()?.length && passwordInputElement?.value?.trim()?.length && errorDivElement) {
            if (!Validate.Email(emailInputElement.value)) {
                errorDivElement.innerHTML = 'Invalid email!';
                errorDivElement.classList.remove('hidden');
            } else if (!Validate.Password(passwordInputElement.value)) {
                errorDivElement.innerHTML = 'Invalid password! Password must be at least 6 characters long.';
                errorDivElement.classList.remove('hidden');
            } else {
                const userSessionData = await signinUser(emailInputElement.value, passwordInputElement.value);

                if (userSessionData) {
                    updateUserSessionData(userSessionData);
                    setIsLoggedinUser(true);
                } else {
                    errorDivElement.innerHTML = 'Incorrect email or password. Please try again.';
                    errorDivElement.classList.remove('hidden');
                }
            }
        } else if (!emailInputElement?.value?.trim()?.length) {
            emailInputElement?.focus();
        } else if (!passwordInputElement?.value?.trim()?.length) {
            passwordInputElement?.focus();
        }
    };

    const onFbSigninClick = async () => {
        if (await fbInit()) {
            const userSessionData = await fbLogIn();

            if (userSessionData) {
                updateUserSessionData(userSessionData);
                setIsLoggedinUser(true);
            }
        }
    };

    const featureBoxStyle = {
        width: '200px',
        height: '200px',
        backgroundSize: 'contain'
    };

    if (isLoggedinUser || (userSessionData.isReady && userSessionData.sessionId)) {
        return <Navigate to="/home" />;
    }

    return (
        <AppMainContainer>
            <section>
                <div className={classes.loginBackground} style={{ backgroundImage: `url('Images/turntable.jpg')` }}>
                    <div className={classes.loginBackgroundOverlay}>
                        <div className={classes.loginWrapper}>
                            <Logo href="/home" text="Bellino" viewSize="large"></Logo>
                            <Button className={classes.facebookLogin} viewMode="fancy" onClick={onFbSigninClick}>
                                Sign in with Facebook
                            </Button>
                            <div className={classes.emailLogin}>OR with your email:</div>
                            <div className="LoginDiv">
                                <form onSubmit={onSigninClick}>
                                    <input ref={emailInputElementRef} type="email" placeholder="Email" />
                                    <input ref={passwordInputElementRef} type="password" placeholder="Password" autoComplete="off" />
                                    <div ref={errorDivElementRef} className={`hidden ${classes.errorMessage}`}></div>
                                    <Button viewMode="fancy" onClick={onSigninClick}>
                                        Sign in
                                    </Button>
                                    <Link href="/signup" viewMode={'Button'} bgColor="green">
                                        Create new account
                                    </Link>
                                    <Link href="/forgot_password">Forgotten your password?</Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className={classes.featureBoxes}>
                    <div className={classes.featureBox}>
                        <span
                            className="center-cropped-img"
                            style={{ ...featureBoxStyle, backgroundImage: `url('Images/headphones.svg')` }}
                        ></span>
                        <h3 className="mb-50">Free Listening</h3>
                        <p>Listen to your favorite music anywhere, anytime. From favorite classics to the latest hits</p>
                    </div>
                    <div className={classes.featureBox}>
                        <span className="center-cropped-img" style={{ ...featureBoxStyle, backgroundImage: `url('Images/charts.svg')` }}></span>
                        <h3 className="mb-50">Music Charts</h3>
                        <p>Watch thousands of music charts including your own dynamic charts based on your favorite music</p>
                    </div>
                    <div className={classes.featureBox}>
                        <span className="center-cropped-img" style={{ ...featureBoxStyle, backgroundImage: `url('Images/share.svg')` }}></span>
                        <h3 className="mb-50">Music Sharing</h3>
                        <p>Share and recommend your music library with your friends</p>
                    </div>
                    <div className={classes.featureBox}>
                        <span
                            className="center-cropped-img"
                            style={{ ...featureBoxStyle, backgroundImage: `url('Images/playlist.png')` }}
                        ></span>
                        <h3 className="mb-50">Music Playlists</h3>
                        <p>Pick or create your playlists according to your music taste</p>
                    </div>
                    <div className={classes.featureBox}>
                        <span
                            className="center-cropped-img"
                            style={{ ...featureBoxStyle, backgroundSize: '90%', backgroundImage: `url('Images/Discover.svg')` }}
                        ></span>
                        <h3 className="mb-50">Explore Music</h3>
                        <p>Explore and discover new music according to your current music taste</p>
                    </div>
                    <div className={classes.featureBox}>
                        <span className="center-cropped-img" style={{ ...featureBoxStyle, backgroundImage: `url('Images/noads.png')` }}></span>
                        <h3 className="mb-50">No Advertising</h3>
                        <p>No advertising, No spam. Just enjoy pure free online music</p>
                    </div>
                </div>
            </section>
        </AppMainContainer>
    );
}

export default IndexPage;
