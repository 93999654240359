import React, { useEffect, useRef, useState } from 'react';

import { BELLINO_BASE_URL } from '../../Classes/Utilities';
import { UserInfo } from '../../Types/User';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import UserAvatar from '../../Components/UserAvatar/UserAvatar';
import Search from '../../Components/Search/Search';
import CountriesSelection from '../../Components/CountriesSelection/CountriesSelection';

import classes from './Users.module.css';
import Preloader from '../../Components/Preloader/Preloader';

function UsersPage(props: any) {
    usePageMetdata({ title: 'Users' });

    const { userSessionData, friendsIds } = useUserSessionData();

    const [users, setUsers] = useState<UserInfo[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const inputElementRef = useRef<HTMLInputElement>();
    const country = useRef('');

    const onSearch = (searchText: string) => {
        return new Promise<any>(async resolve => {
            fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchPeople&search=${searchText}&gender=&cntry=${country.current}`, {
                method: 'GET'
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(response => {
                    resolve(
                        response?.map((user: any) => {
                            return {
                                id: user.ID,
                                firstName: user.FirstName ?? '',
                                lastName: user.LastName ?? '',
                                gender: user.Gender,
                                country: user.Country ?? '',
                                birthday: user.Birthday ?? '',
                                imgPath: user.ProfileImage
                            } as UserInfo;
                        }) ?? []
                    );
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    resolve([]);
                });
        });
    };

    useEffect(() => {
        if (userSessionData.isReady) {
            country.current = userSessionData.userInfo?.country ?? '';
        }
    }, [userSessionData]);

    return (
        <AppMainContainer backgroundColor="off-white">
            <Section>
                <Title>Users</Title>
                <div className={classes.filters}>
                    <Search
                        viewMode="rounded"
                        placeholder="Search People"
                        allowEmptySearchTerm={true}
                        onReady={refInputElement => {
                            inputElementRef.current = refInputElement;
                            if (inputElementRef.current) {
                                inputElementRef.current.dispatchEvent(new Event('propertychange'));
                            }
                        }}
                        onSearch={onSearch}
                        onSearching={status => {
                            setIsSearching(status);
                        }}
                        onSearchResults={(searchText, searchResults) => {
                            setUsers(searchResults);
                        }}
                    ></Search>
                    <span>From</span>
                    <CountriesSelection
                        selectionMode="Single-Selection"
                        selectedCountries={country.current ? [country.current] : []}
                        onSelectionChange={selectedCountry => {
                            country.current = selectedCountry[0] ?? '';
                            if (inputElementRef.current) {
                                inputElementRef.current.dispatchEvent(new Event('propertychange'));
                            }
                        }}
                    ></CountriesSelection>
                </div>
                {isSearching ? (
                    <Preloader viewMode="Inline" animation="Search"></Preloader>
                ) : users.length ? (
                    <ul className={classes.usersGrid}>
                        {users.map(user => (
                            <li key={user.id}>
                                <UserAvatar
                                    userInfo={user}
                                    viewMode="DetailedBox"
                                    friendshipButton={
                                        friendsIds.includes(user.id.toString())
                                            ? 'unfriend'
                                            : userSessionData.userInfo?.id && userSessionData.userInfo.id?.toString() !== user.id.toString()
                                              ? 'add'
                                              : undefined
                                    }
                                ></UserAvatar>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div>No users found</div>
                )}
            </Section>
        </AppMainContainer>
    );
}

export default UsersPage;
