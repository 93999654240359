import React from 'react';

import { ArtistInfo } from '../../Types/Artist';
import Link from '../Link/Link';

export type ArtistLinkProps = {
    artists: ArtistInfo[];
    textColor?: string;
};

function ArtistLink(props: ArtistLinkProps) {
    const { artists, textColor } = props;
    const isVariousArtists = artists.find(a => a.id?.toString() === '0');

    return (
        <>
            {isVariousArtists ? (
                <Link href={'/variousartists'} textColor={textColor}>
                    Various Artists
                </Link>
            ) : (
                artists.map((artist: any, index: number) => (
                    <span key={artist.id}>
                        <Link href={`/artist?aid=${artist.id}`} textColor={textColor}>
                            {artist.name}
                        </Link>
                        {index + 1 < artists.length && <span>, </span>}
                    </span>
                ))
            )}
        </>
    );
}

export default ArtistLink;
