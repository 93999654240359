import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Logo.module.css';

export type LogoProps = {
    viewSize?: 'small' | 'medium' | 'large';
    text?: string;
    href?: string;
};

function Logo(props: LogoProps) {
    const { viewSize, text, href = '/' } = props;

    const className = viewSize === 'large' ? classes.largeLogo : viewSize === 'medium' ? classes.mediumLogo : classes.smallLogo;

    return (
        <Link to={href} className={`${classes.logo} ${className}`}>
            {text}
        </Link>
    );
}

export default Logo;
