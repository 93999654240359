import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, FetchPageData } from '../../Classes/Utilities';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Link from '../../Components/Link/Link';
import ChartList, { ChartItem } from '../../Components/Charts/ChartList/ChartList';

import classes from './Genre.module.css';

function GenrePage(props: any) {
    const [searchParams] = useSearchParams();
    const genreName = searchParams.get('name') ?? '';

    usePageMetdata({ title: genreName });

    const isLoading = useRef<boolean>();

    const [wikiText, setWikiText] = useState('Loading...');
    const [wikiURL, setWikiURL] = useState('');
    const [chartArtists, setChartArtists] = useState<ChartItem[]>([]);

    const loadWiki = useCallback(async () => {
        var pageName = genreName.replace('-', ' ');
        pageName = pageName + ' music';
        pageName = encodeURIComponent(pageName);

        // 1. Search wikipedia page name according to genre name
        // 2. Get summary of wiki value
        let response = await fetch(
            `https://en.wikipedia.org/w/api.php?action=query&format=json&list=search&srsearch=${pageName}&format=json&formatversion=2&origin=*`
        );

        if (!response?.ok) {
            setWikiText("Can't Load Wiki ... ");
        } else {
            const data = await response.json();
            let pageURL = '';

            if (data && data.query && data.query.search) {
                var bestIndex = 0;

                for (var i = 0; i < 5; i++) {
                    if (data.query.search[i] && data.query.search[i].title) {
                        let curTitle = data.query.search[i].title.toLowerCase();

                        if (curTitle === genreName.toLowerCase() || curTitle === genreName.toLowerCase() + ' music') {
                            bestIndex = i;
                            break;
                        }
                    }
                }

                pageName = encodeURIComponent(data.query.search[bestIndex].title);
                pageURL = 'https://en.wikipedia.org/wiki/' + pageName;

                response = await fetch(
                    `https://en.wikipedia.org/w/api.php?action=parse&format=json&prop=text&section=0&page=${pageName}&format=json&formatversion=2&origin=*`
                );

                if (!response?.ok) {
                    setWikiText("Can't Load Wiki ... ");
                } else {
                    const data = await response.json();
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = data.parse.text;

                    // remove any references / style
                    tempDiv.querySelectorAll('sup, style').forEach(elem => {
                        elem.remove();
                    });

                    // remove cite error
                    /*tempDiv.querySelectorAll('.mw-ext-cite-error').forEach(elem => {
                        elem.remove();
                    });*/

                    let wikiText = '';

                    // Pure wiki text
                    tempDiv.querySelectorAll('p').forEach(elem => {
                        wikiText += elem.innerText + '\n';
                    });

                    if (wikiText.length > 0) {
                        setWikiText(wikiText);
                        setWikiURL(pageURL);
                    }
                }
            }
        }
    }, [genreName]);

    useEffect(() => {
        if (genreName) {
            loadWiki();
        }
    }, [genreName, loadWiki]);

    useEffect(() => {
        if (genreName && !isLoading.current) {
            isLoading.current = true;
            setChartArtists([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/genre?name=${genreName}`).then((response: any) => {
                if (response.success) {
                    const fetchedChartItems = response.result?.topArtists?.map((chartArtist: any, cIndex: number) => {
                        const itemId = chartArtist.id;
                        const mainTitle = chartArtist.name;
                        const mainTitleHref = `/artist?aid=${chartArtist.id}`;
                        let subTitle = <span className="fs-105 text-gray">{chartArtist.origin}</span>;

                        return {
                            id: itemId,
                            mainTitleHref,
                            mainTitle,
                            subTitle,
                            date: '',
                            position: cIndex + 1,
                            image: `${BELLINO_BASE_URL}/${chartArtist.image}`,
                            score: chartArtist.score,
                            tags: chartArtist.genre?.split(';')?.map((genre: any, gIndex: number) => {
                                return {
                                    id: `${genre}_${gIndex}`,
                                    name: genre,
                                    href: `/genre?name=${encodeURIComponent(genre)}`
                                };
                            })
                        } as ChartItem;
                    });

                    setChartArtists(fetchedChartItems);
                } else {
                    setChartArtists([]);
                }

                isLoading.current = false;
            });
        }
    }, [genreName]);

    if (genreName.trim().length <= 0) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer showPreloader={isLoading.current}>
            <Section backgroundColor="off-white">
                <Title>{genreName}</Title>
                <div className={classes.genreWiki}>
                    <div>
                        {wikiText
                            .split('\n')
                            .filter(t => t.trim().length > 0)
                            .map((wikiParagraph, index) => (
                                <p key={index}>
                                    {wikiParagraph}
                                    <br />
                                </p>
                            ))}
                    </div>
                </div>
                {wikiURL && (
                    <div className="mt-100">
                        <Link href={wikiURL} target="_blank" textColor="dark-blue">
                            » Taken from Wikipedia
                        </Link>
                    </div>
                )}
            </Section>
            <Section backgroundColor="white">
                <Title>
                    <Link href={`/charts?time=alltime&type=top_artists&genre=${genreName}`} viewMode="See-More-Hover">
                        Top Artists
                    </Link>
                </Title>
                <ChartList chartItems={chartArtists}></ChartList>
            </Section>
        </AppMainContainer>
    );
}

export default GenrePage;
