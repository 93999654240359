import { PhotoInfo } from '../Types/Photo';
import { ReleaseInfo } from '../Types/ReleaseInfo';
import { DEFAULT_ARTIST_IMG, BuildAmazonBuyLink, getImageFullPath, RandomIntFromInterval } from './Utilities';

export class Artist {
    public isInit: boolean = false;
    public id: string = '';
    public name: string = '';
    public country: string = '';
    public coverImgPath: string = DEFAULT_ARTIST_IMG;
    public genres: string[] = [];
    public photos: PhotoInfo[] = [];
    public releases: ReleaseInfo[] = [];
    public amazonBuyLink: string = '';

    public constructor(info?: any) {
        this.init(info);
    }

    public init(info: any) {
        if (info) {
            this.id = info.id;
            this.name = info.name;
            this.country = info.country;
            this.genres = info.genres?.filter((g: any) => g) ?? [];
            this.photos = info.photos;
            this.amazonBuyLink = BuildAmazonBuyLink(info.name);

            const randomImageIndex = info.photos?.length > 0 ? RandomIntFromInterval(0, info.photos.length - 1) : -1;
            this.coverImgPath = getImageFullPath(randomImageIndex >= 0 ? info.photos[randomImageIndex]?.src : DEFAULT_ARTIST_IMG, 'Artist');

            info.releases?.forEach((release: any) => {
                const artistRelease: ReleaseInfo = {
                    id: release.id,
                    name: release.name,
                    artists: release?.artists ?? [],
                    genres: release.genres ?? [],
                    date: release.date,
                    imgPath: release.imgPath
                };

                this.releases.push(artistRelease);
            });

            /*this.photos.forEach((photo: PhotoInfo) => {
                photo.src = getImageFullPath(photo.src, 'Artist');
            });*/

            /*info.tracks?.forEach((track: any) => {
                const albumTrack: AlbumTrack = {
                    id: track.id,
                    name: track.name,
                    cdNumber: track?.cdNumber ?? 1,
                    position: track?.position,
                    score: track?.score ?? 0,
                    artists: track?.artists ?? []
                };

                this.tracks.push(albumTrack);
            });*/

            this.isInit = true;
        }
    }
}
