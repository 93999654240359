import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playPlaylist } from '../../redux/musicPlayerSlice';
import { setActiveDialog } from '../../redux/uiSlice';

import { BELLINO_BASE_URL, DEFAULT_ARTIST_IMG, FetchPageData } from '../../Classes/Utilities';
import { Artist } from '../../Classes/Artist';
import { TrackInfo } from '../../Types/Track';
import { ReleaseInfo } from '../../Types/ReleaseInfo';
import { MusicStatistics } from '../../Types/MusicStatistics';
import { ChartItemInfo } from '../../Types/ChartItem';
import { CommentInfo } from '../../Types/CommentInfo';
import { buildPlaylistByItem, buildPlaylistByTrackIds } from '../../Services/Playlists';

import NotFound from '../NotFound/NotFound';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import SliderList from '../../Containers/SliderList/SliderList';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Tag from '../../Components/Tag/Tag';
import Link from '../../Components/Link/Link';
import Image from '../../Components/Image/Image';
import MenuButton from '../../Components/MenuButton/MenuButton';
import MusicItemStatistics from '../../Components/MusicStatistics/MusicStatistics';
import ReleaseCard from '../../Components/ReleaseCard/ReleaseCard';
import UsersComments from '../../Components/UsersComments/UsersComments';
import Card from '../../Components/Card/Card';
import Button from '../../Components/Button/Button';
import ChartTable from '../../Components/Charts/ChartTable/ChartTable';

import FriendsListenersDialog, { FriendsListener } from '../_partials/FriendsListenersDialog';
import RateDialog from '../_partials/RateDialog';
import RecommendDialog from '../_partials/RecommendDialog';
import ImproveMetadataDialog from '../_partials/ImproveMetadataDialog';
import MyPlaysDialog from '../_partials/MyPlaysDialog';
import AddSimilarArtistsDialog from './partials/AddSimilarArtistsDialog';

import classes from './Artist.module.css';

function ArtistPage(props: any) {
    const [searchParams] = useSearchParams();
    const artistID = searchParams.get('aid');

    const { userSessionData } = useUserSessionData();
    const { updatePageMetdata } = usePageMetdata();
    const dispatch = useDispatch<AppDispatch>();

    const isLoading = useRef<boolean>();

    const [artist, setArtist] = useState<Artist>(new Artist());
    const [artistStatistics, setArtistStatistics] = useState<MusicStatistics>();
    const [artistReleases, setArtistReleases] = useState<ReleaseInfo[]>([]);
    const [artistTopTracks, setArtistTopTracks] = useState<ChartItemInfo[]>([]);
    const [artistTopReleases, setArtistTopReleases] = useState<ChartItemInfo[]>([]);
    const [similarArtists, setSimilarArtists] = useState<Artist[]>([]);
    const [comments, setComments] = useState<CommentInfo[]>([]);

    const [isFriendsListenersDialog, setIsFriendsListenersDialog] = useState(false);
    const [isMyPlaysDialogVisible, setIsMyPlaysDialogVisible] = useState(false);
    const [isRateDialogVisible, setIsRateDialogVisible] = useState(false);
    const [isRecommendDialogVisible, setIsRecommendDialogVisible] = useState(false);
    const [isImproveMetadataDialogVisible, setIsImproveMetadataDialogVisible] = useState(false);
    const [isAddSimilarArtistsDialogVisible, setIsAddSimilarArtistsDialogVisible] = useState(false);

    useEffect(() => {
        if (!isLoading.current && artistID) {
            isLoading.current = true;
            setArtist(new Artist());

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/artist?id=${artistID}`).then((response: any) => {
                if (response.success) {
                    updatePageMetdata(response.result?.metadata);
                    setArtist(new Artist(response.result?.artistInfo));
                    setArtistStatistics({
                        listeners: response.result?.artistStatistics?.listeners,
                        plays: response.result?.artistStatistics?.plays,
                        myPlays: response.result?.artistStatistics?.myPlays,
                        friendsPlays: response.result?.artistStatistics?.friendsPlays,
                        rating: undefined
                    });
                    setArtistReleases(response.result?.artistDiscography as ReleaseInfo[]);
                    setArtistTopTracks(
                        response.result?.artistsTopTracks?.map((track: any, index: number) => {
                            return {
                                position: index + 1,
                                id: track.id,
                                name: track.name,
                                score: track.score,
                                videos: [
                                    {
                                        externalId: track.video,
                                        duration: track.videoDuration
                                    }
                                ]
                            } as ChartItemInfo;
                        })
                    );
                    setArtistTopReleases(
                        response.result?.artistsTopAlbums?.map((release: any, index: number) => {
                            return {
                                position: index + 1,
                                id: release.id,
                                name: release.name,
                                score: release.score,
                                videos: []
                            } as ChartItemInfo;
                        })
                    );
                    setSimilarArtists(
                        response.result?.similarArtists?.map((sa: any) => {
                            return new Artist({ id: sa.id, name: sa.name, photos: [{ src: sa.image }] });
                        })
                    );
                    setComments(response.result?.comments);
                } else {
                    setArtist(new Artist());
                }

                setTimeout(() => {
                    isLoading.current = false;
                }, 100);
            });
        }
    }, [artistID, updatePageMetdata]);

    const friendsListeners = useMemo(
        () =>
            (artistStatistics?.friendsPlays ?? [])
                .map(friendPlays => {
                    return {
                        userId: friendPlays.UserID,
                        itemType: 'Artist',
                        itemID: artistID,
                        itemNumOfPlays: friendPlays.Plays
                    } as FriendsListener;
                })
                .sort((f1, f2) => {
                    return f2.itemNumOfPlays - f1.itemNumOfPlays;
                }),
        [artistID, artistStatistics?.friendsPlays]
    );

    if (!artistID || (!artist.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    const onPlayArtist = async (playMode: 'random' | 'top-tracks') => {
        if (playMode === 'random') {
            const randomPlaylist = await buildPlaylistByItem('Artist', artistID);

            if (randomPlaylist.length > 0) {
                dispatch(playPlaylist(randomPlaylist));
            } else {
                dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: 'Artist' }));
            }
        } else {
            if (artistTopTracks.length > 0) {
                const topTracksPlaylist = await buildPlaylistByTrackIds(artistTopTracks.map(t => t.id));
                dispatch(
                    playPlaylist(
                        topTracksPlaylist.map(pt => {
                            return {
                                ...pt,
                                name: artistTopTracks.find(t => t.id === pt.id)?.name,
                                artists:
                                    pt.artists.length > 0
                                        ? pt.artists
                                        : [
                                              {
                                                  id: artistID,
                                                  name: artist.name
                                              }
                                          ]
                            } as TrackInfo;
                        })
                    )
                );
            } else {
                dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: 'Artist' }));
            }
        }
    };

    return (
        <AppMainContainer showPreloader={!artist.isInit}>
            <Section blurdedBackgroundImg={artist.coverImgPath}>
                <div className={classes.artistInfoContainer}>
                    <div>
                        <Link href={`/photos?type=artist&id=${artist.id}`}>
                            <Image
                                src={artist.coverImgPath}
                                srcFallback={DEFAULT_ARTIST_IMG}
                                viewWidth={250}
                                viewHeight={250}
                                viewMode="background-image"
                                imageClass={'circle l-shadow'}
                            ></Image>
                        </Link>
                    </div>
                    <div>
                        <div>
                            <div className={classes.artistTitle}>{artist.name}</div>
                            <div className="text-gray">{artist.country}</div>
                            <div className="mt-100 mb-100">
                                {artist.genres.map(artistGenre => (
                                    <Tag key={artistGenre} viewMode="default" text={artistGenre} href={`/genre?name=${artistGenre}`}></Tag>
                                ))}
                            </div>
                            {artistTopTracks.length > 0 ? (
                                <MenuButton
                                    buttonCustomClass={classes.artistPlayButton}
                                    buttonChildren={
                                        <>
                                            <i id="play_radio_icon" className="fa fa-play-circle fa-lg me-75"></i>
                                            Play Artist
                                        </>
                                    }
                                    menuSections={[
                                        {
                                            menuItems: [
                                                {
                                                    text: 'Play top tracks',
                                                    onClick: () => {
                                                        onPlayArtist('top-tracks');
                                                    }
                                                },
                                                {
                                                    text: 'Play random tracks',
                                                    onClick: () => {
                                                        onPlayArtist('random');
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                ></MenuButton>
                            ) : (
                                <button
                                    className={classes.artistPlayButton}
                                    onClick={() => {
                                        onPlayArtist('random');
                                    }}
                                >
                                    <i id="play_radio_icon" className="fa fa-play-circle fa-lg me-75"></i>
                                    Play Artist
                                </button>
                            )}
                        </div>
                        <div className={classes.musicStatistics}>
                            <MusicItemStatistics
                                numOfListeners={artistStatistics?.listeners ?? 0}
                                numOfPlays={artistStatistics?.plays ?? 0}
                                numOfMyPlays={artistStatistics?.myPlays ?? 0}
                                numOfMyFriendsPlays={artistStatistics?.friendsPlays ? Object.keys(artistStatistics.friendsPlays).length : 0}
                                onMyFriendsPlaysClick={() => {
                                    setIsFriendsListenersDialog(true);
                                }}
                                onMyPlaysClick={() => {
                                    setIsMyPlaysDialogVisible(true);
                                }}
                            ></MusicItemStatistics>
                        </div>
                    </div>
                </div>
                <MenuButton
                    buttonView="white-circle"
                    buttonSize="large"
                    containerCustomClass={classes.artistMenuBtn}
                    menuSections={[
                        {
                            menuItems: [
                                { text: 'Buy artist music on Amazon', href: artist.amazonBuyLink, hrefTarget: '_blank' },
                                {
                                    text: 'Rate artist',
                                    onClick: () => {
                                        setIsRateDialogVisible(true);
                                    }
                                },
                                {
                                    text: 'Recommend to friends',
                                    onClick: () => {
                                        setIsRecommendDialogVisible(true);
                                    }
                                },
                                {
                                    text: 'Improve metadata',
                                    onClick: () => {
                                        setIsImproveMetadataDialogVisible(true);
                                    }
                                }
                            ]
                        }
                    ]}
                ></MenuButton>
            </Section>

            {artistReleases && artistReleases.length > 0 && (
                <Section backgroundColor="white">
                    <Title>
                        <Link href={`/discography?aid=${artist.id}`} viewMode="See-More-Hover">
                            Discography
                        </Link>
                    </Title>
                    <SliderList>
                        {artistReleases.length &&
                            artistReleases.map(release => <ReleaseCard key={release.id} releaseInfo={release} cardSize="small"></ReleaseCard>)}
                    </SliderList>
                </Section>
            )}

            {artistTopTracks.length > 0 && (
                <Section backgroundColor="white">
                    <Title>
                        <Link href={`/artistcharts?type=track&rid=${artistID}`} viewMode="See-More-Hover">
                            Top Tracks
                        </Link>
                    </Title>
                    <ChartTable
                        chartItems={artistTopTracks.map(artistTrack => {
                            return {
                                type: 'Track',
                                id: artistTrack.id,
                                position: artistTrack.position,
                                score: artistTrack.score,
                                children: <Link href={`/track?tid=${artistTrack.id}`}>{artistTrack.name}</Link>
                            };
                        })}
                    ></ChartTable>
                </Section>
            )}

            {artistTopReleases.length > 0 && (
                <Section backgroundColor="white">
                    <Title>
                        <Link href={`/artistcharts?type=album&rid=${artistID}`} viewMode="See-More-Hover">
                            Top Albums
                        </Link>
                    </Title>
                    <ChartTable
                        chartItems={artistTopReleases.map(artistRelease => {
                            return {
                                type: 'Album',
                                id: artistRelease.id,
                                position: artistRelease.position,
                                score: artistRelease.score,
                                children: <Link href={`/album?aid=${artistRelease.id}`}>{artistRelease.name}</Link>
                            };
                        })}
                    ></ChartTable>
                </Section>
            )}

            <Section backgroundColor="white">
                <Title>Similar Artists</Title>
                <div>
                    {similarArtists?.length > 0 ? (
                        <SliderList>
                            {similarArtists.map(similarArtist => (
                                <Card
                                    key={similarArtist.id}
                                    imgOverlays={[
                                        {
                                            showMode: 'always',
                                            className: classes.similarArtistOverlay,
                                            href: `/artist?aid=${similarArtist.id}`,
                                            overlayChildren: <b>{similarArtist.name}</b>
                                        }
                                    ]}
                                    imgPath={similarArtist.coverImgPath}
                                ></Card>
                            ))}
                        </SliderList>
                    ) : (
                        <p>There are no similar artists yet.</p>
                    )}
                    {userSessionData.isReady && userSessionData.userInfo?.id && (
                        <>
                            <br />
                            <div className="text-dark-blue">
                                <Button
                                    viewMode="decorationLink"
                                    textColor="dark-blue"
                                    onClick={() => setIsAddSimilarArtistsDialogVisible(true)}
                                >
                                    + Add Similar Artists
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Section>

            <Section backgroundColor="white">
                <Title>Comments</Title>
                <div>
                    <UsersComments
                        itemType="Artist"
                        itemId={artistID}
                        comments={comments as CommentInfo[]}
                        activeUserInputComment={
                            userSessionData.userInfo
                                ? {
                                      userId: userSessionData.userInfo.id,
                                      userFirstName: userSessionData.userInfo.firstName,
                                      userLastName: userSessionData.userInfo.lastName,
                                      userImgPath: userSessionData.userInfo.imgPath ?? ''
                                  }
                                : undefined
                        }
                        onAddComment={commentInfo => {
                            setComments(prevComments => [...prevComments, commentInfo]);
                        }}
                        onDeleteComment={commentId => {
                            setComments(prevComments => {
                                return prevComments.filter(c => c.id.toString() !== commentId.toString());
                            });
                        }}
                    ></UsersComments>
                </div>
            </Section>

            <RateDialog
                itemType="Artist"
                itemId={artistID}
                isVisible={isRateDialogVisible}
                onCloseDialog={() => setIsRateDialogVisible(false)}
            ></RateDialog>

            <FriendsListenersDialog
                friendsListeners={friendsListeners}
                isVisible={isFriendsListenersDialog}
                onCloseDialog={() => {
                    setIsFriendsListenersDialog(false);
                }}
            ></FriendsListenersDialog>

            <MyPlaysDialog
                isVisible={isMyPlaysDialogVisible}
                itemType={'Artist'}
                itemID={artistID}
                onCloseDialog={() => {
                    setIsMyPlaysDialogVisible(false);
                }}
            ></MyPlaysDialog>

            <RecommendDialog
                itemType="Artist"
                itemId={artistID}
                isVisible={isRecommendDialogVisible}
                onCloseDialog={() => setIsRecommendDialogVisible(false)}
            ></RecommendDialog>

            <ImproveMetadataDialog
                itemType="Artist"
                itemId={artistID}
                artistOrigin={artist.country}
                isVisible={isImproveMetadataDialogVisible}
                onSave={(metdaDataType, data) => {
                    console.log(metdaDataType, data);
                }}
                onCloseDialog={() => setIsImproveMetadataDialogVisible(false)}
            ></ImproveMetadataDialog>

            <AddSimilarArtistsDialog
                artistID={artistID}
                isVisible={isAddSimilarArtistsDialogVisible}
                onCloseDialog={() => setIsAddSimilarArtistsDialogVisible(false)}
            ></AddSimilarArtistsDialog>
        </AppMainContainer>
    );
}

export default ArtistPage;
