import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, FetchPageData } from '../../Classes/Utilities';
import { ArtistInfo } from '../../Types/Artist';
import { ReleaseInfo } from '../../Types/ReleaseInfo';
import { Artist } from '../../Classes/Artist';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import ReleaseCard from '../../Components/ReleaseCard/ReleaseCard';

import classes from './Discography.module.css';

function Discography(props: any) {
    const { updatePageMetdata } = usePageMetdata();

    const [searchParams] = useSearchParams();
    const artistID = searchParams.get('aid');

    const isLoading = useRef<boolean>();

    const [artist, setArtist] = useState<Artist>(new Artist());
    const [artistReleases, setArtistReleases] = useState<ReleaseInfo[]>([]);

    useEffect(() => {
        if (!isLoading.current && artistID) {
            isLoading.current = true;

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/discography?id=${artistID}`).then((response: any) => {
                if (response.success) {
                    const artistInfo = response.result?.artist as ArtistInfo;

                    updatePageMetdata({
                        title: `${artistInfo.name} - Discography`,
                        description: `Explore and listen free to "${artistInfo.name}" complete discography`
                    });

                    setArtist(new Artist(artistInfo));
                    setArtistReleases(response.result?.releases as ReleaseInfo[]);
                } else {
                    setArtist(new Artist());
                }

                setTimeout(() => {
                    isLoading.current = false;
                }, 100);
            });
        }
    }, [artistID, updatePageMetdata]);

    if (!artistID || (!artist.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={!artist.isInit}>
            <Section>
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[{ text: artist.name, href: `/artist?aid=${artist.id}` }, { text: 'Discography' }]}
                    ></Breadcrumbs>
                </Title>
                <ul className={classes.newReleasesGrid}>
                    {artistReleases.map(release => (
                        <li key={release.id}>
                            <ReleaseCard releaseInfo={release}></ReleaseCard>
                        </li>
                    ))}
                </ul>
            </Section>
        </AppMainContainer>
    );
}

export default Discography;
