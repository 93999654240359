import React, { useEffect, useRef, useState } from 'react';

import Search from '../Search/Search';

import classes from './MultiSearchSelection.module.css';

export type SearchItemInfo = {
    id: string;
    name: string;
};

export type MultiSearchSelectionProps = {
    placeholder?: string;
    width?: string;
    reset?: boolean;
    searchItems?: SearchItemInfo[];
    selectedItems?: SearchItemInfo[];
    onSearch?: (searchText: string) => Promise<SearchItemInfo[]>;
    onSelectionChange: (selectedSearchItems: SearchItemInfo[]) => void;
};

export function MultiSearchSelection(props: MultiSearchSelectionProps) {
    const { placeholder, width, reset, searchItems, selectedItems, onSelectionChange } = props;

    const searchText = useRef('');
    const searchItemsRef = useRef(searchItems);

    const [searchResults, setSearchResults] = useState<SearchItemInfo[]>(searchItems ?? []);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const onSearch = (serachText: string) => {
        return new Promise<any>(async resolve => {
            if (props.onSearch) {
                if (serachText.trim().length > 0) {
                    const results = await props.onSearch(serachText);
                    resolve(results);
                }
            } else if (searchItemsRef.current?.length) {
                resolve(searchItemsRef.current.filter(s => s.name.toLocaleLowerCase().includes(serachText)));
            }
        });
    };

    const onClickSearchResult = (searchItemInfo: SearchItemInfo, selected: boolean) => {
        if (selected) {
            onSelectionChange([...(selectedItems ?? []), searchItemInfo]);
        } else {
            onSelectionChange(selectedItems?.filter(s => s.id !== searchItemInfo.id) ?? []);
        }
    };

    useEffect(() => {
        searchItemsRef.current = searchItems;
        setSearchResults(searchItems ?? []);
    }, [searchItems]);

    useEffect(() => {
        if (reset) {
            setSearchResults([]);
            onSelectionChange([]);
            searchText.current = '';
        }
    }, [reset, onSelectionChange]);

    const serachingStatus = searchResults.length > 0 || searchText.current.length <= 0 ? '' : isSearching ? 'Searching...' : 'No results';

    return (
        <>
            {Boolean(selectedItems?.length) && (
                <div className={`${classes.container} mb-50`}>
                    <div className={classes.selectedItems}>
                        {selectedItems?.map((selectedItem, index) => (
                            <span key={index} className={classes.selectedItem}>
                                {selectedItem.name}
                                <button
                                    onClick={() => {
                                        //setSelectedResults(prev => prev.filter(s => s.id !== selectedItem.id));
                                        onSelectionChange(selectedItems?.filter(s => s.id !== selectedItem.id) ?? []);
                                    }}
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </span>
                        ))}
                    </div>
                </div>
            )}
            <div className={classes.container}>
                <Search
                    viewMode="noborder"
                    width={width}
                    placeholder={placeholder}
                    reset={reset}
                    onSearch={onSearch}
                    onSearching={status => {
                        setIsSearching(status);
                    }}
                    onSearchResults={(searchTerm, results) => {
                        searchText.current = searchTerm;
                        setSearchResults(results.length ? results : searchItemsRef.current ?? []);
                    }}
                ></Search>
                {(serachingStatus || searchResults.length > 0) && (
                    <ul className={classes.searchResults}>
                        {serachingStatus && <li className={classes.searchingMode}>{serachingStatus}</li>}
                        {searchResults.map((searchResult, index) => {
                            const isSelected = Boolean(selectedItems?.filter(r => r.id === searchResult.id)?.length);

                            return (
                                <li
                                    key={index}
                                    className={classes.searchResult}
                                    aria-selected={isSelected}
                                    role="option"
                                    onClick={() => {
                                        onClickSearchResult(searchResult, !isSelected);
                                    }}
                                >
                                    {searchResult.name}
                                    <i
                                        className={`${classes.checkbox} fa-lg ${isSelected ? 'fa-solid fa-square-check' : 'fa-regular fa-square'}`}
                                    ></i>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </>
    );
}
