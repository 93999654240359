import React from 'react';

import Image from '../Image/Image';
import MenuButton from '../MenuButton/MenuButton';

import classes from './Photo.module.css';

export type ImageSize = {
    width: number;
    height: number;
};

export type PhotoProps = {
    id?: string;
    src: string;
    alt: string;
    width: number;
    height: number;
    showMenuEditButton?: boolean;
    viewSize?: 'small' | 'medium' | 'large';
    onPhotoClick?: () => void;
    OnDelete?: () => void;
    OnSetAsProfileImage?: () => void;
};

function Photo(props: PhotoProps) {
    const { src, alt, width, height, showMenuEditButton, onPhotoClick, OnDelete, OnSetAsProfileImage } = props;

    const viewSize = props?.viewSize === 'small' ? 200 : props?.viewSize === 'medium' ? 250 : 300;
    const imgViewSizeX = Math.min((viewSize / height) * width);
    const imgViewSizeY = viewSize;

    return (
        <div className={classes.imgContainer} style={{ width: imgViewSizeX, height: imgViewSizeY }}>
            <Image
                src={src}
                alt={alt}
                width={width}
                height={height}
                viewWidth={imgViewSizeX}
                viewHeight={imgViewSizeY}
                onImageClick={onPhotoClick}
            ></Image>
            {showMenuEditButton && (
                <MenuButton
                    containerCustomClass={classes.imgEditBtnContainer}
                    buttonCustomClass={classes.imgEditBtn}
                    buttonChildren={<i className="fa fa-pen fa-lg"></i>}
                    menuSections={[
                        {
                            menuItems: [
                                {
                                    text: 'Set as profile photo',
                                    iconName: 'fa-thumbtack',
                                    onClick: () => {
                                        OnSetAsProfileImage?.();
                                    }
                                },
                                {
                                    text: 'Delete photo',
                                    iconName: 'fa-trash',
                                    onClick: () => {
                                        OnDelete?.();
                                    }
                                }
                            ]
                        }
                    ]}
                ></MenuButton>
            )}
        </div>
    );
}

export default Photo;
