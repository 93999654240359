import React from 'react';

import { DEFAULT_ARTIST_IMG } from '../../Classes/Utilities';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Image from '../../Components/Image/Image';
import Link from '../../Components/Link/Link';

import classes from './VariousArtists.module.css';

function VariousArtistsPage(props: any) {
    usePageMetdata({ title: 'Various Artists' });

    return (
        <AppMainContainer>
            <Section blurdedBackgroundImg={DEFAULT_ARTIST_IMG}>
                <div className={classes.variousArtistsInfoContainer}>
                    <div>
                        <Image
                            src={DEFAULT_ARTIST_IMG}
                            viewWidth={250}
                            viewHeight={250}
                            viewMode="background-image"
                            imageClass={'circle l-shadow'}
                        ></Image>
                    </div>
                    <div>
                        <div>
                            <div className={classes.variousArtistsTitle}>Various Artists</div>
                            <div className={classes.variousArtistsWiki}>
                                Various Artists often abbreviated as VA or V/A is a term used by the music industry for releases (singles or
                                albums) that contain collaborations. Rather than listing the many individual singers or artists being credited
                                individually, the name Various Artists is used instead. Aggregated compilation albums containing tracks from a
                                number of artists can also be credited to Various Artists. Various Artists compilation albums are often compiled
                                from a particular musical style or period to another. There are also charity records released that may use the
                                term rather than mentioning specific artists, e.g. USA for Africa or Band Aid. Another use for Various Artists is
                                a specific artist "and Various Artists" or "featuring Various Artists". This is most common in remixes where
                                there are many releases using the formula, such as "Rihanna featuring Various Artists" for remixes of "What's My
                                Name?". Sometimes the term "and Friends" is used instead, as for example the song "That's What Friends Are For"
                                credited to "Dionne Warwick & Friends" (rather than Various Artists).
                                <div className="mt-100">
                                    <Link href={'https://en.wikipedia.org/wiki/Various_Artists'} textColor="blue" target="_blank">
                                        &raquo; Taken from Wikipedia
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </AppMainContainer>
    );
}

export default VariousArtistsPage;
