import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playPlaylist } from '../../redux/musicPlayerSlice';
import { setActiveDialog } from '../../redux/uiSlice';

import { buildPlaylistBy } from '../../Services/Playlists';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Slider from '../../Components/Slider/Slider';

import TimeFilterDialog from './partials/TimeFilterDialog';
import CountriesFilterDialog from './partials/CountriesFilterDialog';
import GenresFilterDialog from './partials/GenresFilterDialog';

import classes from './Radio.module.css';

function RadioPage(props: any) {
    usePageMetdata({ title: 'Radio' });

    const dispatch = useDispatch<AppDispatch>();

    const [isCountriesFilterDialogVisible, setIsCountriesFilterDialogVisible] = useState(false);
    const [countriesFilter, setCountriesFilter] = useState<string[]>([]);

    const [isTimeFilterDialogVisible, setIsTimeFilterDialogVisible] = useState(false);
    const [timeFilter, setTimeFilter] = useState('All Times');
    const timeFilterMinYear = useRef<number | undefined>();
    const timeFilterMaxYear = useRef<number | undefined>();

    const [isGenresFilterDialogVisible, setIsGenresFilterDialogVisible] = useState(false);
    const [genresFilter, setGenresFilter] = useState<string[]>([]);

    const popularityLevel = useRef(50);
    const popularityLabelElementRef = useRef(null);
    const [popularityLabel, setPopularityLabel] = useState('Hits');

    const onPlayRadio = async () => {
        const playlist = await buildPlaylistBy(
            genresFilter,
            timeFilterMinYear.current,
            timeFilterMaxYear.current,
            popularityLevel.current,
            //TODO: allow multiple countries in API
            countriesFilter.length > 0 ? countriesFilter[0] : undefined
        );

        if (playlist.length > 0) {
            dispatch(playPlaylist(playlist));
        } else {
            dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: 'Playlist' }));
        }
    };

    return (
        <AppMainContainer backgroundImage="/Images/music_library.jpg">
            <div className={classes.radioContainer}>
                <button className={classes.playRadioButton} onClick={onPlayRadio}>
                    <i className="fa fa-play-circle"></i>
                </button>

                <div className={classes.radioFilters}>
                    <div ref={popularityLabelElementRef} className="mb-100">
                        {popularityLabel}
                    </div>
                    <Slider
                        curVal={popularityLevel.current}
                        sliderHeight={12}
                        sliderHandlerShape={'circle'}
                        sliderProgressBarColor="rgb(217, 223, 224)"
                        sliderCustomCSS={{ border: '1px solid #aaa', borderRadius: 0, background: 'none', marginBottom: '.75rem' }}
                        onChange={value => {
                            popularityLevel.current = value;

                            if (value < 25) {
                                setPopularityLabel('All Tracks');
                            } else if (value < 50) {
                                setPopularityLabel('Popular Tracks');
                            } else if (value < 75) {
                                setPopularityLabel('Hits');
                            } else {
                                setPopularityLabel('Top Hits');
                            }
                        }}
                    ></Slider>
                    <button
                        onClick={() => {
                            setIsCountriesFilterDialogVisible(true);
                        }}
                    >
                        {countriesFilter.length <= 0 ? 'All Countries' : countriesFilter.join(', ')}
                    </button>
                    <button
                        onClick={() => {
                            setIsTimeFilterDialogVisible(true);
                        }}
                    >
                        {timeFilter}
                    </button>
                    <button
                        onClick={() => {
                            setIsGenresFilterDialogVisible(true);
                        }}
                    >
                        {genresFilter.length <= 0 ? 'All Genres' : genresFilter.join(', ')}
                    </button>
                </div>
            </div>
            <CountriesFilterDialog
                isVisible={isCountriesFilterDialogVisible}
                selectedCountries={countriesFilter}
                onFilterValueChanged={(value: string[]) => {
                    setCountriesFilter(value);
                }}
                onCloseDialog={() => {
                    setIsCountriesFilterDialogVisible(false);
                }}
            ></CountriesFilterDialog>
            <TimeFilterDialog
                isVisible={isTimeFilterDialogVisible}
                selectedTimeFilter={timeFilter}
                onFilterValueChanged={(value: string, minYear?: number, maxYear?: number) => {
                    timeFilterMinYear.current = minYear;
                    timeFilterMaxYear.current = maxYear;
                    setTimeFilter(value !== 'Custom' ? value : `${minYear} - ${maxYear}`);
                }}
                onCloseDialog={() => {
                    setIsTimeFilterDialogVisible(false);
                }}
            ></TimeFilterDialog>
            <GenresFilterDialog
                isVisible={isGenresFilterDialogVisible}
                selectedGenres={genresFilter}
                onFilterValueChanged={(value: string[]) => {
                    setGenresFilter(value);
                }}
                onCloseDialog={() => {
                    setIsGenresFilterDialogVisible(false);
                }}
            ></GenresFilterDialog>
        </AppMainContainer>
    );
}

export default RadioPage;
