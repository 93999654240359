import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../Image/Image';

import classes from './GenericList.module.css';

export type GenericListItem = {
    titleStr: string;
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    subInfo?: React.ReactNode;
    image?: string;
    imageShape?: 'circle' | 'square';
    imageHref?: string;
    href?: string;
    isSelectedItem?: boolean;
    startSection?: React.ReactNode;
    endSection?: React.ReactNode;
    onItemClick?: () => void;
};

export type GenericListProps = {
    items: GenericListItem[];
    viewSize?: 'small' | 'medium' | 'large';
    spaceBetweenItems?: boolean;
    listCustomCSS?: React.CSSProperties;
};

function GenericList(props: GenericListProps) {
    const { items, viewSize, spaceBetweenItems, listCustomCSS } = props;

    const imageClass = viewSize === 'large' ? classes.largeImage : viewSize === 'medium' ? classes.mediumImage : classes.smallImage;
    const fontSizeClass = viewSize === 'large' ? 'fs-110' : viewSize === 'medium' ? 'fs-105' : '';
    const useItemSpacer = spaceBetweenItems !== false;

    const innerItemContent = (item: GenericListItem) => {
        const imageShapeClass = item.imageShape === 'circle' ? 'circle' : '';

        return (
            <div
                className={`${classes.itemContent} ${fontSizeClass} ${item.isSelectedItem ? classes.selectedItem : ''} ${useItemSpacer ? classes.itemSpacer : ''}`}
            >
                <div className={item.startSection ? 'me-100' : ''}>{item.startSection}</div>
                <div>
                    {item.image && (
                        <div className="me-100">
                            {item.imageHref ? (
                                <Link to={item.imageHref} aria-label={item.titleStr}>
                                    <Image
                                        src={item.image ?? ''}
                                        viewMode="background-image"
                                        imageClass={`${imageShapeClass} ${imageClass}`}
                                    ></Image>
                                </Link>
                            ) : (
                                <Image
                                    src={item.image ?? ''}
                                    alt={item.titleStr}
                                    viewMode="background-image"
                                    imageClass={`${imageShapeClass} ${imageClass}`}
                                ></Image>
                            )}
                        </div>
                    )}
                </div>
                <div className={classes.itemInnerContent}>
                    <div className="fw-500">{item.title}</div>
                    {item.subTitle && <div>{item.subTitle}</div>}
                    {item.subInfo && <sub className="mt-15 text-gray">{item.subInfo}</sub>}
                </div>
                <div className={item.endSection ? 'ms-100' : ''}>{item.endSection}</div>
            </div>
        );
    };

    return (
        <ul className={classes.listContainer} style={listCustomCSS}>
            {items.map((item, index) => (
                <li key={index}>
                    {item.href ? (
                        <Link
                            to={item.href}
                            onClick={() => {
                                item?.onItemClick?.();
                            }}
                        >
                            {innerItemContent(item)}
                        </Link>
                    ) : (
                        <div>{innerItemContent(item)}</div>
                    )}
                </li>
            ))}
        </ul>
    );
}

export default GenericList;
