import React, { useEffect, useRef } from 'react';

import { disableScrollBar, enableScrollBar, isClickInsideElement } from '../../Classes/Utilities';
import useDeviceData from '../../hooks/useDeviceData';

import classes from './Dialog.module.css';

export type DialogProps = {
    //type: 'modal' | '';
    isVisible: boolean;
    title: string;
    children: React.ReactNode;
    onCloseDialog: () => void;
};

function Dialog(props: DialogProps) {
    const { isVisible, title, children, onCloseDialog } = props;
    const { isMobile, isMobileView } = useDeviceData();

    const dialogElementRef = useRef<HTMLDialogElement | null>(null);

    useEffect(() => {
        const dialogElement = dialogElementRef.current;

        if (dialogElement) {
            const onDialogClose = () => {
                onCloseDialog();
                enableScrollBar();
            };

            const onDialogClick = (event: MouseEvent) => {
                const dialogDimensions = dialogElement.getBoundingClientRect();
                if (!isClickInsideElement(event, dialogDimensions)) {
                    dialogElement.close();
                }
            };

            dialogElement.addEventListener('close', onDialogClose);
            dialogElement.addEventListener('click', onDialogClick);

            return () => {
                dialogElement.removeEventListener('close', onDialogClose);
                dialogElement.removeEventListener('click', onDialogClick);
            };
        }
    }, [onCloseDialog]);

    useEffect(() => {
        const dialogElement = dialogElementRef.current;

        if (dialogElement) {
            if (isVisible) {
                dialogElement.showModal();
                disableScrollBar();
            } else {
                dialogElement.close();
            }
        }
    }, [isVisible]);

    return (
        <dialog className={`${classes.dialog} ${isMobile || isMobileView ? classes.dialogMobile : classes.dialogPC}`} ref={dialogElementRef}>
            <div className={classes.dialogHeader}>
                <div>{title}</div>
                <button className={classes.closeDialog} title="Close" onClick={() => dialogElementRef.current?.close()}></button>
            </div>
            <div className={classes.dialogContent}>{children}</div>
        </dialog>
    );
}

export default Dialog;
