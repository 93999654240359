import React, { useEffect, useRef, useState } from 'react';
import SimpleSelectionList from '../SelectionList/SimpleSelectionList';

export type CountriesSelectionProps = {
    selectedCountries?: string[];
    selectionMode?: 'Single-Selection' | 'Multi-Selection';
    onSelectionChange: (newSelectedOptions: string[]) => void;
};

function CountriesSelection(props: CountriesSelectionProps) {
    const { selectionMode = 'Single-Selection', selectedCountries, onSelectionChange } = props;

    const selectElementRef = useRef(null);
    const [countries, setCountries] = useState<string[]>([]);

    const onSelectChange = () => {
        const selectElement = selectElementRef?.current ? (selectElementRef.current as HTMLSelectElement) : null;

        if (selectElement?.value !== selectedCountries) {
            onSelectionChange(selectElement?.value ? [selectElement.value] : []);
        }
    };

    useEffect(() => {
        if ((window as any).countries_list) {
            setCountries((window as any).countries_list.filter((g: string) => g));
        } else {
            fetch('/countries.json')
                .then(response => response.json())
                .then(response => {
                    if (response) {
                        (window as any).countries_list = response;
                        setCountries((window as any).countries_list.filter((g: string) => g));
                    }
                });
        }
    }, []);

    return selectionMode === 'Single-Selection' ? (
        <select ref={selectElementRef} onChange={onSelectChange} value={selectedCountries?.[0]}>
            {[''].concat(countries).map(country => (
                <option key={country}>{country}</option>
            ))}
        </select>
    ) : (
        <SimpleSelectionList
            selectionMode="Multi-Selection"
            showPreloader={countries.length === 0}
            showOptionsSearch={true}
            selectionOptions={countries}
            selectedOptions={selectedCountries}
            onSelectionChange={val => {
                onSelectionChange(val);
            }}
        ></SimpleSelectionList>
    );
}

export default CountriesSelection;
