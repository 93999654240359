import React, { useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';

import { signupUser } from '../../Services/User';
import { Validate } from '../../Classes/Utilities';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Button from '../../Components/Button/Button';
import Link from '../../Components/Link/Link';
import Logo from '../../Components/Logo/Logo';
import Recaptcha from '../../Components/Recaptcha/Recaptcha';

import classes from './SignUp.module.css';

function SignUpPage(props: any) {
    usePageMetdata({ title: 'Sign Up' });

    const firstNameInputElementRef = useRef(null);
    const lastNameInputElementRef = useRef(null);
    const emailInputElementRef = useRef(null);
    const passwordInputElementRef = useRef(null);
    const errorDivElementRef = useRef(null);

    const { userSessionData, updateUserSessionData } = useUserSessionData();
    const [isRegisteredUser, setIsRegisteredUser] = useState(false);

    const onSignUpClick = async (event: any) => {
        event.preventDefault();

        const firstNameInputElement = firstNameInputElementRef?.current ? (firstNameInputElementRef.current as HTMLInputElement) : null;
        const lastNameInputElement = lastNameInputElementRef?.current ? (lastNameInputElementRef.current as HTMLInputElement) : null;
        const emailInputElement = emailInputElementRef?.current ? (emailInputElementRef.current as HTMLInputElement) : null;
        const passwordInputElement = passwordInputElementRef?.current ? (passwordInputElementRef.current as HTMLInputElement) : null;
        const errorDivElement = errorDivElementRef?.current ? (errorDivElementRef.current as HTMLElement) : null;

        const recaptchaToken = (window as any).grecaptcha?.getResponse();

        if (errorDivElement) {
            // Reset and hide error div
            errorDivElement.innerHTML = '';
            errorDivElement.classList.add('hidden');
        }

        if (
            recaptchaToken &&
            firstNameInputElement?.value?.trim()?.length &&
            lastNameInputElement?.value?.trim()?.length &&
            emailInputElement?.value?.trim()?.length &&
            passwordInputElement?.value?.trim()?.length &&
            errorDivElement
        ) {
            if (!Validate.Email(emailInputElement.value)) {
                errorDivElement.innerHTML = 'Invalid email!';
                errorDivElement.classList.remove('hidden');
            } else if (!Validate.Password(passwordInputElement.value)) {
                errorDivElement.innerHTML = `Invalid password! Password must be at least ${Validate.MIN_PASSWORD_LENGTH} characters long.`;
                errorDivElement.classList.remove('hidden');
            } else {
                const newUserSessionData = await signupUser(
                    firstNameInputElement.value,
                    lastNameInputElement.value,
                    emailInputElement.value,
                    passwordInputElement.value,
                    recaptchaToken
                );

                if (newUserSessionData) {
                    updateUserSessionData(newUserSessionData);
                    setIsRegisteredUser(true);
                }
            }
        } else {
            if (!firstNameInputElement?.value?.trim()?.length) {
                firstNameInputElement?.focus();
            } else if (!lastNameInputElement?.value?.trim()?.length) {
                lastNameInputElement?.focus();
            } else if (!emailInputElement?.value?.trim()?.length) {
                emailInputElement?.focus();
            } else if (!passwordInputElement?.value?.trim()?.length) {
                passwordInputElement?.focus();
            } else if (!recaptchaToken && errorDivElement) {
                errorDivElement.innerHTML = 'Invalid recaptcha!';
                errorDivElement.classList.remove('hidden');
            }
        }
    };

    if (isRegisteredUser || (userSessionData.isReady && userSessionData.sessionId)) {
        return <Navigate to="/home" />;
    }

    return (
        <AppMainContainer>
            <div className={classes.pageBackground} style={{ backgroundImage: `url('Images/people.jpg')` }}>
                <div className={classes.signupArea}>
                    <form autoComplete="off" onSubmit={onSignUpClick}>
                        <Logo href="/home" text="Bellino" viewSize="large"></Logo>
                        <input ref={firstNameInputElementRef} type="text" placeholder="First Name" autoComplete="off" maxLength={50} />
                        <input ref={lastNameInputElementRef} type="text" placeholder="Last Name" autoComplete="off" maxLength={50} />
                        <input ref={emailInputElementRef} type="email" placeholder="Email" autoComplete="off" maxLength={254} />
                        <input
                            ref={passwordInputElementRef}
                            type="password"
                            placeholder="Password"
                            autoComplete="new-password"
                            minLength={Validate.MIN_PASSWORD_LENGTH}
                            maxLength={Validate.MAX_PASSWORD_LENGTH}
                        />
                        <div ref={errorDivElementRef} className={`hidden ${classes.errorMessage}`}></div>
                        <Recaptcha className="mt-100"></Recaptcha>
                        <Button viewMode="fancy" onClick={onSignUpClick}>
                            Sign up
                        </Button>
                        <Link href="/">
                            <b>Sign In</b> with an existing account
                        </Link>
                    </form>
                </div>
            </div>
        </AppMainContainer>
    );
}

export default SignUpPage;
