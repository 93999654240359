import React from 'react';

import Dialog from '../../Containers/Dialog/Dialog';
import Link from '../../Components/Link/Link';

import classes from './MyPlaysDialog.module.css';

export type MyPlaysDialogProps = {
    isVisible: boolean;
    itemType: 'Artist' | 'Album' | 'Track';
    itemID: string;
    onCloseDialog: () => void;
};

function MyPlaysDialog(props: MyPlaysDialogProps) {
    const { isVisible, itemType, itemID, onCloseDialog } = props;

    return (
        <Dialog isVisible={isVisible} title={'View My Plays'} onCloseDialog={onCloseDialog}>
            <div className={classes.content}>
                <Link viewMode={'Button'} bgColor="blue" href={`/listenings?type=${itemType.toLocaleLowerCase()}&itemID=${itemID}`}>
                    View as Plays
                </Link>
                <Link
                    viewMode={'Button'}
                    bgColor="green"
                    href={`/usercharts?type=track&itemType=${itemType.toLocaleLowerCase()}&itemID=${itemID}`}
                >
                    View as Chart
                </Link>
            </div>
        </Dialog>
    );
}

export default MyPlaysDialog;
