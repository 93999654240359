import React, { useCallback, useEffect, useRef } from 'react';
import classes from './Search.module.css';

export type SearchViewMode = 'classic' | 'rounded' | 'noborder';

export type SearchProps = {
    searchTerm?: string;
    viewMode?: SearchViewMode;
    isDisabled?: boolean;
    placeholder?: string;
    width?: string;
    reset?: boolean;
    allowEmptySearchTerm?: boolean;
    onReady?: (refInputElement?: HTMLInputElement, refContainerElement?: HTMLElement) => void;
    onClick?: (event: any) => void;
    onSearch: (query: string) => Promise<any[]>;
    onSearching?: (status: boolean) => void;
    onSearchResults: (searchTerm: string, results: any[]) => void;
};

function Search(props: SearchProps) {
    const {
        placeholder = '',
        searchTerm = '',
        isDisabled = false,
        reset = false,
        allowEmptySearchTerm = false,
        width,
        onReady,
        onClick,
        onSearch,
        onSearching,
        onSearchResults
    } = props;
    const viewMode = props.viewMode ?? 'classic';
    let viewModeClassName = classes[`${viewMode}ViewMode`];

    const isReady = useRef(false);
    const inputElementRef = useRef<HTMLInputElement>(null);
    const debounceTimeout = useRef<NodeJS.Timeout>();
    const controllerRef = useRef<AbortController | null>(null);
    const latestSearchTerm = useRef(searchTerm);

    const onSearchInputChange = useCallback(
        (event: any) => {
            const userInput = event?.target?.value ?? '';
            latestSearchTerm.current = userInput;

            onSearchResults(userInput, []);

            if (!allowEmptySearchTerm && userInput.trim() === '') {
                return;
            }

            onSearching?.(true);

            if (controllerRef.current) {
                controllerRef.current.abort(); // Cancel previous request if input changes
            }

            debounceTimeout.current && clearTimeout(debounceTimeout.current);
            debounceTimeout.current = setTimeout(async () => {
                const controller = new AbortController();
                controllerRef.current = controller;

                try {
                    //console.log('Search seraching = ', userInput);

                    const searchResults = await onSearch(userInput);

                    if (userInput === latestSearchTerm.current && !controller.signal.aborted) {
                        onSearchResults(userInput, searchResults);
                        onSearching?.(false);
                        controllerRef.current = null;
                    }
                } catch (error: any) {
                    if (error?.name !== 'AbortError') {
                        console.error('Fetch error:', error);
                    }

                    onSearching?.(false);
                    controllerRef.current = null;
                }
            }, 500);
        },
        [allowEmptySearchTerm, onSearch, onSearching, onSearchResults]
    );

    useEffect(() => {
        const inputElement = inputElementRef?.current ? (inputElementRef.current as HTMLInputElement) : null;

        if (inputElement && !isReady.current) {
            inputElement.removeEventListener('input', onSearchInputChange);
            inputElement.addEventListener('input', onSearchInputChange);

            inputElement.removeEventListener('propertychange', onSearchInputChange);
            inputElement.addEventListener('propertychange', onSearchInputChange);

            onClick && inputElement.removeEventListener('click', onClick);
            onClick && inputElement.addEventListener('click', onClick);

            inputElement.value = searchTerm;
            inputElement.parentElement && onReady?.(inputElement, inputElement.parentElement);

            isReady.current = true;
        }

        /*   return () => {
            if (inputElement && isReady.current) {
                //console.log('Search unmount');

                inputElement.removeEventListener('input', onSearchInputChange);
                inputElement.removeEventListener('propertychange', onSearchInputChange);

                onReady?.(undefined);
                onClick && inputElement.removeEventListener('click', onClick);

                isReady.current = false;
            }
        };*/
    }, [searchTerm, onReady, onClick, onSearchInputChange]);

    useEffect(() => {
        if (reset && inputElementRef?.current) {
            inputElementRef.current.value = '';
            onSearchResults?.('', []);
        }
    }, [reset, onSearchResults]);

    return (
        <div
            className={`${classes.searchContainer} ${viewModeClassName}`}
            style={{ width: width }}
            onClick={() => {
                const inputElement = inputElementRef?.current ? (inputElementRef.current as HTMLInputElement) : null;
                inputElement?.focus?.();
            }}
        >
            {<i className="fa-solid fa-magnifying-glass"></i>}
            <input ref={inputElementRef} type="text" placeholder={placeholder} disabled={isDisabled} autoComplete="off" />
        </div>
    );
}

export default Search;
