import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PlayerStateMode } from '../../Types/Player';

export type YouTubePlayerProps = {
    videoId: string;
    height?: number;
    width?: number;
    volume?: number;
    showControlsButtons?: boolean;
    forceReload?: boolean;
    onReady?: (player: any) => void;
    onError?: (event: any) => void;
    onStateChange?: (state: PlayerStateMode) => void;
};

function YouTubePlayer(props: YouTubePlayerProps) {
    const { videoId, height = 200, width = 320, showControlsButtons, forceReload = false, onReady, onError, onStateChange } = props;

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [youtubePlayer, setYoutubePlayer] = useState<any>(null);
    const currentPlayingVideoId = useRef<string>();

    const onPlayerReady = useCallback(
        (event: any) => {
            var player = event.target;

            if (player) {
                onReady?.(player);
                setIsReady(true);
            }
        },
        [onReady]
    );

    useEffect(() => {
        if (!isScriptLoaded && !youtubePlayer) {
            // Init Youtube API (make it first script in list)
            const youtubeScript = document.createElement('script');
            youtubeScript.src = 'https://www.youtube.com/iframe_api';

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag?.parentNode?.insertBefore(youtubeScript, firstScriptTag);

            /* On YouTube Iframe API Ready Event. */
            (window as any).onYouTubeIframeAPIReady = () => {
                setIsScriptLoaded(true);
            };
        }
    }, [isScriptLoaded, youtubePlayer]);

    useEffect(() => {
        if (isScriptLoaded && !youtubePlayer) {
            const windowYT = (window as any).YT;
            const player = new windowYT.Player('youtube_player', {
                height,
                width,
                videoId,
                playerVars: {
                    html5: 1,
                    iv_load_policy: 3,
                    autoplay: 0,
                    rel: 0,
                    playsinline: 1,
                    controls: showControlsButtons ? 1 : 0,
                    wmode: 'opaque'
                },
                events: {
                    onReady: onPlayerReady,
                    onStateChange: (event: any) => {
                        if (onStateChange) {
                            if (event.data === windowYT.PlayerState.ENDED) {
                                onStateChange('ended');
                            } else if (event.data === windowYT.PlayerState.PLAYING) {
                                onStateChange('playing');
                            } else if (event.data === windowYT.PlayerState.PAUSED) {
                                onStateChange('paused');
                            } else if (event.data === windowYT.PlayerState.BUFFERING) {
                                onStateChange('buffering');
                            }
                        }
                    },
                    onError: (event: any) => {
                        console.error('Youtube onError', event);

                        if (event.data >= 100) {
                            onError?.(event);
                        }

                        if (onStateChange) {
                            onStateChange('ended');
                        }
                    }
                }
            });
            setYoutubePlayer(player);
        }
    }, [isScriptLoaded, height, width, videoId, showControlsButtons, youtubePlayer, onError, onPlayerReady, onStateChange]);

    useEffect(() => {
        if (youtubePlayer && isReady && (currentPlayingVideoId.current !== videoId || forceReload)) {
            if (videoId) {
                youtubePlayer.loadVideoById(videoId);
                youtubePlayer.playVideo();
                currentPlayingVideoId.current = videoId;
            } else {
                youtubePlayer.stopVideo();
            }
        }
    }, [isReady, videoId, youtubePlayer, forceReload]);

    return <div id="youtube_player"></div>;
}

export default YouTubePlayer;
