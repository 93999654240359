import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, capitalizeFirstLetter, FetchPageData } from '../../Classes/Utilities';
import { User } from '../../Classes/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Breadcrumbs, { BreadcrumbsItem } from '../../Components/Breadcrumbs/Breadcrumbs';
import ChartTable, { ChartItem } from '../../Components/Charts/ChartTable/ChartTable';
import Link from '../../Components/Link/Link';
import ArtistLink from '../../Components/ArtistLink/ArtistLink';
import Preloader from '../../Components/Preloader/Preloader';
import Tabs, { TabItem } from '../../Components/Tabs/Tabs';

enum ChartTimeFilter {
    Overall = 'Overall',
    LastYear = 'LastYear',
    LastMonth = 'LastMonth',
    LastWeek = 'LastWeek'
}

function UserCharts(props: any) {
    const { updatePageMetdata } = usePageMetdata();
    const { userSessionData } = useUserSessionData();
    const [searchParams] = useSearchParams();

    const userID = searchParams.get('uid') ?? userSessionData.userInfo?.id;
    const chartType = searchParams.get('type')?.toLocaleLowerCase();
    const itemID = searchParams.get('itemID');
    const itemType = searchParams.get('itemType')?.toLocaleLowerCase();

    const [user, setUser] = useState<User>(new User());
    const [chartItems, setChartItems] = useState<ChartItem[]>([]);
    const [chartItemName, setChartItemName] = useState('');
    const [chartTimeFilter, setChartTimeFilter] = useState('Overall');

    const isLoadingRef = useRef<boolean>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isLoadingRef.current && userID) {
            isLoadingRef.current = true;
            setIsLoading(true);
            setChartItems([]);

            const maxDays =
                chartTimeFilter === ChartTimeFilter.LastYear
                    ? 365
                    : chartTimeFilter === ChartTimeFilter.LastMonth
                      ? 30
                      : chartTimeFilter === ChartTimeFilter.LastWeek
                        ? 7
                        : -1;

            FetchPageData(
                `${BELLINO_BASE_URL}/api/pages/usercharts?id=${userID}&type=${chartType}&itemID=${itemID}&itemType=${itemType}&maxDyas=${maxDays}`
            ).then((response: any) => {
                let user = new User();

                if (response.success) {
                    user = new User(response.result?.userInfo);

                    updatePageMetdata({
                        title: `${user.fullName} - Charts`,
                        description: `View ${user.fullName}'s music profile on Bellino. Join Bellino to know music lovers like ${user.fullName} from all over the world`
                    });

                    setUser(user);
                    setChartItemName(response.result?.chartItemName ?? 0);
                    setChartItems(
                        response.result?.chartItems?.map((chartItem: any, index: number) => {
                            return {
                                type: capitalizeFirstLetter(chartType ?? ''),
                                id: chartItem.id,
                                position: index + 1,
                                score: chartItem.score,
                                children:
                                    chartType === 'artist' ? (
                                        <Link key={chartItem.id} href={`/artist?aid=${chartItem.id}`}>
                                            {chartItem.name}
                                        </Link>
                                    ) : chartType === 'album' ? (
                                        <span key={chartItem.id}>
                                            {itemType !== 'artist' && (
                                                <>
                                                    <ArtistLink artists={chartItem.artists}></ArtistLink>
                                                    <span> - </span>
                                                </>
                                            )}
                                            <Link key={chartItem.id} href={`/album?aid=${chartItem.id}`}>
                                                {chartItem.name}
                                            </Link>
                                        </span>
                                    ) : chartType === 'track' ? (
                                        <span key={chartItem.id}>
                                            {itemType !== 'artist' && (
                                                <>
                                                    <ArtistLink artists={chartItem.artists}></ArtistLink>
                                                    <span> - </span>
                                                </>
                                            )}
                                            <Link key={chartItem.id} href={`/track?tid=${chartItem.id}`}>
                                                {chartItem.name}
                                            </Link>
                                        </span>
                                    ) : (
                                        <></>
                                    )
                            };
                        })
                    );
                }

                setIsLoading(false);
                setUser(user);
                isLoadingRef.current = false;
            });
        }
    }, [userID, chartType, itemID, itemType, chartTimeFilter, updatePageMetdata]);

    if (!chartType || (!userID && userSessionData.isReady) || (!user.isInit && !isLoading)) {
        return <NotFound></NotFound>;
    }

    let extraBreadcrumbs: BreadcrumbsItem[] = [];

    if (itemType && itemID) {
        if (itemType === 'track') {
            extraBreadcrumbs = [{ text: chartItemName, href: `/track?tid=${itemID}`, textColor: 'dark-gray' }];
        } else if (itemType === 'album') {
            extraBreadcrumbs = [{ text: chartItemName, href: `/album?aid=${itemID}`, textColor: 'dark-gray' }];
        } else if (itemType === 'artist') {
            extraBreadcrumbs = [
                {
                    text: chartItemName,
                    href: `/artist?aid=${itemID}`,
                    textColor: 'dark-gray'
                }
            ];
        }
    }

    const timeTabItems: TabItem[] = [
        {
            text: 'Overall',
            isSelected: chartTimeFilter === ChartTimeFilter.Overall,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.Overall);
            }
        },
        {
            text: 'Last Year',
            isSelected: chartTimeFilter === ChartTimeFilter.LastYear,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.LastYear);
            }
        },
        {
            text: 'Last Month',
            isSelected: chartTimeFilter === ChartTimeFilter.LastMonth,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.LastMonth);
            }
        },
        {
            text: 'Last Week',
            isSelected: chartTimeFilter === ChartTimeFilter.LastWeek,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.LastWeek);
            }
        }
    ];

    return (
        <AppMainContainer showPreloader={!user.isInit}>
            <Section backgroundColor="white">
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[
                            { text: user.fullName, href: `/user?uid=${user.id}` },
                            {
                                text: `Top ${chartType.charAt(0).toUpperCase() + chartType.slice(1)}s`
                            },
                            ...extraBreadcrumbs
                        ]}
                    ></Breadcrumbs>
                </Title>
                <div className={`mb-100 `}>
                    <div>
                        <Tabs tabsItems={timeTabItems} viewMode="buttons"></Tabs>
                    </div>
                </div>
                {isLoading ? (
                    <Preloader viewMode="Inline"></Preloader>
                ) : chartItems.length > 0 ? (
                    <ChartTable chartItems={chartItems}></ChartTable>
                ) : (
                    <div className="mb-100">No user music activity within this time range</div>
                )}
            </Section>
        </AppMainContainer>
    );
}

export default UserCharts;
