import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageMetadata } from '../Types/PageMetadata';

export type setPageMetdataAction = { type: 'SET_PAGE_METADATA'; payload: { pageMetadata: PageMetadata } };

const defaultPageTitle = 'Bellino - Exploring Music';
const defaultPageDescription =
    'Welcome to Bellino, your place for music exploring. Explore live charts, listen for free to your favorite music, create and share your own playlist.';
const defaultPageImageURL = 'https://www.belllino.com/Images/logo.png';

const defaultPageMetdataState: PageMetadata = {
    title: defaultPageTitle,
    description: defaultPageDescription,
    keywords: 'Bellino,Belllino,Music,Track,Artist,Album,Release,Discography,Charts,Playlist,Online,Free,Listening,Streaming,Social,Network',
    imageURL: defaultPageImageURL
};

const pageMetadataSlice = createSlice({
    name: 'pageMetadata',
    initialState: defaultPageMetdataState,
    reducers: {
        setPageMetdata(state, action: PayloadAction<PageMetadata>) {
            state.title = action.payload.title ? `${action.payload.title} | Bellino` : defaultPageTitle;
            state.description = action.payload.description ?? defaultPageDescription;
            //state.keywords = action.payload.keywords ?? defaultPageTitle;
            state.imageURL = action.payload.imageURL ?? defaultPageImageURL;
        }
    }
});

export const { setPageMetdata } = pageMetadataSlice.actions;

export default pageMetadataSlice.reducer;
