import React, { useEffect, useRef, useState } from 'react';

import { PlaylistInfo } from '../../Types/PlaylistInfo';
import { getUserPlaylists, updatePlaylist } from '../../Services/User';
import useUserSessionData from '../../hooks/useUserSessionData';

import Dialog from '../../Containers/Dialog/Dialog';
import Button from '../../Components/Button/Button';
import Preloader from '../../Components/Preloader/Preloader';
import GuestDialog from './GuestDialog';
import { MultiSearchSelection, SearchItemInfo } from '../../Components/MultiSearchSelection/MultiSearchSelection';

function AddTrackToMyPlaylistsDialog(props: { trackId: string; isVisible: boolean; onCloseDialog: () => void }) {
    const { trackId, isVisible, onCloseDialog } = props;

    const { userSessionData } = useUserSessionData();

    const isLoadedPlaylists = useRef<boolean>();
    const isUserEditPlaylists = useRef<boolean>(false);

    const [userPlaylists, setUserPlaylists] = useState<PlaylistInfo[]>([]);
    const [selectedPlaylists, setSelectedPlaylists] = useState<SearchItemInfo[]>([]);
    const [resetSearch, setResetSearch] = useState(false);

    const onUpdatePlaylists = () => {
        // Add track to selected playlist
        selectedPlaylists.forEach(playlist => {
            const selectedPlaylist = userPlaylists.find(p => p.id === playlist.id);

            if (selectedPlaylist && !selectedPlaylist.tracksIds?.includes(trackId)) {
                //console.log('Add track to selected playlist', playlist.name);
                updatePlaylist(selectedPlaylist.id, selectedPlaylist.name, [...(selectedPlaylist.tracksIds ?? []), trackId]);
            }
        });

        // Remove track from unselected playlist
        userPlaylists
            .filter(p => !selectedPlaylists.map(sp => sp.id).includes(p.id))
            .forEach(playlist => {
                if (playlist?.tracksIds?.includes(trackId)) {
                    //console.log('Remove track from unselected playlist', playlist.name);
                    updatePlaylist(
                        playlist.id,
                        playlist.name,
                        playlist.tracksIds.filter(t => t !== trackId)
                    );
                }
            });

        isUserEditPlaylists.current = false;
        isLoadedPlaylists.current = false;

        onCloseDialog();

        setTimeout(() => {
            setResetSearch(true);
            setSelectedPlaylists([]);
            setResetSearch(false);
        }, 100);
    };

    useEffect(() => {
        const fetchUserPlaylists = async () => {
            const playlists = await getUserPlaylists();

            setUserPlaylists(playlists);
            setSelectedPlaylists(playlists.filter(p => p.tracksIds?.includes(trackId.toString())));

            isLoadedPlaylists.current = true;
        };

        if (userSessionData.userInfo?.id && isVisible && isLoadedPlaylists.current !== true) {
            fetchUserPlaylists();
        }
    }, [isVisible, trackId, userSessionData.userInfo?.id]);

    if (!userSessionData.userInfo?.id) {
        return (
            <GuestDialog isVisible={isVisible} userActionDescription={'create and update playlists'} onCloseDialog={onCloseDialog}></GuestDialog>
        );
    }

    return (
        <Dialog isVisible={isVisible} title="Add Track To My Playlists" onCloseDialog={onCloseDialog}>
            {Boolean(isLoadedPlaylists.current && userPlaylists.length) ? (
                <>
                    <MultiSearchSelection
                        width="100%"
                        placeholder="Search playlist"
                        reset={resetSearch}
                        selectedItems={selectedPlaylists}
                        searchItems={userPlaylists}
                        onSelectionChange={selectedSearchItems => {
                            isUserEditPlaylists.current = true;
                            setSelectedPlaylists(selectedSearchItems);
                        }}
                    ></MultiSearchSelection>
                    <div className="mt-150">
                        <Button viewMode="fancy" onClick={onUpdatePlaylists} isDisabled={isUserEditPlaylists.current === false}>
                            Save
                        </Button>
                    </div>
                </>
            ) : (
                <Preloader viewMode="Inline"></Preloader>
            )}
        </Dialog>
    );
}

export default AddTrackToMyPlaylistsDialog;
