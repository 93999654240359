import React, { useEffect, useRef } from 'react';

import { KeyValue } from '../../Types/KeyValue';
import Preloader from '../Preloader/Preloader';
import classes from './SelectionList.module.css';

export type RichSelectionListProps = {
    selectionOptions: string[];
    highlightOptions?: string[];
    showPreloader?: boolean;
    onSelectionChange: (selectedOptions: KeyValue) => void;
};

function RichSelectionList(props: RichSelectionListProps) {
    const { selectionOptions, highlightOptions, onSelectionChange } = props;
    const showPreloader = props.showPreloader ?? false;
    const extraOptions = highlightOptions?.length ? selectionOptions.filter(option => !highlightOptions.includes(option)) : selectionOptions;

    const newSelectedOptions = useRef<KeyValue>({});

    const onOptionSelect = (event: React.MouseEvent, option: string, isDeselect: boolean) => {
        const eventTargetButtonElement = event.target as HTMLButtonElement;
        const targetListElement = eventTargetButtonElement.closest('li');

        if (targetListElement) {
            targetListElement.classList.remove(classes.richSelectionItemSelected, classes.richSelectionItemDeselected);
        }

        const optionValue = isDeselect ? -1 : 1;

        if (typeof newSelectedOptions.current[option] !== 'undefined' && newSelectedOptions.current[option] === optionValue) {
            delete newSelectedOptions.current[option];
        } else {
            newSelectedOptions.current[option] = isDeselect ? -1 : 1;

            if (targetListElement) {
                targetListElement.classList.add(isDeselect ? classes.richSelectionItemDeselected : classes.richSelectionItemSelected);
            }
        }

        onSelectionChange(newSelectedOptions.current);
    };

    useEffect(() => {
        newSelectedOptions.current = {};
        document
            .querySelectorAll(`.${classes.richSelectionItem}`)
            .forEach(listItem => listItem.classList.remove(classes.richSelectionItemSelected, classes.richSelectionItemDeselected));
    }, [highlightOptions]);

    if (showPreloader) {
        return <Preloader viewMode="InlineBox"></Preloader>;
    }

    //console.log(highlightOptions, highlightOptions?.length, extraOptions);

    return (
        <ul className={classes.selectionList}>
            {highlightOptions &&
                highlightOptions?.length > 0 &&
                highlightOptions.map((option, index) => (
                    <li key={`index_${index}`} className={classes.richSelectionItem}>
                        {option}
                        <div className={classes.optionButtons}>
                            <button
                                onClick={event => {
                                    onOptionSelect(event, option, true);
                                }}
                            >
                                ✖
                            </button>
                            <button
                                onClick={event => {
                                    onOptionSelect(event, option, false);
                                }}
                            >
                                ✔
                            </button>
                        </div>
                    </li>
                ))}
            {highlightOptions && highlightOptions?.length > 0 && <li className={classes.seperator}></li>}
            {extraOptions?.length &&
                extraOptions.map((option, index) => (
                    <li key={index} className={classes.richSelectionItem}>
                        {option}
                        <div className={classes.optionButtons}>
                            <button
                                onClick={event => {
                                    onOptionSelect(event, option, false);
                                }}
                            >
                                ✔
                            </button>
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default RichSelectionList;
