import React from 'react';
import { Link } from 'react-router-dom';

import Tag from '../../Tag/Tag';
import Image from '../../Image/Image';
import ItemPlaybar from '../../ItemPlaybar/ItemPlaybar';
import { MusicItemType } from '../../../Types/MusicItemType';

import classes from './ChartList.module.css';

export type ChartItemTag = {
    id: string;
    name: string;
    href?: string;
};

export type ChartItem = {
    id: string;
    type: MusicItemType;
    position: number;
    score?: number;
    mainTitle: string;
    mainTitleHref?: string;
    subTitle: any;
    date: string;
    tags: ChartItemTag[];
    image?: string;
};

export type ChartListProps = {
    chartItems: ChartItem[];
};

function ChartList(props: ChartListProps) {
    return (
        <ul className={classes.chartList}>
            {props.chartItems.map((chartItem, index) => (
                <li key={index} data-id={chartItem.id}>
                    <div className={classes.chartPosition}>{chartItem.position}</div>
                    <div>
                        {chartItem.mainTitleHref ? (
                            <Link to={chartItem.mainTitleHref} className="fs-135 fw-500">
                                <Image src={chartItem.image ?? ''} viewMode="background-image" imageClass={classes.chartImage}></Image>
                            </Link>
                        ) : (
                            <Image src={chartItem.image ?? ''} viewMode="background-image" imageClass={classes.chartImage}></Image>
                        )}
                    </div>
                    <div className={`${classes.chartItemInfo} ${chartItem.type === 'Artist' ? 'me-100' : 'me-700'}`}>
                        <div>
                            {chartItem.mainTitleHref ? (
                                <Link to={chartItem.mainTitleHref} className="fs-135 fw-500">
                                    {chartItem.mainTitle}
                                </Link>
                            ) : (
                                <span className="fs-135 fw-500">{chartItem.mainTitle}</span>
                            )}
                        </div>
                        <div>
                            <span>{chartItem.subTitle}</span>
                        </div>
                        <div className="fs-105 text-gray mt-50">{chartItem.date}</div>
                        <div className="fs-105 mt-75">
                            {chartItem.tags?.map((tag, index) => (
                                <span key={index}>
                                    <Tag viewMode="link" text={tag.name} href={tag.href ?? ''}></Tag>
                                    {index + 1 < chartItem.tags.length && <span>, </span>}
                                </span>
                            ))}
                        </div>
                    </div>
                    {chartItem.type !== 'Artist' && (
                        <div className={classes.playButtons}>
                            <ItemPlaybar itemType={chartItem.type} itemId={chartItem.id} viewMode="inline"></ItemPlaybar>
                        </div>
                    )}
                </li>
            ))}
        </ul>
    );
}

export default ChartList;
