import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { setStatus, setViewMode, setCurrentTrack, removeTrack, playNextTrack, updateVideoDuration } from '../../redux/musicPlayerSlice';

import { PlayerStateMode, TimerMode } from '../../Types/Player';
import { TrackInfo } from '../../Types/Track';
import { SecondsToTime } from '../../Classes/Utilities';
import { flagTrackVideo, updateTrackVideoDuration } from '../../Services/Tracks';
import { addUserListening } from '../../Services/User';

import YouTubePlayer from '../Youtube/Youtube';
import Slider from '../Slider/Slider';

import ListenerGuestDialog from './_partials/ListenerGuestDialog';

import classes from './MusicPlayer.module.css';
import { BasicVideoInfo } from '../../Types/VideoInfo';
import MusicPlayerTracklist from './_partials/MusicPlayerTracklist';

interface MusicPlayerPortalProps {
    children: React.ReactNode;
}

export const MusicPlayerPortal: React.FC<MusicPlayerPortalProps> = ({ children }) => {
    const portalRoot = document.getElementById('youtube-portal-root');
    return portalRoot ? ReactDOM.createPortal(children, portalRoot) : null;
};

const MusicPlayer = () => {
    const { viewMode, viewHeight, playStatus, currentTrackIndex, tracklist } = useSelector((state: RootState) => state.musicPlayer);
    const { userSessionData } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();

    const musicPlayerElementRef = useRef(null);
    const playerObject = useRef<any>(null);
    const playerTimerInterval = useRef<NodeJS.Timer>();

    const currentPlayingTrack = useRef<TrackInfo | null>(null);
    const currentPlayingVideo = useRef<BasicVideoInfo | null>(null);
    const currentTrackTotalPlayedTime = useRef(0);
    const currentTrackAddedToListenings = useRef(false);
    const currentPlayStatus = useRef(playStatus);

    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [timerMode, setTimerMode] = useState<TimerMode>('count-up');
    const [timerStr, setTimerStr] = useState('');
    const [currentVideoTime, setCurrentVideoTime] = useState(0);
    const [forceMusicPlayerReload, setForceMusicPlayerReload] = useState(false);
    const [isListenerGuestDialogVisible, setIsListenerGuestDialogVisible] = useState(false);

    const currentTrack = useMemo(
        () => (tracklist.length >= currentTrackIndex ? tracklist[currentTrackIndex] : null),
        [currentTrackIndex, tracklist]
    );
    const currentVideo = useMemo(() => (currentTrack ? currentTrack.videos?.[0] : null), [currentTrack]);
    const currentVideoDuration = useMemo(() => currentVideo?.duration ?? 0, [currentVideo?.duration]);
    const defaultVlayerVolume = useMemo(() => Number(localStorage.getItem('playerVolume') ?? 50), []);

    const getButtonIconByStatus = (): string => {
        if (playStatus === 'buffering') {
            return 'fa-pause';
            //return 'fa-spinner spin';
        } else if (playStatus === 'playing') {
            return 'fa-pause';
        }

        return 'fa-play';
    };

    const getVloumeIcon = (): string => {
        const playerVolume = playerObject.current?.getVolume?.() ?? defaultVlayerVolume;

        if (isMuted || playerVolume === 0) {
            return 'fa-volume-mute';
        } else if (playerVolume < 30) {
            return 'fa-volume-off';
        } else if (playerVolume < 50) {
            return 'fa-volume-low';
        }

        return 'fa-volume-high';
    };

    const triggerGuestSignUp = useCallback(() => {
        if (userSessionData.isReady && !userSessionData.userInfo?.id) {
            const LOCAL_STORAGE_KEY = 'num_of_plays';
            const LOCAL_STORAGE_KEY_MAX_VALUE = 10;

            let keyValue = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '0');

            if (keyValue > LOCAL_STORAGE_KEY_MAX_VALUE) {
                localStorage.setItem(LOCAL_STORAGE_KEY, '0');
                setIsListenerGuestDialogVisible(true);
                playerObject.current?.pauseVideo?.();
            } else {
                localStorage.setItem(LOCAL_STORAGE_KEY, (keyValue + 1).toString());
            }
        }
    }, [userSessionData.isReady, userSessionData.userInfo?.id]);

    const buildTimerStr = useCallback(() => {
        if (currentVideoDuration > 0) {
            // Update player displayed time
            if (timerMode === 'count-up')
                setTimerStr(SecondsToTime(Math.round(currentVideoTime - 0.1)) + ' / ' + SecondsToTime(Math.round(currentVideoDuration - 0.1)));
            else if (timerMode === 'count-down') {
                setTimerStr(SecondsToTime(Math.round(currentVideoTime - 0.1)));
            } else {
                setTimerStr(SecondsToTime(Math.round(currentVideoDuration - currentVideoTime - 0.1)));
            }
        } else {
            if (timerMode === 'count-up') {
                setTimerStr('00:00 / 00:00');
            } else {
                setTimerStr('00:00');
            }
        }
    }, [currentVideoDuration, currentVideoTime, timerMode]);

    const togglePlay = () => {
        if (playStatus === 'playing') {
            playerObject.current.pauseVideo();
            dispatch(setStatus('paused'));
        } else {
            if (currentTrackIndex < 0) {
                dispatch(setCurrentTrack(0));
            } else {
                playerObject.current.playVideo();
                dispatch(setStatus('playing'));
            }
        }
    };

    const toggleMute = () => {
        if (playerObject.current) {
            if (playerObject.current.isMuted()) {
                playerObject.current.unMute();
                setIsMuted(false);
            } else {
                playerObject.current.mute();
                setIsMuted(true);
            }
        }
    };

    const setVolume = (volume: number) => {
        if (playerObject.current) {
            playerObject.current.setVolume(volume);
            localStorage.setItem('playerVolume', volume.toString());

            if (volume > 0 && playerObject.current.isMuted()) {
                playerObject.current.unMute();
                setIsMuted(false);
            }
        }
    };

    const onSeekToTime = (timePrecent: number) => {
        const currentPlayingVideoDuration = currentPlayingVideo.current?.duration ?? 0;

        if (playerObject.current && currentPlayingVideoDuration > 0) {
            const currentTime = (currentPlayingVideoDuration * timePrecent) / 100;
            playerObject.current.seekTo(currentTime, true);
        }
    };

    const onPlayerStateChange = useCallback(
        (state: PlayerStateMode) => {
            currentPlayStatus.current = state;

            if (state === 'ended') {
                resetCurrentUserListeningInfo();
                dispatch(playNextTrack());

                setTimeout(() => {
                    setForceMusicPlayerReload(true);
                }, 100);
            } else {
                dispatch(setStatus(state));
            }

            if (state === 'playing') {
                setForceMusicPlayerReload(false);
                triggerGuestSignUp();

                if (currentPlayingVideo.current && currentPlayingVideo.current.duration <= 0) {
                    const trackVideoDuration = Math.ceil(playerObject.current.getDuration());
                    currentPlayingVideo.current.duration = trackVideoDuration;
                    updateTrackVideoDuration(currentPlayingVideo.current.externalId, trackVideoDuration);
                    dispatch(updateVideoDuration({ externalVideoId: currentPlayingVideo.current.externalId, duration: trackVideoDuration }));
                }
            }
        },
        [dispatch, triggerGuestSignUp]
    );

    const resetCurrentUserListeningInfo = () => {
        currentTrackTotalPlayedTime.current = 0;
        currentTrackAddedToListenings.current = false;
        setCurrentVideoTime(0);
    };

    useEffect(() => {
        if (viewMode !== 'none' && !playerTimerInterval.current) {
            playerTimerInterval.current = setInterval(() => {
                if (currentPlayStatus.current === 'playing' && currentPlayingTrack.current?.id && currentPlayingVideo.current) {
                    //console.log('playerTimerInterval', playerObject.current.getCurrentTime());
                    setCurrentVideoTime(playerObject.current.getCurrentTime());

                    currentTrackTotalPlayedTime.current += 0.5;
                    //console.log(currentPlayingTrack.current?.id, currentTrackTotalPlayedTime.current);

                    if (
                        !currentTrackAddedToListenings.current &&
                        currentPlayingTrack.current?.id &&
                        currentPlayingVideo.current.duration > 0 &&
                        currentTrackTotalPlayedTime.current / currentPlayingVideo.current.duration > 0.33
                    ) {
                        currentTrackAddedToListenings.current = true;
                        //console.log('OnAddUserListening', currentPlayingTrack.current.id);
                        addUserListening(currentPlayingTrack.current.id, currentPlayingTrack.current.albumId);
                    }
                }
            }, 500);
        }

        return () => {
            //viewMode !== 'none' && playerTimerInterval.current && clearInterval(playerTimerInterval.current);
        };
    }, [viewMode]);

    useEffect(() => {
        buildTimerStr();
    }, [buildTimerStr]);

    useEffect(() => {
        currentPlayingTrack.current = currentTrack;
        resetCurrentUserListeningInfo();
    }, [currentTrack]);

    useEffect(() => {
        currentPlayingVideo.current = currentVideo ?? null;
    }, [currentVideo]);

    useEffect(() => {
        const onBeforeUnload = (event: BeforeUnloadEvent) => {
            const isPlaying = playStatus === 'playing' && !playerObject.current.isMuted();

            if (isPlaying) {
                event.preventDefault();
                return 'Player still playing. Do you really want to close?';
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [playStatus]);

    const sliderVideoCurrentTime = useMemo(
        () => (currentVideoDuration > 0 ? (currentVideoTime / currentVideoDuration) * 100 : 0),
        [currentVideoDuration, currentVideoTime]
    );

    if (viewMode === 'none') {
        return null;
    }

    return (
        <>
            <div
                ref={musicPlayerElementRef}
                className={`${classes.musicPlayer} ${viewMode === 'normal' ? classes.open : ''}`}
                style={{ height: viewHeight }}
            >
                <div>
                    <div className={classes.playerToolbar}>
                        <div>{`Track ${currentTrackIndex + 1}/${tracklist.length}`}</div>
                        <div
                            className={classes.playerTime}
                            onClick={() => {
                                setTimerMode(timerMode === 'count-up' ? 'count-down' : 'count-up');
                            }}
                        >
                            {timerStr}
                        </div>
                        <Slider
                            curVal={sliderVideoCurrentTime}
                            sliderHandlerShape="none"
                            sliderCustomCSS={{ border: 0, backgroundColor: 'rgb(34, 34, 34)', margin: '0 1rem' }}
                            onChange={(timePos, isDragged) => {
                                !isDragged && onSeekToTime(timePos);
                            }}
                        ></Slider>
                        <button
                            className={`fa fa-lg fa-backward`}
                            onClick={() => {
                                dispatch(setCurrentTrack(currentTrackIndex > 0 ? currentTrackIndex - 1 : tracklist.length - 1));
                                setTimeout(() => {
                                    setForceMusicPlayerReload(true);
                                }, 50);
                            }}
                        ></button>
                        <button
                            style={{ width: '3.5rem' }}
                            onClick={() => {
                                if (playStatus !== 'buffering') {
                                    togglePlay();
                                }
                            }}
                        >
                            <i className={`fa fa-lg ${getButtonIconByStatus()}`}></i>
                        </button>
                        <button
                            className={`fa fa-lg fa-forward`}
                            onClick={() => {
                                dispatch(setCurrentTrack(currentTrackIndex < tracklist.length - 1 ? currentTrackIndex + 1 : 0));
                                setTimeout(() => {
                                    setForceMusicPlayerReload(true);
                                }, 50);
                            }}
                        ></button>
                        <button
                            style={{ width: '4.5rem', textAlign: 'start' }}
                            onClick={() => {
                                toggleMute();
                            }}
                        >
                            <i className={`fa fa-lg ${getVloumeIcon()}`}></i>
                        </button>
                        <div className={classes.playerVolume}>
                            <Slider
                                curVal={defaultVlayerVolume}
                                sliderHandlerShape="circle"
                                sliderCustomCSS={{ border: 0, backgroundColor: 'rgb(34, 34, 34)', width: '50px' }}
                                onChange={volumeVal => {
                                    setVolume(volumeVal);
                                }}
                            ></Slider>
                        </div>
                        <button
                            className={`fa fa-lg ${viewMode === 'normal' ? 'fa-compress' : 'fa-expand'}`}
                            title={viewMode === 'normal' ? 'Minimize' : 'Expand'}
                            onClick={() => {
                                dispatch(setViewMode(viewMode === 'normal' ? 'small' : 'normal'));
                            }}
                        ></button>
                    </div>
                    <div className={classes.playerTrackslist}>
                        <MusicPlayerTracklist
                            tracklist={tracklist}
                            currentTrackIndex={currentTrackIndex}
                            onPlayTrack={index => {
                                playerObject.current.stopVideo();
                                dispatch(setCurrentTrack(index));
                                setTimeout(() => {
                                    setForceMusicPlayerReload(true);
                                    playerObject.current.playVideo();
                                }, 50);
                            }}
                            onRemoveTrack={index => {
                                dispatch(removeTrack(index));
                            }}
                        ></MusicPlayerTracklist>
                    </div>
                </div>
                <div className={classes.youtubeContainer}>
                    <YouTubePlayer
                        videoId={currentVideo?.externalId ?? ''}
                        forceReload={forceMusicPlayerReload}
                        onReady={player => {
                            playerObject.current = player;
                            setVolume(defaultVlayerVolume);
                        }}
                        onStateChange={state => {
                            onPlayerStateChange(state);
                        }}
                        onError={error => {
                            flagTrackVideo(currentVideo?.externalId ?? '', 'External');
                        }}
                    ></YouTubePlayer>
                </div>
            </div>

            <ListenerGuestDialog
                isVisible={isListenerGuestDialogVisible}
                onCloseDialog={() => {
                    setIsListenerGuestDialogVisible(false);
                }}
            ></ListenerGuestDialog>
        </>
    );
};

export default MusicPlayer;
