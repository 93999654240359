import React, { useRef, useState } from 'react';

import Button from '../../../Components/Button/Button';
import Dialog from '../../../Containers/Dialog/Dialog';
import SimpleSelectionList from '../../../Components/SelectionList/SimpleSelectionList';

import classes from './TimeFilterDialog.module.css';

const nowYear = new Date().getFullYear();

const selectionOptionsMap = new Map<string, { min?: number; max?: number }>([
    ['All Times', { min: undefined, max: undefined }],
    [nowYear.toString(), { min: nowYear, max: nowYear }],
    [(nowYear - 1).toString(), { min: nowYear - 1, max: nowYear - 1 }],
    ["2020's", { min: 2020, max: 2029 }],
    ["2010's", { min: 2010, max: 2019 }],
    ["2000's", { min: 2000, max: 2009 }],
    ["1990's", { min: 1990, max: 1999 }],
    ["1980's", { min: 1980, max: 1989 }],
    ["1970's", { min: 1970, max: 1979 }],
    ["1960's", { min: 1960, max: 1969 }],
    ["1950's", { min: 1950, max: 1959 }],
    ['Custom', { min: undefined, max: undefined }]
]);

const selectionOptions = Array.from(selectionOptionsMap.keys());

export type TimeFilterDialogProps = {
    isVisible: boolean;
    selectedTimeFilter?: string;
    onFilterValueChanged: (value: string, minYear?: number, maxYear?: number) => void;
    onCloseDialog: () => void;
};

function TimeFilterDialog(props: TimeFilterDialogProps) {
    const { isVisible, selectedTimeFilter, onCloseDialog, onFilterValueChanged } = props;

    const [selectedOption, setSelectedOption] = useState(selectedTimeFilter ?? 'All Times');
    const [selectedMinYear, setSelectedMinYear] = useState(2000);
    const [selectedMaxYear, setSelectedMaxYear] = useState(nowYear);

    const minYearElementRef = useRef<HTMLSelectElement>(null);
    const maxYearElementRef = useRef<HTMLSelectElement>(null);

    const onSubmit = () => {
        if (selectedOption) {
            const submittedOption = selectionOptionsMap.get(selectedOption);

            if (selectedOption !== 'Custom' && submittedOption) {
                onFilterValueChanged(selectedOption, submittedOption.min, submittedOption.max);
            } else {
                onFilterValueChanged(selectedOption, selectedMinYear, selectedMaxYear);
            }

            onCloseDialog();
        }
    };

    const onCustomInputChange = () => {
        const customMinYear = minYearElementRef.current?.value ? parseInt(minYearElementRef.current?.value) : -1;
        const customMaxYear = maxYearElementRef.current?.value ? parseInt(maxYearElementRef.current?.value) : -1;

        if (customMinYear <= customMaxYear) {
            setSelectedMinYear(customMinYear);
            setSelectedMaxYear(customMaxYear);
        } else {
            setSelectedMinYear(customMaxYear);
            setSelectedMaxYear(customMinYear);
        }
    };

    return (
        <Dialog isVisible={isVisible} title="Filter By Decades" onCloseDialog={onCloseDialog}>
            <SimpleSelectionList
                selectionOptions={selectionOptions}
                selectionMode="Single-Selection"
                selectedOptions={[selectedOption]}
                onSelectionChange={value => {
                    if (value.length > 0) {
                        setSelectedOption(value[0]);
                    }
                }}
            ></SimpleSelectionList>
            <div className={classes.footer}>
                <div>
                    {selectedOption === 'Custom' && (
                        <>
                            <select
                                ref={minYearElementRef}
                                value={selectedMinYear}
                                style={{ width: '70px', marginInlineEnd: '-1px' }}
                                onChange={onCustomInputChange}
                            >
                                {[...Array<number>(nowYear - 1900).keys()].map(index => (
                                    <option key={index}>{new Date().getFullYear() - index}</option>
                                ))}
                            </select>
                            <select ref={maxYearElementRef} value={selectedMaxYear} style={{ width: '70px' }} onChange={onCustomInputChange}>
                                {[...Array<number>(nowYear - 1900).keys()].map(index => (
                                    <option key={index}>{nowYear - index}</option>
                                ))}
                            </select>
                        </>
                    )}
                </div>
                <div>
                    <Button isDisabled={!selectedOption} viewMode="fancy" onClick={onSubmit}>
                        Ok
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default TimeFilterDialog;
