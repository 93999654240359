import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { setMainSearchData, setActiveDialog } from '../../redux/uiSlice';

import useUserSessionData from '../../hooks/useUserSessionData';
import { enableScrollBar } from '../../Classes/Utilities';

import Logo from '../../Components/Logo/Logo';
import Hamburger from '../../Components/Hamburger/Hamburger';
import Header from '../../Containers/Header/Header';
import Footer from '../../Containers/Footer/Footer';
import Preloader from '../../Components/Preloader/Preloader';
import Button from '../../Components/Button/Button';
import Link from '../../Components/Link/Link';
import UserAvatar from '../../Components/UserAvatar/UserAvatar';
import { Notifications } from '../../Components/Notifications/Notifications';

import { FeedbackDialog } from './partials/FeedbackDialog';
import { BellinoSearch } from './partials/BellinoSearch';

import classes from './AppMainContainer.module.css';
import NoVideosDialog from '../../Components/Dialogs/NoVideosDialog/NoVideosDialog';
import UploadErrorDialog from '../../Components/Dialogs/UploadErrorDialog/UploadErrorDialog';

export type AppMainContainerProps = {
    backgroundColor?: string;
    backgroundImage?: string;
    showPreloader?: boolean;
    children: any;
    onReady?: () => void;
};

/*****************************************************************************************************/
/* Main Application */

function AppMainContainer(props: AppMainContainerProps) {
    const { backgroundColor, backgroundImage, showPreloader, children, onReady } = props;

    const bgColor = backgroundColor ? 'bg-' + backgroundColor : '';
    const bgImageClass = backgroundImage ? classes.coverBackgroundImage : '';

    const { mainSearchText, mainSearchResults, activeDialog, activeDialogData } = useSelector((state: RootState) => state.ui);

    const { pathname, search } = useLocation();
    const { userSessionData, notifications } = useUserSessionData();
    const dispatch = useDispatch<AppDispatch>();

    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [isFeedbackDialogVisible, setIsFeedbackDialogVisible] = useState(false);

    const isShowPreloader = useMemo(() => Boolean(!userSessionData.isReady || showPreloader), [showPreloader, userSessionData.isReady]);
    const noHeaderStyle = useMemo(
        () => (!isHeaderVisible ? { paddingTop: 0, minHeight: 'max(410px, calc(-1.5em + 100vh))' } : {}),
        [isHeaderVisible]
    );

    const onCloseFeedbackDialog = () => {
        setIsFeedbackDialogVisible(false);
    };

    useEffect(() => {
        setIsHeaderVisible(['/', '/signup', '/forgot_password'].includes(pathname) ? false : true);
        enableScrollBar();
        window.scrollTo(0, 0);
    }, [pathname, search]);

    useEffect(() => {
        if (!isShowPreloader) {
            onReady?.();
        }
    }, [isShowPreloader, onReady]);

    const mainSectionStyle: React.CSSProperties = {
        backgroundImage: backgroundImage && !isShowPreloader ? `url(${backgroundImage})` : undefined,
        ...noHeaderStyle
    };

    return (
        <>
            {isHeaderVisible && (
                <Header
                    startSection={
                        <>
                            <Logo href="/home" text="Bellino" viewSize="small"></Logo>
                            <div className={classes.mainSearch}>
                                <BellinoSearch
                                    searchText={mainSearchText}
                                    searchResults={mainSearchResults}
                                    onSearchResultsChanged={(searchText: string, searchResults: any) => {
                                        dispatch(setMainSearchData({ searchText, searchResults }));
                                    }}
                                ></BellinoSearch>
                            </div>
                        </>
                    }
                    endSection={
                        <>
                            {userSessionData.userInfo?.id ? (
                                <>
                                    <UserAvatar
                                        userInfo={userSessionData.userInfo}
                                        avatarSize="tiny"
                                        viewMode="inline"
                                        userNameNode="none"
                                    ></UserAvatar>
                                    <Notifications notifications={notifications}></Notifications>
                                </>
                            ) : (
                                userSessionData.isReady && (
                                    <span className={classes.signinLink}>
                                        <Link href="/" viewMode={'Button'}>
                                            Sign in
                                        </Link>
                                    </span>
                                )
                            )}
                            <Hamburger></Hamburger>
                        </>
                    }
                ></Header>
            )}

            <main className={`${classes.AppContainer} ${bgColor} ${isShowPreloader ? '' : bgImageClass}`} style={mainSectionStyle}>
                {isShowPreloader ? <Preloader></Preloader> : children}
            </main>

            <Footer>
                <span>Bellino © 2014-{new Date().getFullYear()}</span>
                <span style={{ margin: '0 .5rem' }}> • </span>
                <Button
                    viewMode="link"
                    onClick={() => {
                        setIsFeedbackDialogVisible(true);
                    }}
                >
                    Send Feedback
                </Button>
            </Footer>

            <NoVideosDialog
                isVisible={activeDialog === 'NO_VIDEOS'}
                itemType={activeDialogData}
                onCloseDialog={() => {
                    dispatch(setActiveDialog({ activeDialog: 'NONE', activeDialogData: undefined }));
                }}
            ></NoVideosDialog>

            <UploadErrorDialog
                isVisible={activeDialog === 'UPLOAD_ERROR'}
                onCloseDialog={() => {
                    dispatch(setActiveDialog({ activeDialog: 'NONE', activeDialogData: undefined }));
                }}
            ></UploadErrorDialog>

            <FeedbackDialog isVisible={isFeedbackDialogVisible} onCloseDialog={onCloseFeedbackDialog}></FeedbackDialog>
        </>
    );
}

export default AppMainContainer;
