import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { xPosition, PositionRect } from '../../Types/Position';

import { isClickInsideElement } from '../../Classes/Utilities';

import classes from './Menu.module.css';

export type MenuItem = {
    text: string;
    href?: string;
    hrefTarget?: '_blank' | '_self';
    iconName?: string;
    isSubItem?: boolean;
    onClick?: () => void;
};

export type MenuSection = {
    menuItems: MenuItem[];
};

export type MenuProps = {
    sections: MenuSection[];
    anchorElement?: HTMLElement;
    xPosition?: xPosition; // Position relative to anchor element
    isOpen: boolean;
    parentType?: 'relative' | 'fixed';
    onClose: () => void;
};

function Menu(props: MenuProps) {
    const { isOpen, anchorElement, xPosition = 'Center', parentType = 'relative', onClose } = props;

    const menuBoxElementRef = useRef(null);
    const positionRect = useRef<PositionRect>({});
    const anchorElementWidth = useRef<number>();

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const calcMenuPositionRect = (menuBoxElement: HTMLElement, anchorElement: HTMLElement) => {
            let menuPositionRect: PositionRect;

            const anchorElementRect = anchorElement?.getBoundingClientRect?.();
            anchorElementWidth.current = anchorElementRect.width;

            const menuBoxElementRect = menuBoxElement.getBoundingClientRect();
            const menuBoxElementWidth = menuBoxElementRect.width;

            let xPos = anchorElementRect.left;

            if (xPosition === 'Center') {
                xPos = anchorElementRect.left + anchorElementRect.width / 2;

                // Center the menu horizontally
                xPos = xPos - menuBoxElementWidth / 2;

                // Adjust x position to keep the menu within window boundaries
                if (xPos < 5) {
                    xPos = 5;
                } else if (xPos + menuBoxElementWidth > document.body.clientWidth - 3) {
                    xPos = document.body.clientWidth - menuBoxElementWidth - 3;
                }
            }

            // Determine whether to place the menu above or below the anchor element
            let positionAbove = anchorElementRect.top + menuBoxElementRect.height > window.innerHeight;

            if (positionAbove) {
                // Place menu above the anchor element
                let yPosBottom = window.innerHeight - anchorElementRect.top + 5;
                menuPositionRect = { left: xPos, bottom: yPosBottom };
            } else {
                // Place menu below the anchor element
                let yPosTop = anchorElement.offsetTop + anchorElementRect.height + 5;
                menuPositionRect = { left: xPos, top: yPosTop };

                //let yPosBottom = anchorElementRect.bottom + 5; //- 180 + 5;
                //positionRect.current = { left: xPos, top: yPosBottom };
            }

            return menuPositionRect;
        };

        const calcRelativeMenuPositionRect = (menuBoxElement: HTMLElement, anchorElement: HTMLElement) => {
            let menuPositionRect: PositionRect;

            const anchorElementRect = anchorElement?.getBoundingClientRect?.();
            anchorElementWidth.current = anchorElementRect.width;

            const menuBoxElementRect = menuBoxElement.getBoundingClientRect();
            const menuBoxElementWidth = menuBoxElementRect.width;

            let xPos = 0;

            if (xPosition === 'Center') {
                xPos = xPos + anchorElementRect.width / 2;

                // Center the menu horizontally
                xPos = xPos - menuBoxElementWidth / 2;

                // Adjust x position to keep the menu within window boundaries
                if (anchorElementRect.left + xPos < 5) {
                    xPos = 5;
                } else if (anchorElementRect.left + xPos + menuBoxElementWidth > document.body.clientWidth - 3) {
                    xPos = document.body.clientWidth - menuBoxElementWidth - anchorElementRect.left - 3;
                }
            } else if (xPosition === 'Right') {
                xPos = anchorElementRect.width - menuBoxElementWidth;
            }

            // Determine whether to place the menu above or below the anchor element
            let positionAbove = anchorElementRect.top + menuBoxElementRect.height > window.innerHeight;

            if (positionAbove) {
                // Place menu above the anchor element
                let yPosBottom = anchorElementRect.height + 5;
                menuPositionRect = { left: xPos, bottom: yPosBottom };
            } else {
                // Place menu below the anchor element
                let yPosTop = anchorElement.offsetTop + anchorElementRect.height + 5;
                menuPositionRect = { left: xPos, top: yPosTop };

                //let yPosBottom = anchorElementRect.bottom + 5; //- 180 + 5;
                //positionRect.current = { left: xPos, top: yPosBottom };
            }

            //console.log('menuPositionRect = ', menuPositionRect);
            return menuPositionRect;
        };

        const menuBoxElement = menuBoxElementRef.current ? (menuBoxElementRef.current as HTMLElement) : null;

        if (isOpen && anchorElement && menuBoxElement) {
            positionRect.current =
                parentType === 'relative'
                    ? calcRelativeMenuPositionRect(menuBoxElement, anchorElement)
                    : calcMenuPositionRect(menuBoxElement, anchorElement);

            setIsReady(true);
        }
    }, [anchorElement, isOpen, parentType, xPosition]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
                anchorElement && anchorElement.focus();
            } else if (event.key === 'Tab') {
            }
        };

        const checkForOutsideClick = (event: MouseEvent) => {
            const menuBoxRect = menuBoxElementRef.current ? (menuBoxElementRef.current as HTMLElement).getBoundingClientRect() : null;
            const anchorElementRect = anchorElement?.getBoundingClientRect?.();

            if (
                isReady &&
                isOpen &&
                anchorElement !== event.target &&
                !isClickInsideElement(event, menuBoxRect) &&
                !isClickInsideElement(event, anchorElementRect)
            ) {
                onClose();
            }
        };

        if (isOpen) {
            window.addEventListener('click', checkForOutsideClick);
            window.addEventListener('keydown', onKeyDown);
        } else {
            window.removeEventListener('click', checkForOutsideClick);
            window.removeEventListener('keydown', onKeyDown);
        }

        return () => {
            window.removeEventListener('click', checkForOutsideClick);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [anchorElement, isOpen, isReady, onClose]);

    if (!isOpen || props.sections.length <= 0) {
        return null;
    }

    return (
        <div
            ref={menuBoxElementRef}
            className={classes.menuBox}
            style={{ ...positionRect.current, visibility: isReady ? 'visible' : 'hidden', minWidth: `${anchorElementWidth.current ?? 100}px` }}
        >
            <ul className={classes.menu}>
                {props.sections.map((section, sectionIndex) => {
                    const sectionItems = section.menuItems;

                    return sectionItems.map((menuItem, itemIndex) => (
                        <li key={`${sectionIndex}_${itemIndex}`} className={itemIndex + 1 < sectionItems.length ? '' : classes.lastSectionItem}>
                            {menuItem.href ? (
                                <Link
                                    to={menuItem.href}
                                    className={classes.menuItem}
                                    target={menuItem.hrefTarget ?? '_self'}
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    {menuItem.iconName && (
                                        <span className={`text-dark-gray me-100 ${menuItem.isSubItem ? 'ms-100' : ''}`}>
                                            <i className={`fa-solid ${menuItem.iconName}`}></i>
                                        </span>
                                    )}
                                    <span className={menuItem.isSubItem && !menuItem.iconName ? 'ms-150' : ''}>{menuItem.text}</span>
                                </Link>
                            ) : menuItem.onClick ? (
                                <button
                                    className={classes.menuItem}
                                    onClick={() => {
                                        onClose();
                                        menuItem.onClick?.();
                                    }}
                                >
                                    {menuItem.iconName && (
                                        <span className={`text-dark-gray me-100 ${menuItem.isSubItem ? 'ms-100' : ''}`}>
                                            <i className={`fa-solid ${menuItem.iconName}`}></i>
                                        </span>
                                    )}
                                    <span className={menuItem.isSubItem && !menuItem.iconName ? 'ms-150' : ''}>{menuItem.text}</span>
                                </button>
                            ) : (
                                <span className={classes.menuItem}>
                                    {menuItem.iconName && (
                                        <span className={`text-dark-gray me-100 ${menuItem.isSubItem ? 'ms-100' : ''}`}>
                                            <i className={`fa-solid ${menuItem.iconName}`}></i>
                                        </span>
                                    )}
                                    <span className={menuItem.isSubItem && !menuItem.iconName ? 'ms-150' : ''}>{menuItem.text}</span>
                                </span>
                            )}
                        </li>
                    ));
                })}
            </ul>
        </div>
    );
}

export default Menu;
