import React, { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, FetchPageData } from '../../Classes/Utilities';
import { RatedItemInfo } from '../../Types/RatedItem';
import { User } from '../../Classes/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';
import Preloader from '../../Components/Preloader/Preloader';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Tabs, { TabItem } from '../../Components/Tabs/Tabs';
import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import ToggleSelection from '../../Components/ToggleSelection/ToggleSelection';
import Score from '../../Components/Score/Score';
import LiveDate from '../../Components/LiveDate/LiveDate';

import classes from './UserRatedItems.module.css';

function UserRatedItemsPage(props: any) {
    const [searchParams] = useSearchParams();
    const { userSessionData } = useUserSessionData();
    const { updatePageMetdata } = usePageMetdata();

    const userID = searchParams.get('uid') ?? userSessionData.userInfo?.id;
    const orderBy = searchParams.get('order') ?? 'date';
    const itemType = searchParams.get('type') ?? 'all';

    const [user, setUser] = useState<User>(new User());
    const [recentlyRated, setRecentlyRated] = useState<RatedItemInfo[]>([]);
    const [recentlyRatedOffset, setRecentlyRatedOffset] = useState(0);
    const [isMoreToScroll, setIsMoreToScroll] = useState(true);

    const isLoading = useRef<boolean>();
    const numOfRatedItemsChunk = useRef(25);
    const numOfRatedItems = recentlyRated.length;

    useEffect(() => {
        const handlePageScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;

            if (isMoreToScroll && scrollPos / document.body.clientHeight > 0.8) {
                setRecentlyRatedOffset(numOfRatedItems);
                numOfRatedItemsChunk.current = 50;
            }
        };

        window.addEventListener('scroll', handlePageScroll);
        return () => window.removeEventListener('scroll', handlePageScroll);
    }, [numOfRatedItems, isMoreToScroll]);

    useEffect(() => {
        setRecentlyRatedOffset(0);
    }, [itemType, orderBy]);

    useEffect(() => {
        if (!isLoading.current && userID) {
            isLoading.current = true;

            if (recentlyRatedOffset === 0) {
                setRecentlyRated([]);
            }

            const type = itemType === 'all' ? itemType : itemType.substring(0, itemType.length - 1);

            FetchPageData(
                `${BELLINO_BASE_URL}/api/pages/userratings?id=${userID}&itemType=${type}&order=${orderBy}&offset=${recentlyRatedOffset}`
            ).then((response: any) => {
                if (response.success) {
                    if (recentlyRatedOffset > 0) {
                        setRecentlyRated(oldArray => [...oldArray, ...response.result?.recentlyRated]);
                    } else {
                        const user = new User(response.result?.userInfo);

                        updatePageMetdata({
                            title: `${user.fullName} - Rated Items`,
                            description: `View ${user.fullName}'s music profile on Bellino. Join Bellino to know music lovers like ${user.fullName} from all over the world`
                        });

                        setUser(user);
                        setRecentlyRated(response.result?.recentlyRated);
                    }

                    if (response.result?.recentlyRated?.length === 0) {
                        setIsMoreToScroll(false);
                    }
                } else {
                    setUser(new User());
                }

                setTimeout(() => {
                    isLoading.current = false;
                }, 100);
            });
        }
    }, [userID, itemType, orderBy, recentlyRatedOffset, updatePageMetdata]);

    if (!userID || (!user.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    const ratedItemsAsListItems = recentlyRated.map(item => {
        return {
            titleStr: item.name,
            title: (
                <Link
                    to={
                        item.type === 'Track'
                            ? `/track?tid=${item.id}`
                            : item.type === 'Album'
                              ? `/album?aid=${item.id}`
                              : `/artist?aid=${item.id}`
                    }
                >
                    {item.name}
                </Link>
            ),
            subTitle: item?.artists?.map((artist, index) => (
                <span key={index}>
                    <Link to={`/artist?aid=${artist.id}`}>{artist.name}</Link>
                    {index < item.artists.length - 1 ? <span>, </span> : <></>}
                </span>
            )),
            subInfo: <LiveDate utcDate={item.date}></LiveDate>,
            image: item.imgPath,
            imageHref: `/track?tid=${item.id}`,
            endSection: (
                <div className="me-75">
                    <Score score={item.rating}></Score>
                </div>
            )
        } as GenericListItem;
    });

    const typeTabItems: TabItem[] = [
        {
            text: 'All',
            href: `/ratings?uid=${userID}&type=all&order=${orderBy}`,
            isSelected: itemType === 'all'
        },
        {
            text: 'Artists',
            href: `/ratings?uid=${userID}&type=artists&order=${orderBy}`,
            isSelected: itemType === 'artists'
        },
        {
            text: 'Albums',
            href: `/ratings?uid=${userID}&type=albums&order=${orderBy}`,
            isSelected: itemType === 'albums'
        },
        {
            text: 'Tracks',
            href: `/ratings?uid=${userID}&type=tracks&order=${orderBy}`,
            isSelected: itemType === 'tracks'
        }
    ];

    return (
        <AppMainContainer showPreloader={!user.isInit}>
            <Section backgroundColor="white">
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[{ text: user.fullName, href: `/user?uid=${user.id}` }, { text: 'Rated Items' }]}
                    ></Breadcrumbs>
                </Title>
                <div className={`mb-100 ${classes.filters}`}>
                    <div>
                        <Tabs tabsItems={typeTabItems} viewMode="buttons"></Tabs>
                    </div>
                    <div>
                        <ToggleSelection
                            selectedValue={orderBy === 'rating' ? 'By Rating' : 'By Date'}
                            optionL={{
                                href: `/ratings?uid=${user.id}&type=${itemType}&order=date`,
                                value: 'By Date'
                            }}
                            optionR={{ href: `/ratings?uid=${user.id}&type=${itemType}&order=rating`, value: 'By Rating' }}
                        ></ToggleSelection>
                    </div>
                </div>
                <GenericList items={ratedItemsAsListItems} spaceBetweenItems={false}></GenericList>
                {isMoreToScroll && isLoading.current !== false && <Preloader viewMode="Inline"></Preloader>}
            </Section>
        </AppMainContainer>
    );
}

export default UserRatedItemsPage;
