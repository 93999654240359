import React, { useRef, useState } from 'react';

import { xPosition } from '../../Types/Position';

import Menu, { MenuSection } from '../Menu/Menu';

import classes from './MenuButton.module.css';

export type MenuButtonView = 'white-circle' | 'transparent' | 'custom';

export type MenuButtonProps = {
    buttonSize?: 'small' | 'medium' | 'large';
    buttonView?: MenuButtonView;
    buttonChildren?: React.ReactNode;
    buttonCustomClass?: string;
    buttonAriaLabel?: string;
    containerCustomClass?: string;
    menuXPosition?: xPosition;
    menuSections: MenuSection[];
    parentType?: 'relative' | 'fixed';
};

function MenuButton(props: MenuButtonProps) {
    const {
        buttonSize,
        buttonView,
        buttonChildren,
        buttonCustomClass,
        buttonAriaLabel,
        containerCustomClass,
        menuXPosition,
        menuSections,
        parentType
    } = props;

    const menuBtnSizeClass = buttonSize ? classes[`${buttonSize}MenuBtn`] : classes.mediumMenuBtn;
    const menuBtnViewClass =
        buttonView === 'transparent' ? classes.transparentMenuBtn : buttonView === 'white-circle' ? classes.whiteMenuBtn : '';

    const menuButtonElementRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className={`${containerCustomClass ?? (parentType !== 'fixed' ? classes.menuButton : classes.menuButtonFixed)}`}>
            <button
                ref={menuButtonElementRef}
                className={`${buttonCustomClass ?? `${menuBtnViewClass} ${menuBtnSizeClass}`}`}
                aria-label={buttonAriaLabel ?? 'Menu'}
                onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                }}
            >
                {buttonChildren ? buttonChildren : <i className="fa fa-ellipsis-v"></i>}
            </button>
            <Menu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                anchorElement={menuButtonElementRef.current ? (menuButtonElementRef.current as HTMLButtonElement) : undefined}
                sections={menuSections}
                xPosition={menuXPosition}
                parentType={parentType}
            ></Menu>
        </div>
    );
}

export default MenuButton;
