import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ChatData, ChatInfo, Message } from '../../Types/Chat';
import { getImageFullPath, htmlToText } from '../../Classes/Utilities';
import { getUserChats, getChatData } from '../../Services/Messages';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import Button from '../../Components/Button/Button';
import UserAvatar from '../../Components/UserAvatar/UserAvatar';
import Chat from '../../Components/Chat/Chat';
import MenuButton from '../../Components/MenuButton/MenuButton';
import LiveDate from '../../Components/LiveDate/LiveDate';
import Preloader from '../../Components/Preloader/Preloader';

import NewMessageDialog from '../_partials/NewMessageDialog';

import classes from './UsersMessages.module.css';

function UsersMessagesPage(props: any) {
    usePageMetdata({ title: 'Messages' });

    const [searchParams] = useSearchParams();
    const friendID = searchParams.get('fid');

    const { userSessionData } = useUserSessionData();

    const [userChats, setUserChats] = useState<ChatInfo[]>([]);
    const [chatData, setChatData] = useState<ChatData>();
    const [filteredChatData, setFilteredChatData] = useState<ChatData>();

    const [isNewMessageDialogOpen, setIsNewMessageDialogOpen] = useState(false);

    const isLoadingChats = useRef<boolean>();
    const isLoadingSelectedChat = useRef<boolean>();

    const selectedChatId = friendID ? friendID : userChats.length > 0 ? userChats[0].id : undefined;

    useEffect(() => {
        if (!isLoadingChats.current) {
            isLoadingChats.current = true;

            getUserChats().then(chatsInfo => {
                setUserChats(chatsInfo);
                isLoadingChats.current = false;
            });
        }
    }, []);

    useEffect(() => {
        if (!isLoadingSelectedChat.current && selectedChatId) {
            isLoadingSelectedChat.current = true;
            setChatData(undefined);

            getChatData(selectedChatId).then(chatData => {
                setChatData(chatData);
                isLoadingSelectedChat.current = false;
            });
        }
    }, [selectedChatId]);

    const onNewMessage = async (newMessage: Message) => {
        if (newMessage) {
            setFilteredChatData(undefined);
            setChatData(prev => {
                return {
                    participants: prev?.participants,
                    messages: prev?.messages
                        ? [...prev.messages.filter(m => m.id.toString() !== newMessage.id.toString()), newMessage]
                        : [newMessage]
                } as ChatData;
            });
        }
    };

    const chatsAsListItems = useMemo(
        () =>
            userChats.map(chat => {
                return {
                    titleStr: chat.title,
                    title: chat.title,
                    subTitle: (
                        <div className={classes.lastMessagePreview}>
                            {htmlToText(chat.lastMessage.replaceAll('<br/>', '\n').replaceAll('<br>', '\n').replaceAll('\n', ' '))}
                        </div>
                    ),
                    subInfo: <LiveDate utcDate={chat.lastMessageDate}></LiveDate>,
                    image: getImageFullPath(chat.imgPath),
                    imageShape: 'circle',
                    href: `/messages?fid=${chat.id}`,
                    isSelectedItem: chat.id.toString() === selectedChatId?.toString()
                } as GenericListItem;
            }),
        [selectedChatId, userChats]
    );

    const chatParticipant = useMemo(
        () => chatData?.participants?.find(p => p.id.toString() === selectedChatId?.toString()),
        [chatData?.participants, selectedChatId]
    );

    if (userSessionData.isReady && !userSessionData.userInfo?.id /* || (!chatParticipant && isLoadingSelectedChat.current === false)*/) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={isLoadingChats.current !== false}>
            <Section>
                <div className={classes.chatsAndMessages}>
                    <div>
                        <div className="mt-100 mb-100">
                            <Button
                                viewMode="classic"
                                onClick={() => {
                                    setIsNewMessageDialogOpen(true);
                                }}
                            >
                                + New Message
                            </Button>
                        </div>
                        <div className={classes.conversionsList}>
                            <GenericList items={chatsAsListItems} spaceBetweenItems={true}></GenericList>
                        </div>
                    </div>
                    <div>
                        <Title>
                            <div className={classes.messagesHeader}>
                                {chatParticipant && <UserAvatar avatarSize="tiny" viewMode="inline" userInfo={chatParticipant}></UserAvatar>}
                                <MenuButton
                                    buttonSize="small"
                                    menuSections={[
                                        {
                                            menuItems: [
                                                {
                                                    text: filteredChatData ? 'Show All Messages' : 'Show Only Recommendations',
                                                    onClick: () => {
                                                        if (filteredChatData) {
                                                            setFilteredChatData(undefined);
                                                        } else if (chatData) {
                                                            setFilteredChatData({
                                                                participants: chatData.participants,
                                                                messages: chatData.messages.filter(m => m.type === 'RC')
                                                            });
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                ></MenuButton>
                            </div>
                        </Title>
                        <div className={classes.messagesList}>
                            {chatData ? (
                                <Chat chatData={filteredChatData ?? chatData} isMessageInputVisible={true} onNewMessage={onNewMessage}></Chat>
                            ) : (
                                <Preloader viewMode="Inline"></Preloader>
                            )}
                        </div>
                    </div>
                </div>
            </Section>
            <NewMessageDialog
                isVisible={isNewMessageDialogOpen}
                onCloseDialog={() => {
                    setIsNewMessageDialogOpen(false);
                }}
            ></NewMessageDialog>
        </AppMainContainer>
    );
}

export default UsersMessagesPage;
