import React from 'react';

import Dialog from '../../../Containers/Dialog/Dialog';
import Link from '../../Link/Link';
import Button from '../../Button/Button';

import classes from './ListenerGuestDialog.module.css';

export type ListenerGuestDialogProps = {
    isVisible: boolean;
    onCloseDialog: () => void;
};

function ListenerGuestDialog(props: ListenerGuestDialogProps) {
    const { isVisible, onCloseDialog } = props;

    return (
        <Dialog isVisible={isVisible} title={'Hello Guest'} onCloseDialog={onCloseDialog}>
            <div className="me-75">
                <div className="fw-500 mb-150">Enjoy our website?</div>
                <div>Please sign up for FREE and help us improve our music recommendations for you!</div>
            </div>
            <div className={classes.footer}>
                <Link viewMode={'Button'} href="/">
                    Sign In
                </Link>
                <Button viewMode="fancy" btnColor="gray" onClick={onCloseDialog}>
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
}

export default ListenerGuestDialog;
