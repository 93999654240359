import React, { useRef } from 'react';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import { sendFeedback } from '../../../Services/Common';

export function FeedbackDialog(props: { isVisible: boolean; onCloseDialog: () => void }) {
    const { isVisible, onCloseDialog } = props;
    const textboxElementRef = useRef(null);
    const selectElementRef = useRef(null);

    const onSaveFeedback = () => {
        const selectElement = selectElementRef?.current ? (selectElementRef.current as HTMLSelectElement) : null;
        const textboxElement = textboxElementRef?.current ? (textboxElementRef.current as HTMLTextAreaElement) : null;

        if (textboxElement?.value && textboxElement.value.trim().length > 0) {
            sendFeedback(selectElement?.value ?? '', textboxElement.value);
            onCloseDialog();
            textboxElement.value = '';
        } else {
            textboxElement?.focus();
        }
    };

    return (
        <Dialog isVisible={isVisible} title="Send Feedback" onCloseDialog={onCloseDialog}>
            <p className="mb-100">Feel free to write suggestions, problem reports, feature ideas and general comments</p>
            <select ref={selectElementRef} className="mb-100">
                <option>General Feedback</option>
                <option>Problem Report</option>
                <option>Feature Idea</option>
            </select>
            <textarea ref={textboxElementRef} placeholder="Your Description ..." style={{ height: '8rem' }}></textarea>
            <Button viewMode="fancy" className="mt-100" onClick={onSaveFeedback}>
                Send
            </Button>
        </Dialog>
    );
}
