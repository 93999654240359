import React, { useRef, useState } from 'react';

import useUserSessionData from '../../hooks/useUserSessionData';

import GuestDialog from './GuestDialog';
import Dialog from '../../Containers/Dialog/Dialog';
import Button from '../../Components/Button/Button';
import { MultiSearchSelection, SearchItemInfo } from '../../Components/MultiSearchSelection/MultiSearchSelection';

import { searchAlbumTracks, searchArtistTracks } from '../../Services/Tracks';
import { searchUserFriends } from '../../Services/User';
import { sendRecommendationMessage } from '../../Services/Messages';

import classes from './RecommendDialog.module.css';

export type RecommendDialogProps = {
    itemType: 'Artist' | 'Album' | 'Track';
    itemId: string;
    isVisible: boolean;
    onCloseDialog: () => void;
};

function RecommendDialog(props: RecommendDialogProps) {
    const { itemType, itemId, isVisible, onCloseDialog } = props;

    const { userSessionData, friendsIds } = useUserSessionData();

    const [friendsList, setFriendsList] = useState<SearchItemInfo[]>([]);
    const [tracksList, setTracksList] = useState<SearchItemInfo[]>([]);
    const [resetSearch, setResetSearch] = useState(false);

    const messageTextareaElementRef = useRef(null);

    const onSendRecommendation = () => {
        const messageTextareaElement = messageTextareaElementRef?.current ? (messageTextareaElementRef.current as HTMLTextAreaElement) : null;

        if (friendsList.length > 0) {
            const recommendationMsg = messageTextareaElement?.value?.trim() ?? '';
            const specifiedTracks = tracksList.length > 0 ? tracksList.map(t => t.id).join(',') : '';

            friendsList.forEach(friend => {
                sendRecommendationMessage(friend.id, itemType, itemId, specifiedTracks, recommendationMsg);
            });

            onCloseDialog();
            setResetSearch(true);
            setTimeout(() => {
                setResetSearch(false);
            }, 100);
        }
    };

    if (!userSessionData.userInfo?.id) {
        return (
            <GuestDialog
                isVisible={isVisible}
                userActionDescription={`send ${itemType.toLocaleLowerCase()}s recommendations`}
                onCloseDialog={onCloseDialog}
            ></GuestDialog>
        );
    }

    return (
        <Dialog isVisible={isVisible} title={`Recommend ${itemType} To Friends`} onCloseDialog={onCloseDialog}>
            <div className="mb-75">
                <MultiSearchSelection
                    placeholder="Friend Name"
                    width="100%"
                    reset={resetSearch}
                    selectedItems={friendsList}
                    onSelectionChange={selectedFriends => {
                        setFriendsList(selectedFriends);
                    }}
                    onSearch={serachText => {
                        return searchUserFriends(serachText, friendsIds);
                    }}
                ></MultiSearchSelection>
            </div>
            {itemType !== 'Track' && (
                <div className="mb-75">
                    <MultiSearchSelection
                        placeholder="Track Name"
                        width="100%"
                        reset={resetSearch}
                        selectedItems={tracksList}
                        onSelectionChange={selectedTracks => {
                            setTracksList(selectedTracks);
                        }}
                        onSearch={serachText => {
                            if (itemType === 'Artist') {
                                return searchArtistTracks(serachText, itemId);
                            }

                            return searchAlbumTracks(serachText, itemId);
                        }}
                    ></MultiSearchSelection>
                </div>
            )}
            <textarea ref={messageTextareaElementRef} className={classes.recommendInput} placeholder="Message" rows={4}></textarea>
            <div className={classes.footer}>
                <Button viewMode="fancy" isDisabled={friendsList.length === 0} onClick={onSendRecommendation}>
                    Send
                </Button>
            </div>
        </Dialog>
    );
}

export default RecommendDialog;
