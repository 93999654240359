import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserSessionData } from '../Types/User';
import { Message } from '../Types/Chat';

export type UserState = {
    userSessionData: UserSessionData;
    notifications: Message[];
    friendsIds: string[];
    friendsRequestsIds: string[];
};

export type setUserSessionAction = { type: 'SET_USER_SESSION_DATA'; payload: UserSessionData };
export type resetUserSessionAndLogoutAction = { type: 'RESET_USER_SESSION_AND_LOGOUT' };
export type setUserNotificationsAction = { type: 'SET_USER_NOTIFICATIONS'; payload: Message[] };
export type removeUserNotificationsAction = { type: 'REMOVE_USER_NOTIFICATIONS'; payload: string[] };
export type setUserFriendsIdsAction = { type: 'SET_USER_FRIENDS_IDS'; payload: string[] };
export type removeUserFriendAction = { type: 'REMOVE_USER_FRIEND'; payload: string };
export type setUserFriendsRequestsIdsAction = { type: 'SET_USER_FRIENDS_REQUESTS_IDS'; payload: string[] };
export type addUserFriendRequestAction = { type: 'ADD_USER_FRIEND_REQUEST'; payload: string };
export type removeUserFriendRequestAction = { type: 'REMOVE_USER_FRIEND_REQUEST'; payload: string };

export type UserAction =
    | setUserSessionAction
    | resetUserSessionAndLogoutAction
    | setUserNotificationsAction
    | removeUserNotificationsAction
    | setUserFriendsIdsAction
    | removeUserFriendAction
    | setUserFriendsRequestsIdsAction
    | addUserFriendRequestAction
    | removeUserFriendRequestAction;

const defaultUserState: UserState = {
    userSessionData: { isReady: false },
    notifications: [],
    friendsIds: [],
    friendsRequestsIds: []
};

const userSlice = createSlice({
    name: 'user',
    initialState: defaultUserState,
    reducers: {
        setUserSessionData(state, action: PayloadAction<UserSessionData>) {
            state.userSessionData = action.payload;
        },
        resetUserSessionAndLogout(state) {
            state.userSessionData = { isReady: true };
            state.notifications = [];
            state.friendsIds = [];
            state.friendsRequestsIds = [];
        },
        /* Notifications */
        setUserNotifications(state, action: PayloadAction<Message[]>) {
            state.notifications = action.payload;
        },
        removeUserNotification(state, action: PayloadAction<string[]>) {
            state.notifications = state.notifications.filter(n => !action.payload.includes(n.id));
        },
        /* Friends */
        setUserFriendsIds(state, action: PayloadAction<string[]>) {
            state.friendsIds = action.payload;
        },
        removeUserFriend(state, action: PayloadAction<string>) {
            state.friendsIds = state.friendsIds.filter(fid => fid !== action.payload);
        },
        setUserFriendsRequestsIds(state, action: PayloadAction<string[]>) {
            state.friendsRequestsIds = action.payload;
        },
        addUserFriendRequest(state, action: PayloadAction<string>) {
            if (!state.friendsRequestsIds.includes(action.payload)) {
                state.friendsRequestsIds.push(action.payload);
            }
        },
        removeUserFriendRequest(state, action: PayloadAction<string>) {
            state.friendsRequestsIds = state.friendsRequestsIds.filter(fid => fid !== action.payload);
        }
    }
});

export const {
    setUserSessionData,
    setUserNotifications,
    resetUserSessionAndLogout,
    removeUserNotification,
    setUserFriendsIds,
    removeUserFriend,
    setUserFriendsRequestsIds,
    addUserFriendRequest,
    removeUserFriendRequest
} = userSlice.actions;

export default userSlice.reducer;
