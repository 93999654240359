import React from 'react';
import classes from './Title.module.css';

export type TitleProps = {
    centered?: boolean;
    level?: 1 | 2 | 3 | 4;
    children?: React.ReactNode;
};

function Title(props: TitleProps) {
    const { centered, level = 4, children } = props;
    const TitleTag = `h${level}` as keyof JSX.IntrinsicElements;

    return <TitleTag className={`${classes.title} ${classes[`title${level}`]} ${centered ? classes.centered : ''}`}>{children}</TitleTag>;
}

export default Title;
