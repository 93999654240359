import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../redux/store';
import {
    resetUserSessionAndLogout,
    setUserFriendsIds,
    setUserFriendsRequestsIds,
    setUserNotifications,
    setUserSessionData
} from '../redux/userSlice';

import { UserSessionData } from '../Types/User';
import { getUserFriendsIDS, getUserNotifications, getUserSessionInfo, logoutUser } from '../Services/User';

const setupUserSessionInterval = (name: string, callback: () => void, intervalMs: number) => {
    const windowObj = window as any;

    if (!windowObj[name]) {
        windowObj[name] = setInterval(callback, intervalMs);
        callback();
    }
};

const cleanUserSessionInterval = (name: string) => {
    const windowObj = window as any;

    if (windowObj[name]) {
        clearInterval(windowObj[name]);
        windowObj[name] = undefined;
    }
};

function useUserSessionData() {
    const dispatch = useDispatch<AppDispatch>();

    const { userSessionData, notifications, friendsIds, friendsRequestsIds } = useSelector((state: RootState) => state.user);

    const updateUserSessionInfo = useCallback(async () => {
        const userSessionInfo = await getUserSessionInfo();
        userSessionInfo && dispatch(setUserSessionData(userSessionInfo));
    }, [dispatch]);

    const updateUserNotifications = useCallback(async () => {
        if (document.hasFocus()) {
            const userNotifications = await getUserNotifications();
            dispatch(setUserNotifications(userNotifications));
        }
    }, [dispatch]);

    const updateUserFriendsIds = useCallback(async () => {
        const userFriends = await getUserFriendsIDS();

        if (userFriends.friendsIds.length > 0) {
            dispatch(setUserFriendsIds(userFriends.friendsIds.map((f: any) => f.toString())));
        }

        if (userFriends.friendsRequestsIds.length > 0) {
            dispatch(setUserFriendsRequestsIds(userFriends.friendsRequestsIds.map((f: any) => f.toString())));
        }
    }, [dispatch]);

    useEffect(() => {
        setupUserSessionInterval('userSessionTimerInterval', updateUserSessionInfo, 300000);
    }, [updateUserSessionInfo]);

    useEffect(() => {
        if (userSessionData.isReady && userSessionData.sessionId) {
            setupUserSessionInterval('userFriendsTimerInterval', updateUserFriendsIds, 300000);
            setupUserSessionInterval('userNotificationsTimerInterval', updateUserNotifications, 30000);
        }
    }, [userSessionData.isReady, userSessionData.sessionId, updateUserFriendsIds, updateUserNotifications]);

    const updateUserSessionData = async (userSessionData: UserSessionData) => {
        dispatch(setUserSessionData(userSessionData));
    };

    const logout = async () => {
        await logoutUser();
        dispatch(resetUserSessionAndLogout());
        cleanUserSessionInterval('userSessionTimerInterval');
        cleanUserSessionInterval('userNotificationsTimerInterval');
        cleanUserSessionInterval('userFriendsTimerInterval');
    };

    return { notifications, userSessionData, friendsIds, friendsRequestsIds, updateUserSessionData, logout };
}

export default useUserSessionData;
