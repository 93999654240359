import React from 'react';
import classes from './Header.module.css';

export type HeaderProps = {
    startSection: any;
    middleSection?: any;
    endSection?: any;
};

const Header = (props: HeaderProps) => {
    return (
        <header className={classes.header}>
            <span className={classes.section}>{props.startSection}</span>
            <span className={classes.section}>{props.middleSection}</span>
            <span className={classes.section}>{props.endSection}</span>
        </header>
    );
};

export default Header;
