import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { setActiveDialog } from '../../redux/uiSlice';

import { PhotoInfo } from '../../Types/Photo';
import { BELLINO_BASE_URL, FetchPageData, getImageFullPath } from '../../Classes/Utilities';
import { addUserPhoto, deleteUserPhoto, resetUserProfilePhoto, updateUserProfilePhoto } from '../../Services/User';
import { addItemPhoto } from '../../Services/Common';

import NotFound from '../NotFound/NotFound';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Photo from '../../Components/Photo/Photo';
import Breadcrumbs, { BreadcrumbsItem } from '../../Components/Breadcrumbs/Breadcrumbs';
import SingleFileUploader from '../../Components/FileUploader/FileUploader';
import ToggleSelection from '../../Components/ToggleSelection/ToggleSelection';
import Preloader from '../../Components/Preloader/Preloader';

import classes from './Photos.module.css';

function PhotosPage(props: any) {
    const { updatePageMetdata } = usePageMetdata();
    const [searchParams] = useSearchParams();

    const itemType = searchParams.get('type')?.toLocaleLowerCase?.();
    const itemID = searchParams.get('id');
    const orderBy = searchParams.get('order')?.toLocaleLowerCase?.() ?? 'popular';

    const dispatch = useDispatch<AppDispatch>();
    const { userSessionData, updateUserSessionData } = useUserSessionData();
    const allowUploadPhotos = userSessionData.userInfo?.id && (itemType !== 'user' || itemID === userSessionData.userInfo?.id);
    const isMyProfilePhotos = itemType === 'user' && userSessionData.userInfo?.id === itemID;

    const isLoading = useRef<boolean>();

    const [isLoaded, setIsLoaded] = useState(false);
    const [itemName, setItemName] = useState('');
    const [itemPhotos, setItemPhotos] = useState<PhotoInfo[]>([]);

    useEffect(() => {
        if (!isLoading.current && itemType && itemID) {
            isLoading.current = true;
            setIsLoaded(false);
            setItemPhotos([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/photos?id=${itemID}&type=${itemType}&order=${orderBy}`).then((response: any) => {
                if (response.success && response.result) {
                    const itemName = response.result.itemInfo?.name ?? '';

                    updatePageMetdata({
                        title: `${itemName} - Photos`,
                        imageURL: response.result.photos?.[0]?.src
                    });

                    setItemName(itemName);
                    setItemPhotos(
                        response.result.photos?.map((p: any) => {
                            return { ...p, src: getImageFullPath(p.src) };
                        }) ?? []
                    );
                } else {
                    setItemPhotos([]);
                }

                isLoading.current = false;
                setIsLoaded(true);
            });
        }
    }, [itemID, itemType, orderBy, updatePageMetdata]);

    if (!itemType || !itemID || (!itemName && isLoaded)) {
        return <NotFound></NotFound>;
    }

    let rootBreadcrumbsItem = null;
    let itemLink = '';
    let itemTitle = '';

    if (itemType === 'user') {
        itemTitle = itemName;
        itemLink = `/user?uid=${itemID}`;
    } else if (itemType === 'artist') {
        itemTitle = itemName;
        itemLink = `/artist?aid=${itemID}`;
    } else if (itemType === 'album') {
        itemTitle = itemName;
        itemLink = `/album?aid=${itemID}`;
    }

    const photoItemType =
        itemType === 'artist'
            ? 'Artist'
            : itemType === 'album'
              ? 'Album'
              : itemType === 'track'
                ? 'Track'
                : itemType === 'user'
                  ? 'User'
                  : undefined;

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={!itemName}>
            <Section>
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={
                            rootBreadcrumbsItem
                                ? [rootBreadcrumbsItem as BreadcrumbsItem, { text: itemTitle, href: itemLink }, { text: 'Photos' }]
                                : [{ text: itemTitle, href: itemLink }, { text: 'Photos' }]
                        }
                    ></Breadcrumbs>
                </Title>
                <div className={itemPhotos.length > 1 || allowUploadPhotos ? classes.toolbar : undefined}>
                    {photoItemType !== 'User' && itemPhotos.length > 1 && (
                        <div>
                            <ToggleSelection
                                selectedValue={orderBy === 'popular' ? 'By Popularity' : 'By Date'}
                                optionL={{
                                    href: `/photos?id=${itemID}&type=${itemType}&order=popular`,
                                    value: 'By Popularity'
                                }}
                                optionR={{ href: `/photos?id=${itemID}&type=${itemType}&order=date`, value: 'By Date' }}
                            ></ToggleSelection>
                        </div>
                    )}
                    {allowUploadPhotos && (
                        <SingleFileUploader
                            buttonText="Upload Photo"
                            uploadType="image/*"
                            buttonViewMode="fancy"
                            itemType={photoItemType}
                            itemId={itemID}
                            onFileUploaded={async photoPath => {
                                if (itemID && photoItemType && photoPath) {
                                    const photoData =
                                        photoItemType === 'User'
                                            ? await addUserPhoto(photoPath)
                                            : await addItemPhoto(photoItemType, itemID, photoPath);

                                    if (photoData?.ID) {
                                        setItemPhotos(prev => [
                                            ...prev,
                                            { id: photoData.ID, src: photoData.Path, width: photoData.Width, height: photoData.Height }
                                        ]);
                                    }
                                }
                            }}
                            onFileUploadError={() => {
                                dispatch(setActiveDialog({ activeDialog: 'UPLOAD_ERROR' }));
                            }}
                        ></SingleFileUploader>
                    )}
                </div>
                {itemPhotos.length > 0 ? (
                    <div className={classes.photosGrid}>
                        {itemPhotos.map((photo, index) => (
                            <Photo
                                key={index}
                                id={photo.id}
                                src={photo.src}
                                alt={itemTitle}
                                width={photo.width}
                                height={photo.height}
                                showMenuEditButton={isMyProfilePhotos}
                                viewSize="medium"
                                onPhotoClick={() => {}}
                                OnSetAsProfileImage={async () => {
                                    if (photo.id && photoItemType === 'User') {
                                        const isSuccess = await updateUserProfilePhoto(photo.id);

                                        if (isSuccess && userSessionData.userInfo) {
                                            updateUserSessionData({
                                                ...userSessionData,
                                                userInfo: {
                                                    ...userSessionData.userInfo,
                                                    imgPath: photo.src
                                                }
                                            });
                                        }
                                    }
                                }}
                                OnDelete={async () => {
                                    if (photo.id) {
                                        setItemPhotos(prevPhotos => prevPhotos?.filter(p => p.id !== photo.id));

                                        if (photoItemType === 'User') {
                                            let isSuccess = await deleteUserPhoto(photo.id);

                                            if (isSuccess && userSessionData.userInfo?.imgPath === photo.src) {
                                                // Current profile photo deleted
                                                isSuccess = await resetUserProfilePhoto();

                                                if (isSuccess && userSessionData.userInfo) {
                                                    updateUserSessionData({
                                                        ...userSessionData,
                                                        userInfo: {
                                                            ...userSessionData.userInfo,
                                                            imgPath: undefined
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }}
                            ></Photo>
                        ))}
                    </div>
                ) : !isLoaded ? (
                    <Preloader viewMode="Inline"></Preloader>
                ) : (
                    <div>No available photos</div>
                )}
            </Section>
        </AppMainContainer>
    );
}

export default PhotosPage;
