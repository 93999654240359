import React, { useMemo, useRef } from 'react';

import useUserSessionData from '../../../hooks/useUserSessionData';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import Link from '../../../Components/Link/Link';
import GuestDialog from '../../_partials/GuestDialog';

import classes from './AddVideoDialog.module.css';

function AddLyricsDialog(props: { trackFullName: string; isVisible: boolean; onAdd: (lyrics: string) => void; onCloseDialog: () => void }) {
    const { trackFullName, isVisible, onAdd, onCloseDialog } = props;

    const { userSessionData } = useUserSessionData();

    const textareaElementRef = useRef(null);
    const warningElementRef = useRef(null);

    const onAddLyrics = () => {
        const textareaElement = textareaElementRef?.current ? (textareaElementRef.current as HTMLTextAreaElement) : null;

        if (textareaElement?.value && textareaElement.value.trim().length > 0) {
            onAdd(textareaElement.value.trim());
            onCloseDialog();
        } else {
            textareaElement?.focus();
        }
    };

    const googleLyricsSearchQuery = useMemo(
        () => `https://www.google.com/search?q=Lyrics+${encodeURIComponent(trackFullName)}`,
        [trackFullName]
    );

    if (!userSessionData.userInfo?.id) {
        return <GuestDialog isVisible={isVisible} userActionDescription={'upadte lyrics'} onCloseDialog={onCloseDialog}></GuestDialog>;
    }

    return (
        <Dialog isVisible={isVisible} title="Add Lyrics" onCloseDialog={onCloseDialog}>
            <p className="mb-100">To add lyrics, just enter the lyrics:</p>
            <textarea rows={5} ref={textareaElementRef} className={classes.videoInput} placeholder="Lyrics..."></textarea>
            <div ref={warningElementRef} className={classes.warning}>
                <b className="mb-50">Warning:</b>
                <p>
                    Please do not abuse this system! Adding unrelevant lyrics for this item may result in you being banned from adding lyrics, or
                    from the entire site.
                </p>
            </div>
            <div className={classes.footer}>
                <div>
                    <Link target="_blank" textColor="dark-blue" href={googleLyricsSearchQuery}>
                        Search lyrics on Google
                    </Link>
                </div>
                <div>
                    <Button viewMode="fancy" onClick={onAddLyrics}>
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default AddLyricsDialog;
