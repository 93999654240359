import { BELLINO_BASE_URL } from '../Classes/Utilities';
import { MusicItemType } from '../Types/MusicItemType';
import { TrackInfo } from '../Types/Track';
import { BasicVideoInfo } from '../Types/VideoInfo';

export const buildPlaylistByTag = async (tagName: string) => {
    let playlist: TrackInfo[] = [];

    if (tagName.trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&tag=${tagName}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    playlist = responseData.map((trackData: any) => {
                        return {
                            id: trackData.trackID,
                            name: trackData.trackName,
                            artists: trackData.artists ?? [],
                            videos: [
                                {
                                    duration: trackData.duration,
                                    externalId: trackData.videoID
                                } as BasicVideoInfo
                            ]
                        } as TrackInfo;
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return playlist;
};

export const buildPlaylistByTrackIds = async (trackIds: string[]) => {
    let playlist: TrackInfo[] = [];

    if (trackIds.length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&data=${trackIds.join(',')}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    playlist = responseData.map((trackData: any) => {
                        return {
                            id: trackData.trackID,
                            name: trackData.trackName,
                            artists: trackData.artists ?? [],
                            videos: [
                                {
                                    duration: trackData.duration,
                                    externalId: trackData.videoID
                                } as BasicVideoInfo
                            ]
                        } as TrackInfo;
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return playlist;
};

export const buildPlaylistBy = async (genres: string[] = [], minYear: number = -1, maxYear: number = -1, level?: number, country?: string) => {
    let playlist: TrackInfo[] = [];

    const genreStr = genres?.join('|')?.toLowerCase() ?? '';

    if (genreStr.trim().length > 0 || minYear > 0 || maxYear > 0 || level) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&genre=${genreStr}&minyear=${minYear}&maxyear=${maxYear}${country ? `&cntry=${country}` : ''}${level ? `&level=${level}` : ''}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    playlist = responseData.map((trackData: any) => {
                        return {
                            id: trackData.trackID,
                            name: trackData.trackName,
                            artists: trackData.artists ?? [],
                            videos: [
                                {
                                    duration: trackData.duration,
                                    externalId: trackData.videoID
                                } as BasicVideoInfo
                            ]
                        } as TrackInfo;
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return playlist;
};

export const buildPlaylistByItem = async (itemType: MusicItemType, itemID: string) => {
    let playlist: TrackInfo[] = [];

    if (itemType.trim().length > 0 && itemID.trim().length > 0) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist&type=${itemType.toLowerCase()}&itemID=${itemID}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    playlist = responseData.map((trackData: any) => {
                        return {
                            id: trackData.trackID,
                            name: trackData.trackName,
                            artists: trackData.artists ?? [],
                            videos: [
                                {
                                    duration: trackData.duration,
                                    externalId: trackData.videoID
                                } as BasicVideoInfo
                            ]
                        } as TrackInfo;
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return playlist;
};

export const buildPlaylistFromUserLibrary = async (userId: string) => {
    let playlist: TrackInfo[] = [];

    if (userId.trim().length > 0) {
        const formData = new FormData();
        formData.append('action', 'BuildPlaylist');
        formData.append('uid', userId);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=BuildPlaylist`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    playlist = responseData.map((trackData: any) => {
                        return {
                            id: trackData.trackID,
                            name: trackData.trackName,
                            artists: trackData.artists ?? [],
                            videos: [
                                {
                                    duration: trackData.duration,
                                    externalId: trackData.videoID
                                } as BasicVideoInfo
                            ]
                        } as TrackInfo;
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return playlist;
};
