import React, { useState } from 'react';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import { MultiSearchSelection, SearchItemInfo } from '../../../Components/MultiSearchSelection/MultiSearchSelection';
import { searchArtists } from '../../../Services/Artists';
import { addSimilarItem } from '../../../Services/Common';

function AddSimilarArtistsDialog(props: { artistID: string; isVisible: boolean; onCloseDialog: () => void }) {
    const { artistID, isVisible, onCloseDialog } = props;

    const [similarArtists, setSimilarArtists] = useState<SearchItemInfo[]>([]);
    const [resetSearch, setResetSearch] = useState(false);

    const onAddSimilarArtists = () => {
        if (similarArtists.length > 0) {
            //console.log('similarArtists = ', similarArtists);

            similarArtists.forEach(similarArtist => {
                addSimilarItem('Artist', artistID, similarArtist.id);
            });

            setSimilarArtists([]);
            onCloseDialog();
            setResetSearch(true);
            setTimeout(() => {
                setResetSearch(false);
            }, 100);
        }
    };

    return (
        <Dialog isVisible={isVisible} title="Add Similar Artists" onCloseDialog={onCloseDialog}>
            <MultiSearchSelection
                width="100%"
                placeholder="Type similar artist name..."
                reset={resetSearch}
                selectedItems={similarArtists}
                onSearch={serachText => {
                    return searchArtists(serachText, artistID);
                }}
                onSelectionChange={selectedSearchItems => {
                    setSimilarArtists(selectedSearchItems);
                }}
            ></MultiSearchSelection>
            <div className="mt-150">
                <Button viewMode="fancy" onClick={onAddSimilarArtists} isDisabled={similarArtists.length <= 0}>
                    Add
                </Button>
            </div>
        </Dialog>
    );
}

export default AddSimilarArtistsDialog;
