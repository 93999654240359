import React from 'react';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';

function TermsPage(props: any) {
    usePageMetdata({ title: 'Terms' });

    const { userSessionData } = useUserSessionData();

    const paragraphStyle = {
        fontSize: '1.2rem',
        lineHeight: '1.7em',
        letterSpacing: '0.5px',
        maxWidth: '1000px'
    };

    return (
        <AppMainContainer showPreloader={!userSessionData.isReady} backgroundColor="off-white">
            <Section>
                <Title level={1}>Terms Of Use</Title>
                <div className="text-gray fs-120 mb-75">Last updated: 04.05.2022</div>
                <p style={paragraphStyle}>
                    Please read the following agreement carefully. You must accept the terms of this agreement in order to use belllino.com
                </p>
            </Section>

            <Section>
                <Title>Acceptance of Terms</Title>
                <p style={paragraphStyle}>
                    belllino.com is provided free of charge to all users on an "AS IS" and "AS AVAILABLE" basis. We provide no warranty,
                    including those regarding the availability, accuracy of information provided, ability to reliably retain personal
                    information, or operability of this web site. By registering or using this service, you agree to abide by these terms of
                    service, as well as our privacy policy.
                </p>
            </Section>

            <Section>
                <Title>Member Conduct</Title>
                <p style={paragraphStyle}>
                    You understand that all material posted on this web site is the sole responsibility of the person from whom such content
                    originated. Bellino does not censor or correct material submitted (although we reserve the right to do this in the future).
                    Therefore, you understand that you may be exposed to content that is objectionable, offensive, or indecent. In addition, you
                    may be exposed to information that is inaccurate. belllino.com will not be responsible in any way for any content that is
                    posted to this site, as well as any harm, loss, or damage resulting from this content. Bellino does not attempt to claim
                    copyright or ownership of material that you submit, however, rateyourmusic claims a non-exclusive worldwide, royalty free,
                    perpetual, non-revocable license to reproduce your material (Otherwise, we would not be able to display it to other users).
                    In addition, you agree to give other users the same license to reprint or save your material for their personal use. Bellino
                    reserves the right to refuse, move, or delete any content that is submitted by users for any reason.
                </p>
            </Section>

            <Section>
                <Title>Modifications to this service</Title>
                <p style={paragraphStyle}>
                    belllino.com reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the
                    service (or any part thereof) with or without notice. You agree that belllino.com shall not be liable to you or any third
                    party for any modification to or discontinuance of the service.
                </p>
            </Section>

            <Section>
                <Title>Questions or Comments</Title>
                <p style={paragraphStyle}>
                    Questions or comments concerning these terms of service can be sent us by feedback (bottom of every page). Certain pages on
                    the site include content from YouTube. Usage of this content is subject to the YouTube Terms of Service.
                </p>
            </Section>
        </AppMainContainer>
    );
}

export default TermsPage;
