import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ReleaseInfo } from '../../Types/ReleaseInfo';
import { BELLINO_BASE_URL, FormatDate, ConvertToArtistsArray, FetchPageData } from '../../Classes/Utilities';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import ReleaseCard from '../../Components/ReleaseCard/ReleaseCard';
import ToggleSelection from '../../Components/ToggleSelection/ToggleSelection';
import Preloader from '../../Components/Preloader/Preloader';

import classes from './NewReleases.module.css';

function NewReleases(props: any) {
    usePageMetdata({ title: 'New Releases' });

    const [searchParams] = useSearchParams();
    const orderBy = searchParams.get('order') ?? 'popularity';

    const [newReleases, setNewReleases] = useState<ReleaseInfo[]>([]);
    const [newReleasesOffset, setNewReleasesOffset] = useState(0);
    const [isMoreToScroll, setIsMoreToScroll] = useState(true);
    const [releaseCardSize, setReleaseCardSize] = useState(250);

    const isInit = useRef(false);
    const isLoading = useRef(false);
    const newReleasesListRef = useRef(null);
    const numOfNewReleases = newReleases.length;

    useEffect(() => {
        const handlePageScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;

            if (isMoreToScroll && scrollPos / document.body.clientHeight > 0.8) {
                setNewReleasesOffset(numOfNewReleases);
            }
        };

        window.removeEventListener('scroll', handlePageScroll);
        window.addEventListener('scroll', handlePageScroll);

        return () => window.removeEventListener('scroll', handlePageScroll);
    }, [numOfNewReleases, isMoreToScroll]);

    useEffect(() => {
        const handlePageResize = () => {
            const newReleasesList = newReleasesListRef?.current ? (newReleasesListRef.current as HTMLUListElement) : null;
            const gridWidth = newReleasesList ? Math.ceil(newReleasesList.clientWidth + 1) : 0;
            const maxCardSize = 350;
            const minCardSize = 230;
            const minGridGap = 25; // = 2rem

            if (gridWidth > 0) {
                const numOfItems = Math.floor(gridWidth / (minCardSize + minGridGap));
                const newCardSize = Math.floor(gridWidth / numOfItems - minGridGap - 1);

                if (newCardSize >= minCardSize) {
                    setReleaseCardSize(Math.min(newCardSize, maxCardSize));
                }
            }
        };

        if (numOfNewReleases > 0) {
            handlePageResize();
        }

        window.removeEventListener('resize', handlePageResize);
        window.addEventListener('resize', handlePageResize);

        return () => window.removeEventListener('resize', handlePageResize);
    }, [numOfNewReleases, isMoreToScroll]);

    useEffect(() => {
        if (!isLoading.current) {
            isLoading.current = true;
            setNewReleases([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/newreleases?order=${orderBy === 'popularity' ? 'popular' : orderBy}`).then(
                (response: any) => {
                    if (response.success && response.result?.newReleases) {
                        const releases = response.result?.newReleases?.map((release: any) => {
                            return {
                                id: release.id,
                                name: release.name,
                                date: FormatDate(release.date, 'Short'),
                                genres: release.genres ?? [],
                                imgPath: release.imgPath,
                                artists: release.artists ?? []
                            } as ReleaseInfo;
                        });
                        setNewReleases(releases);
                        isLoading.current = false;
                        isInit.current = true;
                    }

                    isLoading.current = false;
                }
            );
        }
    }, [orderBy]);

    useEffect(() => {
        if (newReleasesOffset > 0 && !isLoading.current) {
            isLoading.current = true;

            fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetLatestAlbums&data=${orderBy === 'date' ? orderBy : ''}&offset=${newReleasesOffset}`,
                {
                    method: 'GET'
                }
            )
                .then(response => {
                    if (!response.ok) {
                        isLoading.current = false;
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(response => {
                    if (response?.length) {
                        const releases = response.map((release: any) => {
                            return {
                                id: release.ID,
                                name: release.Name,
                                date: FormatDate(release.ReleasedDate, 'Short'),
                                genres: release.Genre?.split(';') ?? [],
                                imgPath: release.Image,
                                artists: ConvertToArtistsArray(release.ArtistID, release.Artist)
                            } as ReleaseInfo;
                        });
                        setNewReleases(oldArray => [...oldArray, ...releases]);

                        isLoading.current = false;
                    } else {
                        setIsMoreToScroll(false);
                    }
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                    isLoading.current = false;
                });
        }
    }, [orderBy, newReleasesOffset]);

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={newReleasesOffset === 0 && isLoading.current && !isInit.current}>
            {
                <Section>
                    <Title>New Releases</Title>
                    <div className="mb-100">
                        <ToggleSelection
                            selectedValue={orderBy === 'popularity' ? 'By Popularity' : 'By Date'}
                            optionL={{
                                href: '/newreleases?order=popularity',
                                value: 'By Popularity'
                            }}
                            optionR={{ href: '/newreleases?order=date', value: 'By Date' }}
                        ></ToggleSelection>
                    </div>
                    {newReleasesOffset === 0 && isLoading.current ? (
                        <Preloader viewMode="Inline"></Preloader>
                    ) : (
                        <ul ref={newReleasesListRef} className={classes.newReleasesGrid}>
                            {newReleases?.map(release => (
                                <li key={release.id}>
                                    <ReleaseCard cardSize={releaseCardSize} releaseInfo={release} showArtists={true}></ReleaseCard>
                                </li>
                            ))}
                        </ul>
                    )}

                    {newReleasesOffset > 0 && isMoreToScroll && isLoading.current && <Preloader viewMode="Inline"></Preloader>}
                </Section>
            }
        </AppMainContainer>
    );
}

export default NewReleases;
