import React, { useMemo } from 'react';

import { CommentInfo } from '../../Types/CommentInfo';
import { MusicItemType } from '../../Types/MusicItemType';
import { addComment, deleteComment } from '../../Services/Common';
import { AnalyzedText } from '../../Classes/jsxUtilities';

import Link from '../Link/Link';
import UserAvatar from '../UserAvatar/UserAvatar';
import UsersLikes from '../UsersLikes/UsersLikes';
import LiveDate from '../LiveDate/LiveDate';

import classes from './UsersComments.module.css';

export type UsersCommentsProps = {
    itemType: MusicItemType;
    itemId: string;
    comments: CommentInfo[];
    activeUserInputComment?: { userId: string; userImgPath: string; userFirstName: string; userLastName: string };
    onAddComment?: (commentInfo: CommentInfo) => void;
    onDeleteComment?: (commentId: string) => void;
};

function UsersComments(props: UsersCommentsProps) {
    const { itemType, itemId, activeUserInputComment, comments, onAddComment, onDeleteComment } = props;

    const onUserInputChange = (event: React.KeyboardEvent) => {
        if (event && event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
            const textareaElement = event?.target as HTMLTextAreaElement;
            const newCommentText = textareaElement?.value?.trim();

            if (newCommentText?.length > 0 && activeUserInputComment) {
                addComment(itemType, itemId, newCommentText);
                onAddComment?.({
                    comment: newCommentText,
                    date: new Date(),
                    userId: activeUserInputComment.userId,
                    userFirstName: activeUserInputComment.userFirstName,
                    userLastName: activeUserInputComment.userLastName ?? '',
                    userImgPath: activeUserInputComment.userImgPath,
                    id: Date.now().toString(),
                    isMyComment: true
                });

                textareaElement.value = '';
                textareaElement.blur();
            }
        }
    };

    // Sort comments by date ASC + handle links
    const sortedComments = useMemo(
        () =>
            comments
                .sort((c1, c2) => {
                    return new Date(c1.date).getDate() - new Date(c2.date).getDate();
                })
                .map(commentInfo => {
                    return { ...commentInfo, commentJSX: AnalyzedText(commentInfo.comment) };
                }),
        [comments]
    );

    return (
        <>
            {activeUserInputComment ? (
                <div className={classes.userCommentContainer}>
                    <div>
                        <UserAvatar
                            avatarSize="small"
                            viewMode="image-only"
                            userInfo={{
                                id: activeUserInputComment.userId,
                                firstName: activeUserInputComment.userFirstName,
                                lastName: activeUserInputComment.userLastName,
                                imgPath: activeUserInputComment.userImgPath
                            }}
                        ></UserAvatar>
                    </div>
                    <div className={`ml-100 ${classes.userInputComment}`}>
                        <textarea placeholder="Add your comment..." onKeyDown={onUserInputChange}></textarea>
                    </div>
                </div>
            ) : (
                <div className={classes.guestUserCommentContainer}>
                    <span>Please </span>
                    <Link href="/" textColor="dark-blue">
                        log in
                    </Link>
                    <span> to leave a comment.</span>
                </div>
            )}
            {sortedComments.map(comment => (
                <div key={comment.id} className={classes.userCommentContainer}>
                    <div>
                        <UserAvatar
                            avatarSize="small"
                            viewMode="image-only"
                            userInfo={{
                                id: comment.userId,
                                firstName: comment.userFirstName,
                                lastName: comment.userLastName,
                                imgPath: comment.userImgPath
                            }}
                        ></UserAvatar>
                    </div>
                    <div className="ml-100">
                        <div className="mb-50 fs-105">
                            <Link href={`/user?uid=${comment.userId}`} textColor="dark-blue">
                                <b>{`${comment.userFirstName} ${comment.userLastName}`}</b>
                            </Link>
                            <LiveDate utcDate={comment.date} showIcon={true} className="text-gray ml-100"></LiveDate>
                        </div>
                        {comment.commentJSX}
                        <div className="mt-100">
                            <UsersLikes itemType="comment" itemId={comment.id} usersLikeInfo={comment.usersLikeInfo}></UsersLikes>
                            {comment.userId === activeUserInputComment?.userId && (
                                <button
                                    className={'ml-100 fa fa-lg fa-solid fa-trash'}
                                    title="Delete Comment"
                                    onClick={async () => {
                                        const isDeleted = await deleteComment(comment.id);
                                        isDeleted && onDeleteComment?.(comment.id);
                                    }}
                                ></button>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default UsersComments;
