import React, { useState } from 'react';

import Button from '../../../Components/Button/Button';
import Dialog from '../../../Containers/Dialog/Dialog';
import GenresSelection from '../../../Components/GenresSelection/GenresSelection';

import classes from './GenresFilterDialog.module.css';

export type GenresFilterDialogProps = {
    isVisible: boolean;
    selectedGenres?: string[];
    onFilterValueChanged: (value: string[]) => void;
    onCloseDialog: () => void;
};

function GenresFilterDialog(props: GenresFilterDialogProps) {
    const { isVisible, selectedGenres, onCloseDialog, onFilterValueChanged } = props;

    const [newSelectedGenres, setNewSelectedGenres] = useState(selectedGenres ?? []);

    const onSubmit = () => {
        onFilterValueChanged(newSelectedGenres);
        onCloseDialog();
    };

    const onCancelAndClose = () => {
        onCloseDialog();
        setNewSelectedGenres(selectedGenres ?? []);
    };

    return (
        <Dialog isVisible={isVisible} title="Filter By Genres" onCloseDialog={onCancelAndClose}>
            <GenresSelection
                selectionMode="Multi-Selection"
                selectedGenres={newSelectedGenres}
                onSelectionChange={(newSelectedGenres: string[]) => {
                    setNewSelectedGenres(newSelectedGenres);
                }}
            ></GenresSelection>
            <div className={classes.footer}>
                <div>
                    <Button
                        viewMode="link"
                        textColor="dark-blue"
                        onClick={() => {
                            setNewSelectedGenres([]);
                        }}
                    >
                        Clear Selection
                    </Button>
                </div>
                <div>
                    <Button viewMode="fancy" onClick={onSubmit}>
                        Ok
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default GenresFilterDialog;
