import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BELLINO_BASE_URL, capitalizeFirstLetter, FetchPageData } from '../../Classes/Utilities';
import { Artist } from '../../Classes/Artist';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Breadcrumbs, { BreadcrumbsItem } from '../../Components/Breadcrumbs/Breadcrumbs';
import ChartTable, { ChartItem } from '../../Components/Charts/ChartTable/ChartTable';
import Link from '../../Components/Link/Link';
import Preloader from '../../Components/Preloader/Preloader';
import Tabs, { TabItem } from '../../Components/Tabs/Tabs';

enum ChartTimeFilter {
    Overall = 'Overall',
    LastYear = 'LastYear',
    LastMonth = 'LastMonth',
    LastWeek = 'LastWeek'
}

function ArtistCharts(props: any) {
    const { updatePageMetdata } = usePageMetdata();
    const [searchParams] = useSearchParams();

    const artistID = searchParams.get('rid');
    const chartType = searchParams.get('type')?.toLocaleLowerCase();
    const itemID = searchParams.get('itemID');
    const itemType = searchParams.get('itemType');

    const [artist, setArtist] = useState<Artist>(new Artist());
    const [chartItems, setChartItems] = useState<ChartItem[]>([]);
    const [chartItemName, setChartItemName] = useState('');
    const [chartTimeFilter, setChartTimeFilter] = useState('Overall');

    const isLoadingRef = useRef<boolean>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isLoadingRef.current && artistID) {
            isLoadingRef.current = true;
            setIsLoading(true);
            setChartItems([]);

            const maxDays =
                chartTimeFilter === ChartTimeFilter.LastYear
                    ? 365
                    : chartTimeFilter === ChartTimeFilter.LastMonth
                      ? 30
                      : chartTimeFilter === ChartTimeFilter.LastWeek
                        ? 7
                        : -1;

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/artistcharts?id=${artistID}&type=${chartType}&maxDyas=${maxDays}`).then(
                (response: any) => {
                    if (response.success) {
                        const artist = new Artist(response.result?.artistInfo);

                        updatePageMetdata({
                            title: `${artist.name} - Charts`,
                            description: `Watch charts, videos, pictures and listen free to "${artist.name}"`,
                            imageURL: artist.coverImgPath
                        });

                        setArtist(artist);
                        setChartItemName(response.result?.chartItemName ?? 0);
                        setChartItems(
                            response.result?.chartItems?.map((chartItem: any, index: number) => {
                                return {
                                    type: capitalizeFirstLetter(chartType ?? ''),
                                    id: chartItem.id,
                                    position: index + 1,
                                    score: chartItem.score,
                                    children:
                                        chartType === 'album' ? (
                                            <span key={chartItem.id}>
                                                <Link key={chartItem.id} href={`/album?aid=${chartItem.id}`}>
                                                    {chartItem.name}
                                                </Link>
                                            </span>
                                        ) : chartType === 'track' ? (
                                            <span key={chartItem.id}>
                                                <Link key={chartItem.id} href={`/track?tid=${chartItem.id}`}>
                                                    {chartItem.name}
                                                </Link>
                                            </span>
                                        ) : (
                                            <></>
                                        )
                                };
                            })
                        );
                    } else {
                        setArtist(new Artist());
                    }

                    setIsLoading(false);
                    isLoadingRef.current = false;
                }
            );
        }
    }, [artistID, chartType, itemID, itemType, chartTimeFilter, updatePageMetdata]);

    if (!artistID || !chartType || (!artist.isInit && !isLoading)) {
        return <NotFound></NotFound>;
    }

    let extraBreadcrumbs: BreadcrumbsItem[] = [];

    if (itemType && itemID) {
        if (itemType === 'track') {
            extraBreadcrumbs = [{ text: chartItemName, href: `/track?tid=${itemID}`, textColor: 'dark-gray' }];
        } else if (itemType === 'album') {
            extraBreadcrumbs = [{ text: chartItemName, href: `/album?aid=${itemID}`, textColor: 'dark-gray' }];
        } else if (itemType === 'artist') {
            extraBreadcrumbs = [
                {
                    text: chartItemName,
                    href: `/artist?aid=${itemID}`,
                    textColor: 'dark-gray'
                }
            ];
        }
    }

    const timeTabItems: TabItem[] = [
        {
            text: 'Overall',
            isSelected: chartTimeFilter === ChartTimeFilter.Overall,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.Overall);
            }
        },
        {
            text: 'Last Year',
            isSelected: chartTimeFilter === ChartTimeFilter.LastYear,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.LastYear);
            }
        },
        {
            text: 'Last Month',
            isSelected: chartTimeFilter === ChartTimeFilter.LastMonth,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.LastMonth);
            }
        },
        {
            text: 'Last Week',
            isSelected: chartTimeFilter === ChartTimeFilter.LastWeek,
            onClick: () => {
                setChartTimeFilter(ChartTimeFilter.LastWeek);
            }
        }
    ];

    return (
        <AppMainContainer showPreloader={!artist.isInit}>
            <Section backgroundColor="white">
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[
                            { text: artist.name, href: `/artist?aid=${artist.id}` },
                            {
                                text: `Top ${chartType.charAt(0).toUpperCase() + chartType.slice(1)}s`
                            },
                            ...extraBreadcrumbs
                        ]}
                    ></Breadcrumbs>
                </Title>
                <div className={`mb-100 `}>
                    <div>
                        <Tabs tabsItems={timeTabItems} viewMode="buttons"></Tabs>
                    </div>
                </div>
                {isLoading ? (
                    <Preloader viewMode="Inline"></Preloader>
                ) : chartItems.length > 0 ? (
                    <ChartTable chartItems={chartItems}></ChartTable>
                ) : (
                    <div className="mb-100">No users music activity within this time range</div>
                )}
            </Section>
        </AppMainContainer>
    );
}

export default ArtistCharts;
