import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { UserInfo } from '../../Types/User';
import { User } from '../../Classes/User';
import { BELLINO_BASE_URL, FetchPageData } from '../../Classes/Utilities';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import NotFound from '../NotFound/NotFound';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import UserAvatar from '../../Components/UserAvatar/UserAvatar';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import Search from '../../Components/Search/Search';
import Preloader from '../../Components/Preloader/Preloader';

import classes from './Friends.module.css';

function FriendsPage(props: any) {
    const { updatePageMetdata } = usePageMetdata();
    const { userSessionData, friendsIds } = useUserSessionData();
    const [searchParams] = useSearchParams();

    const userID = searchParams.get('uid') ?? userSessionData.userInfo?.id;
    const isLoading = useRef<boolean>();

    const [user, setUser] = useState<User>(new User());
    const [userFriends, setUserFriends] = useState<UserInfo[]>([]);
    const [filteredUserFriends, setFilteredUserFriends] = useState<UserInfo[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (!isLoading.current && userID) {
            isLoading.current = true;
            setUserFriends([]);
            setFilteredUserFriends([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/userfriends?id=${userID}`).then((response: any) => {
                let user = new User();

                if (response.success) {
                    user = new User(response.result?.userInfo);

                    updatePageMetdata({
                        title: `${user.fullName} - Friends`,
                        description: `View ${user.fullName}'s music profile on Bellino. Join Bellino to know music lovers like ${user.fullName} from all over the world`,
                        imageURL: user.userImg
                    });

                    setUserFriends(response.result?.friends ?? []);
                    setFilteredUserFriends(response.result?.friends ?? []);
                }

                setTimeout(() => {
                    setUser(user);
                    isLoading.current = false;
                }, 100);
            });
        }
    }, [userID, updatePageMetdata]);

    if ((!userID && userSessionData.isReady) || (!user.isInit && typeof isLoading.current !== 'undefined' && !isLoading.current)) {
        return <NotFound></NotFound>;
    }

    if (userSessionData.isReady && !userSessionData.userInfo?.id) {
        return <Navigate to="/home" />;
    }

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={!user.isInit}>
            <Section>
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[{ text: user.fullName, href: `/user?uid=${userID}` }, { text: `Friends (${userFriends.length})` }]}
                    ></Breadcrumbs>
                </Title>
                <div className="mb-150">
                    <Search
                        width="300px"
                        viewMode="rounded"
                        placeholder="Search Friends"
                        onSearch={searchText => {
                            return new Promise<any>(async resolve => {
                                if (searchText.trim().length > 0) {
                                    resolve(
                                        userFriends.filter(f =>
                                            `${f.firstName} ${f.lastName}`.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                                        )
                                    );
                                } else {
                                    resolve(userFriends);
                                }
                            });
                        }}
                        onSearching={status => {
                            setIsSearching(status);
                        }}
                        onSearchResults={(searchText, searchResults) => {
                            setFilteredUserFriends(searchText ? searchResults : userFriends);
                        }}
                    ></Search>
                </div>
                {isSearching ? (
                    <Preloader viewMode="Inline" animation="Search"></Preloader>
                ) : (
                    <ul className={classes.friendsGrid}>
                        {filteredUserFriends.map(user => (
                            <li key={user.id}>
                                <UserAvatar
                                    userInfo={user}
                                    viewMode="DetailedBox"
                                    friendshipButton={friendsIds.includes(user.id.toString()) ? 'unfriend' : 'add'}
                                ></UserAvatar>
                            </li>
                        ))}
                    </ul>
                )}
            </Section>
        </AppMainContainer>
    );
}

export default FriendsPage;
