import React, { useMemo } from 'react';

import { getImageFullPath } from '../../../Classes/Utilities';

import Card from '../../Card/Card';
import Link from '../../Link/Link';
import ItemPlaybar from '../../ItemPlaybar/ItemPlaybar';

import classes from './RecommendationMessage.module.css';
import { RecommendationInfo } from '../../../Types/Chat';

function RecommendationMessage(props: { recommendationInfo: RecommendationInfo }) {
    const { itemId, itemName, itemType, itemImagePath } = props.recommendationInfo;

    const itemImageFullPath = useMemo(() => getImageFullPath(itemImagePath, itemType), [itemImagePath, itemType]);
    const itemIdQueryParam = useMemo(() => (itemType === 'Track' ? 'tid' : 'aid'), [itemType]);

    return (
        <Card
            imgOverlays={[
                {
                    showMode: 'always',
                    className: classes.recommendationOverlay,
                    overlayChildren: (
                        <>
                            <ItemPlaybar viewMode="overlay" itemType={itemType} itemId={itemId}>
                                <div className={classes.recommendationLink}>
                                    <Link href={`/${itemType?.toLowerCase()}?${itemIdQueryParam}=${itemId}`}>
                                        <b>{itemName}</b>
                                    </Link>
                                </div>
                            </ItemPlaybar>
                        </>
                    )
                }
            ]}
            imgPath={itemImageFullPath}
        ></Card>
    );
}

export default RecommendationMessage;
