import React, { useEffect, useRef, useState } from 'react';

import { Gender, UserInfo } from '../../../Types/User';
import { User } from '../../../Classes/User';
import Button from '../../../Components/Button/Button';
import CountriesSelection from '../../../Components/CountriesSelection/CountriesSelection';

function AccountSettingsTab(props: { user: User; className: string; onSave: (userInfo: UserInfo) => void }) {
    const { user, className, onSave } = props;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [message, setMessage] = useState('');
    const [userCountry, setUserCountry] = useState<string>();

    const firstNameElementRef = useRef(null);
    const lastNameElementRef = useRef(null);
    const genderElementRef = useRef(null);
    const locationElementRef = useRef(null);
    const bDayElementRef = useRef(null);
    const bMonthElementRef = useRef(null);
    const bYearElementRef = useRef(null);

    const onInputChange = () => {
        const firstNameElement = firstNameElementRef?.current ? (firstNameElementRef.current as HTMLInputElement) : null;
        const lastNameElement = lastNameElementRef?.current ? (lastNameElementRef.current as HTMLInputElement) : null;

        if (firstNameElement?.value && lastNameElement?.value) {
            setIsSaveDisabled(false);
            setMessage('');
        } else {
            setIsSaveDisabled(true);
            setMessage('First and last name cannot be empty');
        }
    };

    const onDeleteAccount = () => {
        alert('onDeleteAccount');
    };

    const onSaveSettings = () => {
        const firstNameElement = firstNameElementRef?.current ? (firstNameElementRef.current as HTMLInputElement) : null;
        const lastNameElement = lastNameElementRef?.current ? (lastNameElementRef.current as HTMLInputElement) : null;
        const genderElement = genderElementRef?.current ? (genderElementRef.current as HTMLSelectElement) : null;
        const locationElement = locationElementRef?.current ? (locationElementRef.current as HTMLInputElement) : null;
        const bDayElement = bDayElementRef?.current ? (bDayElementRef.current as HTMLSelectElement) : null;
        const bMonthElement = bMonthElementRef?.current ? (bMonthElementRef.current as HTMLSelectElement) : null;
        const bYearElement = bYearElementRef?.current ? (bYearElementRef.current as HTMLSelectElement) : null;

        let birthday: Date | undefined;

        if (bDayElement?.value && bMonthElement?.value && bYearElement?.value) {
            birthday = new Date(parseInt(bYearElement.value), parseInt(bMonthElement.value), parseInt(bDayElement.value));
        }

        const newUserSettings: UserInfo = {
            id: user.id,
            email: user.email,
            firstName: firstNameElement?.value ?? user.firstName,
            lastName: lastNameElement?.value ?? user.lastName,
            gender: genderElement?.value as Gender,
            country: userCountry,
            location: locationElement?.value,
            birthday: birthday
        };

        onSave(newUserSettings);
        setIsSaveDisabled(true);
    };

    useEffect(() => {
        const firstNameElement = firstNameElementRef?.current ? (firstNameElementRef.current as HTMLInputElement) : null;
        const lastNameElement = lastNameElementRef?.current ? (lastNameElementRef.current as HTMLInputElement) : null;
        const genderElement = genderElementRef?.current ? (genderElementRef.current as HTMLSelectElement) : null;
        const locationElement = locationElementRef?.current ? (locationElementRef.current as HTMLInputElement) : null;
        const bDayElement = bDayElementRef?.current ? (bDayElementRef.current as HTMLSelectElement) : null;
        const bMonthElement = bMonthElementRef?.current ? (bMonthElementRef.current as HTMLSelectElement) : null;
        const bYearElement = bYearElementRef?.current ? (bYearElementRef.current as HTMLSelectElement) : null;

        if (firstNameElement) {
            firstNameElement.value = user.firstName;
        }
        if (lastNameElement) {
            lastNameElement.value = user.lastName;
        }
        if (genderElement && user.gender) {
            genderElement.value = user.gender;
        }

        setUserCountry(user.userCountry);

        if (locationElement && user.userLocation) {
            locationElement.value = user.userLocation;
        }
        if (user.userBirthday) {
            if (bDayElement) {
                bDayElement.value = user.userBirthday.getDate().toString();
            }
            if (bMonthElement) {
                bMonthElement.value = user.userBirthday.getMonth().toString();
            }
            if (bYearElement) {
                bYearElement.value = user.userBirthday.getFullYear().toString();
            }
        }
    }, [user]);

    return (
        <div style={{ padding: '1.25rem 2%' }}>
            <div className={className}>
                <div className="mb-100">Email</div>
                <div className="mb-100">{user.email}</div>
                <div>First Name</div>
                <input ref={firstNameElementRef} onChange={onInputChange}></input>
                <div>Last Name</div>
                <input ref={lastNameElementRef} onChange={onInputChange}></input>
                <div>Gender</div>
                <select ref={genderElementRef} onChange={onInputChange}>
                    <option>Male</option>
                    <option>Female</option>
                </select>
                <div>Country</div>
                <CountriesSelection
                    selectionMode="Single-Selection"
                    selectedCountries={userCountry ? [userCountry] : []}
                    onSelectionChange={userCountry => {
                        setUserCountry(userCountry[0] ?? '');
                        onInputChange();
                    }}
                ></CountriesSelection>
                <div>Location</div>
                <input ref={locationElementRef} onChange={onInputChange}></input>
                <div>Birthday</div>
                <div>
                    <select ref={bDayElementRef} style={{ width: '80px' }} onChange={onInputChange}>
                        <option key="empty" value=""></option>
                        {[...Array<number>(31).keys()].map(index => (
                            <option key={index + 1}>{index + 1}</option>
                        ))}
                    </select>
                    <select ref={bMonthElementRef} style={{ width: '80px' }} onChange={onInputChange}>
                        <option key="empty" value=""></option>
                        {months.map((month, index) => (
                            <option key={index} value={index}>
                                {month}
                            </option>
                        ))}
                    </select>
                    <select ref={bYearElementRef} style={{ width: '80px' }} onChange={onInputChange}>
                        <option key="empty" value=""></option>
                        {[...Array<number>(120).keys()].map(index => (
                            <option key={index}>{new Date().getFullYear() - index}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="mt-150">
                <Button viewMode="fancy" isDisabled={isSaveDisabled} onClick={onSaveSettings}>
                    Save Changes
                </Button>
                <Button className="ms-100" viewMode="fancy" isDisabled={true} onClick={onDeleteAccount}>
                    Delete Account
                </Button>
                {message && (
                    <span className="ms-100 fw-500" style={{ color: '#d14b4b' }}>
                        {message}
                    </span>
                )}
            </div>
        </div>
    );
}

export default AccountSettingsTab;
