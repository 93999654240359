import { Track } from '../../Classes/Track';
import { getTextFromHtml, SecondsToTime } from '../../Classes/Utilities';

const GOOGLE_KEY = 'AIzaSyDbnwG0B-MCGKwSeIMwU1PjPB8wWJImohc';

interface VideoItem {
    id: { videoId: string };
    contentDetails: {
        duration: number;
    };
    snippet: {
        title: string;
        channelTitle: string;
    };
}

interface YouTubeResponse {
    items: VideoItem[];
    error?: any;
}

export const convertYoutubeVideoDuration = (youtubeVideoDuration: any) => {
    var totalSeconds = 0;

    if (youtubeVideoDuration) {
        var parts = youtubeVideoDuration.match(/[0-9]+[HMS]/g);

        totalSeconds = 0;

        for (var i = 0; i < parts.length; i++) {
            var unit = parts[i].charAt(parts[i].length - 1);
            var amount = parseInt(parts[i].slice(0, -1));

            switch (unit) {
                case 'S':
                    totalSeconds += amount;
                    break;
                case 'M':
                    totalSeconds += amount * 60;
                    break;
                case 'H':
                    totalSeconds += amount * 3600;
                    break;
            }
        }
    }

    return {
        total_seconds: totalSeconds, // Duration as Total Seconds
        string: SecondsToTime(totalSeconds), // Duration as String
        youtube_obj: youtubeVideoDuration // Youtube API duration format
    };
};

export const extractVideoDuration = (videoData: any) => {
    if (videoData && videoData.pageInfo.totalResults > 0) {
        const youtubeVideoDuration = videoData.items[0].contentDetails.duration;
        return convertYoutubeVideoDuration(youtubeVideoDuration);
    }

    return {
        total_seconds: 0,
        string: SecondsToTime(0),
        youtube_obj: null
    };
};

export const getYoutubeVideoData = async (videoId: string) => {
    let videoData: any = undefined;

    if (videoId?.toString().trim()?.length > 0) {
        try {
            const response = await fetch(
                `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails,status&key=${GOOGLE_KEY}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                videoData = await response.json();

                if (videoData) {
                    videoData.duration = extractVideoDuration(videoData);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return videoData;
};

/*********************************************************************************************/

export const searchTrackYoutubeVideo = async (track: Track): Promise<any> => {
    if (!track || !track.id || !track.name) {
        return null;
    }

    try {
        const response = await fetch(
            `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${encodeURIComponent(track.fullName)}&type=video&maxResults=5&key=${GOOGLE_KEY}`,
            {
                method: 'GET'
            }
        );

        const responseData: YouTubeResponse = await response.json();

        if (responseData.error) {
            console.error('Error in YouTube API response:', responseData.error);
            return null;
        }

        let bestVideoId: string = '';
        let highestScore: number = 0;
        let isOfficial: boolean = false;

        const trackNameWords = track.fullName
            .toLowerCase()
            .split(/[\s-|(),"'\\/]+/)
            .filter(Boolean);

        const trackArtistName = track.artists
            .map(a => a.name)
            .join(', ')
            .toLowerCase();

        responseData.items.forEach((item, i) => {
            const videoID = item.id.videoId;
            const videoTitle = item.snippet.title.toLowerCase();
            const videoChannel = item.snippet.channelTitle.toLowerCase();

            // Skip unrelevant videos
            if (videoTitle.includes('cover') || (videoTitle.includes('remix') && !track.name.toLowerCase().includes('remix'))) {
                return null;
            }

            let videoNameWords = getTextFromHtml(videoChannel + ' ' + videoTitle)
                .split(/[\s-|(),"']+/)
                .filter(Boolean);
            videoNameWords = Array.from(new Set(videoNameWords)); // Avoid duplicate words

            let matchedWords = 0;
            let unmatchedWords = 0;
            let officialVideo = false;

            trackNameWords.forEach(word => {
                if (videoNameWords.includes(word)) matchedWords++;
            });

            videoNameWords.forEach(word => {
                if (!trackNameWords.includes(word)) unmatchedWords++;
            });

            if (videoChannel.includes('topic') || videoChannel.includes('official') || videoChannel.endsWith('vevo')) {
                officialVideo = true;
                unmatchedWords -= 1;
            } else if (trackArtistName.includes(videoChannel)) {
                officialVideo = true;
            }

            if (
                videoTitle.includes('official music video') ||
                videoTitle.includes('official lyric video') ||
                videoTitle.includes('official lyrics video')
            ) {
                officialVideo = true;
                unmatchedWords -= 3;
            } else if (['remastered', 'remaster'].some(term => videoTitle.includes(term))) {
                unmatchedWords -= 1;
            } else if (
                ['official video', 'official audio', 'lyrics video', 'lyric video', 'official visualiser'].some(suffix =>
                    videoTitle.includes(suffix)
                )
            ) {
                officialVideo = true;
                unmatchedWords -= 2;
            }

            const score = matchedWords / trackNameWords.length - 0.1 * (unmatchedWords + i);

            if (score > highestScore) {
                highestScore = score;
                bestVideoId = videoID;
                isOfficial = officialVideo;
            }
        });

        if (highestScore > 0.5) {
            const videoData = await getYoutubeVideoData(bestVideoId);

            return {
                videoID: bestVideoId,
                videoType: isOfficial ? 'Official' : 'Unofficial',
                videoDuration: videoData?.duration?.total_seconds ?? 0
            };
        }
    } catch (error) {
        console.error('Error fetching videos:', error);
    }

    return null;
};
