import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { removeUserNotification } from '../../redux/userSlice';

import { Message } from '../../Types/Chat';
import { htmlToText, isClickInsideElement } from '../../Classes/Utilities';
import { acceptFriendshipRequest, cancelFriendshipRequest } from '../../Services/User';

import GenericList, { GenericListItem } from '../GenericList/GenericList';
import Link from '../Link/Link';
import LiveDate from '../LiveDate/LiveDate';
import Button from '../Button/Button';

import classes from './Notifications.module.css';

export type NotificationsProps = {
    notifications: Message[];
};

export function Notifications(props: NotificationsProps) {
    const { notifications } = props;

    const dispatch = useDispatch<AppDispatch>();

    const anchorElement = useRef<HTMLButtonElement>(null);
    const boxElement = useRef<HTMLDivElement>(null);

    const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);

    const onNotificationsBtnClick = (event: React.MouseEvent) => {
        setIsNotificationsVisible(prev => !prev);
    };

    const notificationsAsListItems = useMemo(
        () =>
            notifications
                .slice()
                .sort((n1, n2) => {
                    return new Date(n1.date).getDate() - new Date(n2.date).getDate();
                })
                .map(notification => {
                    const { id, date, type, message, senderId, senderInfo } = notification;
                    const { firstName, lastName, imgPath } = senderInfo ?? { firstName: '', lastName: '', imgPath: undefined };
                    const fullSenderName = `${firstName} ${lastName}`;

                    return {
                        titleStr: fullSenderName,
                        href: type !== 'FR' ? `/messages?fid=${senderId}&mid=${id}` : undefined,
                        title: type === 'FR' ? <Link href={`/user?uid=${senderId}`}>{fullSenderName}</Link> : fullSenderName,
                        subTitle: (
                            <>
                                {type === 'FR' && <div>sent you friend request</div>}
                                {type === 'RC' && <div>{message ? htmlToText(message) : 'sent you recommandation'}</div>}
                                {type === 'PM' && <div>{htmlToText(message)}</div>}
                            </>
                        ),
                        subInfo: (
                            <>
                                <LiveDate utcDate={date} className="text-gray"></LiveDate>
                                {type === 'FR' && (
                                    <div className="mt-50">
                                        <Button
                                            viewMode="fancy"
                                            btnColor="blue"
                                            className="me-75"
                                            onClick={async () => {
                                                const isSuccess = await acceptFriendshipRequest(senderId, id);

                                                if (isSuccess) {
                                                    dispatch(removeUserNotification([id]));
                                                }
                                            }}
                                        >
                                            Accept
                                        </Button>
                                        <Button
                                            viewMode="fancy"
                                            btnColor="gray"
                                            onClick={async () => {
                                                const isSuccess = await cancelFriendshipRequest(senderId, id);

                                                if (isSuccess) {
                                                    dispatch(removeUserNotification([id]));
                                                }
                                            }}
                                        >
                                            Decline
                                        </Button>
                                    </div>
                                )}
                            </>
                        ),
                        image: imgPath,
                        imageHref: type === 'FR' ? `/user?uid=${senderId}` : undefined,
                        imageShape: 'circle',
                        onItemClick: () => {
                            setIsNotificationsVisible(false);
                        }
                    } as GenericListItem;
                }),
        [notifications, dispatch]
    );

    const anyNotifications = notifications.length;

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsNotificationsVisible(false);
                anchorElement.current && anchorElement.current.focus();
            } else if (event.key === 'Tab') {
            }
        };

        const checkForOutsideClick = (event: MouseEvent) => {
            const menuBoxRect = boxElement.current ? (boxElement.current as HTMLElement).getBoundingClientRect() : null;
            const anchorElementRect = anchorElement.current?.getBoundingClientRect?.();

            if (
                isNotificationsVisible &&
                anchorElement.current !== event.target &&
                !isClickInsideElement(event, menuBoxRect) &&
                !isClickInsideElement(event, anchorElementRect)
            ) {
                setIsNotificationsVisible(false);
            }
        };

        if (isNotificationsVisible) {
            window.addEventListener('click', checkForOutsideClick);
            window.addEventListener('keydown', onKeyDown);
        } else {
            window.removeEventListener('click', checkForOutsideClick);
            window.removeEventListener('keydown', onKeyDown);
        }

        return () => {
            window.removeEventListener('click', checkForOutsideClick);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [isNotificationsVisible]);

    return (
        <div className={classes.notificationsArea}>
            <button ref={anchorElement} className={classes.notificationsBtn} onClick={onNotificationsBtnClick}>
                <i className={`fa-solid fa-bell ${anyNotifications ? classes.notificationsOn : ''}`}></i>
            </button>
            {notifications.length > 0 && <div className={classes.notificationsCount}>{notifications.length}</div>}
            {isNotificationsVisible && (
                <div ref={boxElement} className={classes.notificationsBox}>
                    <h2 className={classes.notificationsHeaderTitle}>Notifications</h2>
                    {notifications.length ? (
                        <GenericList items={notificationsAsListItems}></GenericList>
                    ) : (
                        <div style={{ padding: '0 1rem' }}>You don't have new notifications</div>
                    )}
                </div>
            )}
        </div>
    );
}
