import React, { Children, useCallback, useEffect, useRef, useState } from 'react';

import { getElementFullWidth } from '../../Classes/Utilities';
import useDeviceData from '../../hooks/useDeviceData';

import classes from './SliderList.module.css';

export type SliderListProps = {
    children: any;
};

function SliderList(props: SliderListProps) {
    const arrayChildren = Children.toArray(props.children);

    const { hasTouchScreen } = useDeviceData();

    const DEFAULT_STEP_SIZE = 150;

    const [isHasSliderButtons, setIsHasSliderButtons] = useState(false);
    const [isLeftButtonDisabled, setIsLeftButtonDisabled] = useState(true);
    const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(true);
    const [moveStepSize, setMoveStepSize] = useState(DEFAULT_STEP_SIZE);

    const sliderElementRef = useRef<HTMLUListElement>(null);
    const sliderLeftButtonRef = useRef(null);
    const sliderRightButtonRef = useRef(null);
    const sliderTranslateX = useRef(0);

    const isElementOverflow = (element: HTMLElement) => {
        var curOverflow = element.style.overflow;

        if (!curOverflow || curOverflow === 'visible') {
            element.style.overflow = 'hidden';
        }

        var isOverflowing = element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
        element.style.overflow = curOverflow;

        return isOverflowing;
    };

    const onSlideLeft = () => {
        if (sliderElementRef.current) {
            const sliderElement = sliderElementRef.current as HTMLElement;
            sliderTranslateX.current = Math.min(0, sliderTranslateX.current + moveStepSize);
            sliderElement.style.transform = `translateX(${sliderTranslateX.current}px)`;

            if (sliderTranslateX.current === 0) {
                setIsLeftButtonDisabled(true);
            }

            setIsRightButtonDisabled(false);
        }
    };

    const onSlideRight = () => {
        if (sliderElementRef.current) {
            const sliderElement = sliderElementRef.current as HTMLElement;
            const maxLeft = sliderElement.offsetWidth - sliderElement.scrollWidth;
            sliderTranslateX.current = Math.max(maxLeft, sliderTranslateX.current - moveStepSize);
            sliderElement.style.transform = `translateX(${sliderTranslateX.current}px)`;

            if (sliderTranslateX.current <= maxLeft) {
                setIsRightButtonDisabled(true);
            }

            setIsLeftButtonDisabled(false);
        }
    };

    const initSlider = useCallback(() => {
        const sliderElement = sliderElementRef.current ? (sliderElementRef.current as HTMLElement) : null;

        if (sliderElement) {
            sliderElement.style.transform = '';
        }

        if (!hasTouchScreen && sliderElement && isElementOverflow(sliderElement)) {
            setIsHasSliderButtons(true);
            setIsLeftButtonDisabled(true);
            setIsRightButtonDisabled(false);

            // Calculate slider inner item with padding\margin and use it as step slider size
            const sliderInnerElements = sliderElement.querySelectorAll('li');
            if (sliderInnerElements?.length > 1) {
                setMoveStepSize(getElementFullWidth(sliderInnerElements[1]) ?? DEFAULT_STEP_SIZE);
            }
        } else {
            setIsHasSliderButtons(false);
        }
    }, [hasTouchScreen]);

    useEffect(() => {
        initSlider();
        window.addEventListener('resize', initSlider);
        return () => window.removeEventListener('resize', initSlider);
    }, [arrayChildren.length, initSlider]);

    if (!arrayChildren || arrayChildren.length <= 0) {
        return null;
    }

    return (
        <div className={classes.sliderContainer}>
            {isHasSliderButtons && (
                <button
                    disabled={isLeftButtonDisabled}
                    ref={sliderLeftButtonRef}
                    className={classes.sliderLeftBtn}
                    aria-label="Move Left"
                    onClick={onSlideLeft}
                >
                    <i className="fa fa-xl fa-angle-left"></i>
                </button>
            )}
            <ul ref={sliderElementRef} className={`${classes.list} ${hasTouchScreen ? classes.touch : ''}`}>
                {arrayChildren.map((item, index) => (
                    <li key={index} className={classes.listItem}>
                        {item}
                    </li>
                ))}
            </ul>
            {isHasSliderButtons && (
                <button
                    disabled={isRightButtonDisabled}
                    ref={sliderRightButtonRef}
                    className={classes.sliderRightBtn}
                    aria-label="Move Right"
                    onClick={onSlideRight}
                >
                    <i className="fa fa-xl fa-angle-right"></i>
                </button>
            )}
        </div>
    );
}

export default SliderList;
