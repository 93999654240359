import React from 'react';

import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';

function PrivacyPage(props: any) {
    usePageMetdata({ title: 'Privacy' });

    const { userSessionData } = useUserSessionData();

    const paragraphStyle = {
        fontSize: '1.2rem',
        lineHeight: '1.7em',
        letterSpacing: '0.5px',
        maxWidth: '1000px'
    };

    return (
        <AppMainContainer showPreloader={!userSessionData.isReady} backgroundColor="off-white">
            <Section>
                <Title level={1}>Privacy Policy</Title>
                <div className="text-gray fs-120 mb-75">Last updated: 04.05.2022</div>
                <p style={paragraphStyle}>
                    This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from
                    users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree
                    to the collection and use of information in accordance with this policy.
                </p>
            </Section>

            <Section>
                <Title>Information Collection And Use</Title>
                <p style={paragraphStyle}>
                    While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to
                    contact or identify you. Personally identifiable information may include, but is not limited to your name, age, email and
                    photo ("Personal Information").
                    <br />
                    <br />
                    <b>How can I correct my personal data?</b>
                    <br />
                    You have complete control over your personal data through the functionality offered on the site and can correct such data
                    yourself. If you have any questions, please get in touch using our site feedback link.
                    <br />
                    <br />
                    <b>How can I delete my personal data?</b>
                    <br />
                    In order to delete your personal data, you can go to your account settings and choose "request account deletion".
                    Alternatively, you can request deletion via our site feedback link.
                    <br />
                    <br />
                    <b>Are you collecting any data about me from any source other than myself?</b>
                    <br />
                    No. We do not purchase or collect data about people from third-party sources, and we don't attempt to match up or correlate
                    your data with any third-party data sources.
                </p>
            </Section>

            <Section>
                <Title>Cookies</Title>
                <p style={paragraphStyle}>
                    Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your
                    browser from a web site and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information.
                    You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
                    cookies, you may not be able to use some portions of our Site.
                </p>
            </Section>

            <Section>
                <Title>Security</Title>
                <p style={paragraphStyle}>
                    The security of your Personal Information is important to us, but remember that no method of transmission over the Internet,
                    or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your
                    Personal Information, we cannot guarantee its absolute security.
                </p>
            </Section>

            <Section>
                <Title>Changes To This Privacy Policy</Title>
                <p style={paragraphStyle}>
                    We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
                    periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will
                    constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy. If
                    we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided
                    us, or by placing a prominent notice on our website.
                </p>
            </Section>

            <Section>
                <Title>Contact Us</Title>
                <p style={paragraphStyle}>
                    If you have any questions about this Privacy Policy, please contact us by sending us feedback (at the bottom of every page).
                </p>
            </Section>
        </AppMainContainer>
    );
}

export default PrivacyPage;
