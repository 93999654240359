import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playPlaylist, appendPlaylist } from '../../redux/musicPlayerSlice';

import { User } from '../../Classes/User';
import { Track } from '../../Classes/Track';
import { TrackInfo } from '../../Types/Track';
import { PlaylistInfo } from '../../Types/PlaylistInfo';
import { BELLINO_BASE_URL, FetchPageData, GetNowUTCDate } from '../../Classes/Utilities';
import { createPlaylist, deletePlaylist, updatePlaylist } from '../../Services/User';
import useUserSessionData from '../../hooks/useUserSessionData';
import usePageMetdata from '../../hooks/usePageMetdata';

import NotFound from '../NotFound/NotFound';
import Preloader from '../../Components/Preloader/Preloader';
import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import Link from '../../Components/Link/Link';
import Button from '../../Components/Button/Button';
import ArtistLink from '../../Components/ArtistLink/ArtistLink';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import ItemPlaybar from '../../Components/ItemPlaybar/ItemPlaybar';
import MenuButton from '../../Components/MenuButton/MenuButton';
import TrackMenuButton from '../../Components/MenuButton/TrackMenuButton';
import LiveDate from '../../Components/LiveDate/LiveDate';

import PlaylistEditorDialog, { PlaylistEditorMode } from './partials/PlaylistEditorDialog';

import classes from './UsersPlaylists.module.css';

function UsersPlaylistsPage(props: any) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { userSessionData } = useUserSessionData();
    const { updatePageMetdata } = usePageMetdata();

    const dispatch = useDispatch<AppDispatch>();

    const userID = searchParams.get('uid') ?? userSessionData.userInfo?.id;
    const playlistID = searchParams.get('pid');
    const isMyPlaylits = userSessionData.userInfo?.id === userID;

    const isLoadingRef = useRef<boolean>();
    const [isLoading, setIsLoading] = useState(true);

    const [user, setUser] = useState<User>(new User());
    const [userPlaylists, setUserPlaylists] = useState<PlaylistInfo[]>([]);
    const [selectedPlaylistTracks, setSelectedPlaylistTracks] = useState<TrackInfo[]>([]);
    const [playlistEditorDialogMode, setPlaylistEditorDialogMode] = useState<PlaylistEditorMode>('hidden');

    const loadPlaylists = useCallback(() => {
        if (!isLoadingRef.current && userID) {
            isLoadingRef.current = true;
            setSelectedPlaylistTracks([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/userplaylists?uid=${userID}&pid=${playlistID ?? ''}`).then((response: any) => {
                if (response.success) {
                    const user = new User(response.result?.userInfo);

                    updatePageMetdata({
                        title: `${user.fullName} - Playlists`,
                        description: `View ${user.fullName}'s music profile on Bellino. Join Bellino to know music lovers like ${user.fullName} from all over the world`
                    });

                    setUser(user);
                    setUserPlaylists(response.result?.playlists);
                    setSelectedPlaylistTracks(
                        response.result?.selectedPlaylistTracks?.map((pt: any) => {
                            return {
                                id: pt.id,
                                name: pt.name,
                                artists: pt.artists ?? [],
                                videos: [
                                    {
                                        externalId: pt.video,
                                        duration: pt.duration
                                    }
                                ]
                            } as TrackInfo;
                        })
                    );
                } else {
                    setUser(new User());
                    setUserPlaylists([]);
                    setSelectedPlaylistTracks([]);
                }

                isLoadingRef.current = false;
                setIsLoading(false);
            });
        }
    }, [playlistID, userID, updatePageMetdata]);

    useEffect(() => {
        loadPlaylists();
    }, [loadPlaylists]);

    const selectedPlaylist = playlistID ? userPlaylists.filter(p => p.id.toString() === playlistID)?.[0] : userPlaylists?.[0];

    const playlistsAsListItems = useMemo(
        () =>
            userPlaylists?.map(playlist => {
                return {
                    titleStr: playlist.name,
                    title: playlist.name,
                    subTitle: `${playlist.numOfTracks} Tracks`,
                    subInfo: <LiveDate utcDate={playlist.date}></LiveDate>,
                    image: '/Images/playlist.png',
                    href: `/playlists?uid=${userID}&pid=${playlist.id}`,
                    isSelectedItem: playlist.id === selectedPlaylist?.id
                } as GenericListItem;
            }),
        [userID, userPlaylists, selectedPlaylist?.id]
    );

    const playlistTracksAsListItems = useMemo(
        () =>
            selectedPlaylistTracks?.map(track => {
                return {
                    titleStr: track.name,
                    title: <Link href={`/track?tid=${track.id}`}>{track.name}</Link>,
                    subTitle: <ArtistLink artists={track.artists}></ArtistLink>,
                    endSection: (
                        <div className={classes.trackToolbar}>
                            <ItemPlaybar viewSize="medium" itemType="Track" itemId={track.id} trackInfo={track}></ItemPlaybar>
                            <TrackMenuButton
                                buttonSize="medium"
                                buttonChildren={<i className="fa fa-ellipsis-v"></i>}
                                track={new Track(track)}
                                menuXPosition="Right"
                            ></TrackMenuButton>
                        </div>
                    )
                } as GenericListItem;
            }) ?? [],
        [selectedPlaylistTracks]
    );

    if ((!userID && userSessionData.isReady) || (!user.isInit && !isLoading)) {
        return <NotFound></NotFound>;
    }

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={!user.isInit}>
            <Section>
                <Title>
                    <Breadcrumbs
                        breadcrumbsItems={[
                            { text: user.fullName, href: `/user?uid=${user.id}` },
                            { text: 'Playlists' },
                            { text: selectedPlaylist?.name }
                        ]}
                    ></Breadcrumbs>
                </Title>
                <div className={classes.container}>
                    {selectedPlaylist ? (
                        <>
                            <div>
                                <div className="mt-100 mb-100">
                                    <Button
                                        viewMode="classic"
                                        onClick={() => {
                                            setPlaylistEditorDialogMode('create');
                                        }}
                                    >
                                        + New Playlist
                                    </Button>
                                </div>
                                <div className={classes.playlists}>
                                    <GenericList items={playlistsAsListItems} spaceBetweenItems={false}></GenericList>
                                </div>
                            </div>
                            <div>
                                <div className={classes.playlistToolbar}>
                                    <ItemPlaybar
                                        itemType="Playlist"
                                        viewMode="inline"
                                        viewSize="medium"
                                        onPlayClick={() => {
                                            dispatch(playPlaylist(selectedPlaylistTracks));
                                        }}
                                        onPlusClick={() => {
                                            dispatch(appendPlaylist(selectedPlaylistTracks));
                                        }}
                                    ></ItemPlaybar>
                                    <span>{selectedPlaylist.name}</span>
                                    <span>
                                        {isMyPlaylits && (
                                            <>
                                                <button className={classes.playlistToolbarSaveChnges}>Save Changes</button>
                                                <MenuButton
                                                    buttonView="transparent"
                                                    buttonSize="medium"
                                                    //menuXPosition="Right"
                                                    menuSections={[
                                                        {
                                                            menuItems: [
                                                                {
                                                                    text: 'Add Tracks',
                                                                    onClick: () => {
                                                                        setPlaylistEditorDialogMode('add-tracks');
                                                                    }
                                                                },
                                                                {
                                                                    text: 'Rename Playlist',
                                                                    onClick: () => {
                                                                        setPlaylistEditorDialogMode('rename');
                                                                    }
                                                                },

                                                                {
                                                                    text: 'Delete Playlist',
                                                                    onClick: () => {
                                                                        setPlaylistEditorDialogMode('delete');
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]}
                                                ></MenuButton>
                                            </>
                                        )}
                                    </span>
                                </div>
                                <div className={classes.playlistTracks}>
                                    {playlistTracksAsListItems.length > 0 ? (
                                        <GenericList items={[...playlistTracksAsListItems]} viewSize="medium"></GenericList>
                                    ) : isLoading ? (
                                        <Preloader viewMode="Inline"></Preloader>
                                    ) : (
                                        <div className={classes.emptyPlaylist}>Empty playlist</div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>{`${user.fullName} has no playlists`}</p>
                    )}
                </div>
            </Section>

            {selectedPlaylist && (
                <PlaylistEditorDialog
                    mode={playlistEditorDialogMode}
                    playlistName={selectedPlaylist.name}
                    onCreate={async (newPlaylistName, playlistTracksIDS) => {
                        const playlistID = await createPlaylist(newPlaylistName, playlistTracksIDS);

                        if (playlistID) {
                            const newPlaylistsArray = [
                                {
                                    id: playlistID,
                                    name: newPlaylistName,
                                    date: new Date(),
                                    numOfTracks: playlistTracksIDS?.length ?? 0
                                } as PlaylistInfo
                            ].concat(userPlaylists);

                            setUserPlaylists(newPlaylistsArray);

                            // Update URL to view new created playlist
                            searchParams.set('pid', playlistID);
                            setSearchParams(searchParams);
                        }
                    }}
                    onUpdate={async (newPlaylistName, newPlaylistTracksIds) => {
                        const newPlaylistTracklistIds = selectedPlaylistTracks.map(t => t.id).concat(newPlaylistTracksIds ?? []);
                        const isUpdated = await updatePlaylist(selectedPlaylist.id, newPlaylistName, newPlaylistTracklistIds);

                        if (isUpdated) {
                            const newPlaylistsArray = userPlaylists.map(p => {
                                return p.id === selectedPlaylist.id
                                    ? { ...p, name: newPlaylistName, tracksIds: newPlaylistTracklistIds, date: GetNowUTCDate() }
                                    : p;
                            });

                            if (newPlaylistTracksIds?.length) {
                                //TODO: maybe find better way to append new tracks without reload all playlists
                                loadPlaylists();
                            } else {
                                setUserPlaylists(newPlaylistsArray);
                            }
                        }
                    }}
                    onDelete={async () => {
                        const isDeleted = await deletePlaylist(selectedPlaylist.id);

                        if (isDeleted) {
                            const newPlaylistsArray = userPlaylists.filter(p => p.id !== selectedPlaylist.id);
                            setUserPlaylists(newPlaylistsArray);

                            if (searchParams.has('pid')) {
                                searchParams.delete('pid');
                                setSearchParams(searchParams);
                            }
                        }
                    }}
                    onCloseDialog={() => {
                        setPlaylistEditorDialogMode('hidden');
                    }}
                ></PlaylistEditorDialog>
            )}
        </AppMainContainer>
    );
}

export default UsersPlaylistsPage;
