import React from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../redux/store';
import { playTrack, appendTrack, playPlaylist, appendPlaylist } from '../../redux/musicPlayerSlice';

import { TrackInfo } from '../../Types/Track';
import { MusicItemType } from '../../Types/MusicItemType';

import { getAlbumTracksPlaylist } from '../../Services/Album';
import { getTrackVideoInfo } from '../../Services/Tracks';

import classes from './ItemPlaybar.module.css';
import { setActiveDialog } from '../../redux/uiSlice';

export type ItemPlaybarProps = {
    itemId?: string;
    itemType: MusicItemType;
    trackInfo?: TrackInfo;
    viewMode?: 'inline' | 'overlay';
    viewSize?: 'small' | 'medium' | 'large';
    customCSS?: React.CSSProperties;
    children?: any;
    onPlayClick?: () => void;
    onPlusClick?: () => void;
};

function ItemPlaybar(props: ItemPlaybarProps) {
    const { itemId, itemType, customCSS, trackInfo, onPlayClick, onPlusClick } = props;

    const viewMode = props.viewMode ?? 'inline';
    const viewSize = props.viewSize ?? 'small';

    const iconSize = viewSize === 'large' ? 'fa-2x' : viewSize === 'medium' ? 'fa-xl' : 'fa-lg';
    const buttonsStyle = customCSS ? { ...customCSS } : {};

    const dispatch = useDispatch<AppDispatch>();

    const onItemPlay = async () => {
        if (onPlayClick) {
            onPlayClick();
        } else if (itemId) {
            if (itemType === 'Track' || trackInfo) {
                const trackData = trackInfo ?? (await getTrackVideoInfo(itemId));

                if (trackData?.videos?.length) {
                    dispatch(playTrack(trackData));
                } else {
                    dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: itemType }));
                }
            } else if (itemType === 'Album') {
                const albumPlaylist = await getAlbumTracksPlaylist(itemId);

                if (albumPlaylist.length > 0) {
                    dispatch(playPlaylist(albumPlaylist));
                } else {
                    dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: itemType }));
                }
            }
        }
    };

    const onItemPlus = async () => {
        if (onPlusClick) {
            onPlusClick();
        } else if (itemId) {
            if (itemType === 'Track' || trackInfo) {
                const trackData = trackInfo ?? (await getTrackVideoInfo(itemId));

                if (trackData?.videos?.length) {
                    dispatch(appendTrack(trackData));
                } else {
                    dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: itemType }));
                }
            } else if (itemType === 'Album') {
                const albumPlaylist: TrackInfo[] = await getAlbumTracksPlaylist(itemId);

                if (albumPlaylist.length > 0) {
                    dispatch(appendPlaylist(albumPlaylist));
                } else {
                    dispatch(setActiveDialog({ activeDialog: 'NO_VIDEOS', activeDialogData: itemType }));
                }
            }
        }
    };

    return (
        <>
            {viewMode === 'inline' ? (
                <div className={classes.itemPlaybar}>
                    <div>
                        {
                            /* For now - do not show play buttons for artsit */
                            itemType !== 'Artist' && (!trackInfo || trackInfo?.videos?.length) && (
                                <>
                                    <button
                                        className={classes.playItem}
                                        style={buttonsStyle}
                                        aria-label={`Play ${itemType}`}
                                        onClick={onItemPlay}
                                    >
                                        <i className={`fa fa-play-circle ${iconSize}`}></i>
                                    </button>
                                    <button
                                        className={classes.plusItem}
                                        style={buttonsStyle}
                                        aria-label={`Append ${itemType} to current playlist`}
                                        onClick={onItemPlus}
                                    >
                                        <i className={`fa fa-plus-circle ${iconSize}`}></i>
                                    </button>
                                </>
                            )
                        }
                    </div>
                    <div>{props.children}</div>
                </div>
            ) : (
                <>
                    <button
                        className={`play_rls ${classes.icon} ${classes.playItemIcon}`}
                        style={{ background: `url('Images/play-w-48.ico') center no-repeat` }}
                        aria-label={`Play ${itemType}`}
                        onClick={onItemPlay}
                    ></button>
                    <button
                        className={`plus_rls ${classes.icon} ${classes.plusItemIcon}`}
                        style={{ background: `url('Images/plus-w-48.ico') center no-repeat` }}
                        aria-label={`Append ${itemType} to current playlist`}
                        onClick={onItemPlus}
                    ></button>
                    {props.children}
                </>
            )}
        </>
    );
}

export default ItemPlaybar;
