import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Tabs.module.css';

export type TabItem = {
    text: string;
    href?: string;
    isSelected?: boolean;
    onClick?: () => void;
};

export type TabsProps = {
    tabsItems: TabItem[];
    viewMode: 'classic' | 'simple' | 'buttons';
};

function Tabs(props: TabsProps) {
    const viewModeClasses =
        props.viewMode === 'classic'
            ? { tabsContainer: classes.tabsContainer, tab: classes.tab, selectedTab: classes.selectedTab }
            : props.viewMode === 'simple'
              ? { tabsContainer: classes.simpleTabsContainer, tab: classes.simpleTab, selectedTab: classes.simpleTabSelected }
              : { tabsContainer: classes.buttonsTabsContainer, tab: classes.buttontab, selectedTab: classes.buttonSelectedTab };

    return (
        <div className={viewModeClasses.tabsContainer}>
            {props.tabsItems.map((tabItem, index) => (
                <span key={index} className={tabItem.isSelected ? `${viewModeClasses.tab} ${viewModeClasses.selectedTab}` : viewModeClasses.tab}>
                    {tabItem.href ? <Link to={tabItem.href}>{tabItem.text}</Link> : <button onClick={tabItem.onClick}>{tabItem.text}</button>}
                </span>
            ))}
        </div>
    );
}

export default Tabs;
