import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ToggleSelection.module.css';

export type ToggleSelectionProps = {
    optionL: ToggleSelectionOption;
    optionR: ToggleSelectionOption;
    selectedValue: string;
};

export type ToggleSelectionOption = {
    href: string;
    value: string;
};

function ToggleSelection(props: ToggleSelectionProps) {
    const optionL =
        props.selectedValue === props.optionL.value ? (
            <span className={`${classes.option} ${classes.optionL} ${classes.selectedOption}`}>{props.optionL.value}</span>
        ) : (
            <Link to={props.optionL.href} className={`${classes.option} ${classes.optionL}`}>
                {props.optionL.value}
            </Link>
        );

    const optionR =
        props.selectedValue === props.optionR.value ? (
            <span className={`${classes.option} ${classes.optionR} ${classes.selectedOption}`}>{props.optionR.value}</span>
        ) : (
            <Link to={props.optionR.href} className={`${classes.option} ${classes.optionR}`}>
                {props.optionR.value}
            </Link>
        );

    return (
        <>
            {optionL}
            {optionR}
        </>
    );
}

export default ToggleSelection;
