import React from 'react';

import { MusicItemType } from '../../../Types/MusicItemType';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../Button/Button';

import classes from './NoVideosDialog.module.css';

export type NoVideosDialogProps = {
    isVisible: boolean;
    itemType: MusicItemType;
    onCloseDialog: () => void;
};

function NoVideosDialog(props: NoVideosDialogProps) {
    const { itemType, isVisible, onCloseDialog } = props;
    const message = `Sorry! we have no videos for this ${itemType?.toLowerCase()}.`;

    return (
        <Dialog isVisible={isVisible} title={':('} onCloseDialog={onCloseDialog}>
            <div style={{ textAlign: 'left' }}>{message}</div>
            <div className={classes.footer}>
                <Button viewMode="fancy" onClick={onCloseDialog}>
                    OK
                </Button>
            </div>
        </Dialog>
    );
}

export default NoVideosDialog;
