import React, { useMemo, useRef, useState } from 'react';

import { BELLINO_BASE_URL, handleSearchText } from '../../../Classes/Utilities';
import Search from '../../../Components/Search/Search';
import Menu, { MenuSection } from '../../../Components/Menu/Menu';

export type BellinoSearchProps = {
    searchText: string;
    searchResults?: MenuSection[];
    onSearchResultsChanged: (searchTerm: string, results: any) => void;
};

export function BellinoSearch(props: BellinoSearchProps) {
    const { searchText, searchResults = [], onSearchResultsChanged } = props;

    const [showResults, setShowResults] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const refSearchElement = useRef<HTMLElement>();
    const isSearchingRef = useRef(isSearching);
    const searchTextRef = useRef(searchText);
    const searchResultsRef = useRef(searchResults);

    searchTextRef.current = searchText;
    searchResultsRef.current = searchResults;

    const onSearch = (text: string) => {
        return new Promise<any>(resolve => {
            if (text.trim().length > 0) {
                const encodedSerachText = handleSearchText(text);

                fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchMusicAndPeople&search=${encodedSerachText}`, {
                    method: 'GET'
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(response => {
                        if (response) {
                            const artistsResults: MenuSection = { menuItems: [] };
                            const albumsResults: MenuSection = { menuItems: [] };
                            const tracksResults: MenuSection = { menuItems: [] };
                            const usersResults: MenuSection = { menuItems: [] };

                            response.forEach((serachResult: any) => {
                                const resultItemType = serachResult?.[0];
                                const resultItemId = serachResult?.[1];
                                const resultItemName = serachResult?.[2];

                                if (resultItemType === 'Artist') {
                                    artistsResults.menuItems.push({
                                        text: resultItemName,
                                        href: `/artist?aid=${resultItemId}`,
                                        iconName: 'fa-star'
                                    });
                                } else if (resultItemType === 'Album') {
                                    albumsResults.menuItems.push({
                                        text: resultItemName,
                                        href: `/album?aid=${resultItemId}`,
                                        iconName: 'fa-compact-disc'
                                    });
                                } else if (resultItemType === 'Track') {
                                    tracksResults.menuItems.push({
                                        text: resultItemName,
                                        href: `/track?tid=${resultItemId}`,
                                        iconName: 'fa-music'
                                    });
                                } else if (resultItemType === 'User') {
                                    usersResults.menuItems.push({
                                        text: resultItemName,
                                        href: `/user?uid=${resultItemId}`,
                                        iconName: 'fa-user'
                                    });
                                }
                            });

                            resolve([artistsResults, albumsResults, tracksResults, usersResults].filter(s => s.menuItems.length));
                        } else {
                            resolve([]);
                        }
                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });
            } else {
                resolve([]);
            }
        });
    };

    const serachResultsMenuOptions = useMemo(
        () =>
            isSearching
                ? [
                      {
                          menuItems: [{ text: 'Searching...' }]
                      }
                  ]
                : searchResults.length > 0
                  ? searchResults
                  : searchText
                    ? [
                          {
                              menuItems: [{ text: 'No results' }]
                          }
                      ]
                    : [],
        [isSearching, searchText, searchResults]
    );

    return (
        <>
            <Search
                viewMode="rounded"
                placeholder="Search Music & People"
                searchTerm={searchText}
                onSearch={onSearch}
                onClick={() => {
                    setShowResults(Boolean(searchResultsRef.current.length || isSearchingRef.current || searchTextRef.current));
                }}
                onReady={(refInputElement, refContainerElement) => {
                    refSearchElement.current = refContainerElement;
                }}
                onSearching={status => {
                    setIsSearching(status);
                    setShowResults(true);
                }}
                onSearchResults={(searchTerm, results) => {
                    onSearchResultsChanged(searchTerm, results);
                }}
            ></Search>
            <Menu
                isOpen={showResults}
                onClose={() => setShowResults(false)}
                anchorElement={refSearchElement.current}
                xPosition="Left"
                parentType="fixed"
                sections={serachResultsMenuOptions}
            ></Menu>
        </>
    );
}
