import React, { useEffect, useRef, useState } from 'react';

import { User } from '../../Classes/User';
import { getUsersBaseDetails } from '../../Services/User';

import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import Dialog from '../../Containers/Dialog/Dialog';
import Preloader from '../../Components/Preloader/Preloader';
import Link from '../../Components/Link/Link';

export type FriendsListener = {
    userId: string;
    itemType: 'Artist' | 'Album' | 'Track';
    itemID: string;
    itemNumOfPlays: number;
};

export type FriendsListenersDialogProps = {
    isVisible: boolean;
    friendsListeners: FriendsListener[];
    onCloseDialog: () => void;
};

function FriendsListenersDialog(props: FriendsListenersDialogProps) {
    const { friendsListeners, isVisible, onCloseDialog } = props;

    const isLoading = useRef<boolean>();
    const [friendsListenerItems, setFriendsListenerItems] = useState<GenericListItem[]>([]);

    useEffect(() => {
        if (isVisible && !isLoading.current && friendsListeners.length > 0) {
            isLoading.current = true;
            setFriendsListenerItems([]);

            getUsersBaseDetails(friendsListeners.map(f => f.userId)).then(friendsInfo => {
                isLoading.current = false;

                setFriendsListenerItems(
                    friendsListeners.map(friendsListener => {
                        const { itemType, itemID, userId } = friendsListener;
                        const friendInfo = friendsInfo.find(f => f.id.toString() === userId.toString());

                        const playsLink = itemType === 'Track' ? 'listenings' : 'usercharts';
                        const user = new User(friendInfo);

                        return {
                            titleStr: user.fullName,
                            title: (
                                <div className="fs-105">
                                    <Link href={`/user?uid=${user.id}`}>{user.fullName}</Link>
                                </div>
                            ),
                            subInfo: (
                                <div className="fs-105">
                                    <Link
                                        href={`/${playsLink}?uid=${user.id}&type=track&itemType=${itemType.toLowerCase()}&itemID=${itemID}`}
                                    >{`${friendsListener.itemNumOfPlays} plays`}</Link>
                                </div>
                            ),
                            image: user.userImg,
                            imageHref: `/user?uid=${user.id}`,
                            imageShape: 'circle'
                        } as GenericListItem;
                    })
                );
            });
        }
    }, [isVisible, friendsListeners]);

    return (
        <Dialog isVisible={isVisible} title="Friends Who Listened To It" onCloseDialog={onCloseDialog}>
            {isLoading.current !== false ? <Preloader viewMode="Inline"></Preloader> : <GenericList items={friendsListenerItems}></GenericList>}
        </Dialog>
    );
}

export default FriendsListenersDialog;
