import React, { useEffect, useRef } from 'react';

import useUserSessionData from '../../hooks/useUserSessionData';
import { MusicItemType } from '../../Types/MusicItemType';

import { getUserItemRating, rateItem, unrateItem } from '../../Services/Ratings';

import Dialog from '../../Containers/Dialog/Dialog';
import Button from '../../Components/Button/Button';
import GuestDialog from './GuestDialog';

import classes from './RateDialog.module.css';

export type RateDialogProps = {
    itemType: MusicItemType;
    itemId: string;
    isVisible: boolean;
    onCloseDialog: () => void;
};

function RateDialog(props: RateDialogProps) {
    const { itemType, itemId, isVisible, onCloseDialog } = props;

    const { userSessionData } = useUserSessionData();
    const ratingElementRef = useRef(null);
    const isLoading = useRef(true);
    const isRatedBefore = useRef(false);

    const onSaveRating = () => {
        const ratingElement = ratingElementRef?.current ? (ratingElementRef.current as HTMLSelectElement) : null;

        if (ratingElement?.value) {
            rateItem(itemType, itemId, parseInt(ratingElement.value), !isRatedBefore.current);
        } else {
            unrateItem(itemType, itemId);
        }

        //console.log('onRating: ' + ratingElement?.value);
        onCloseDialog();
    };

    useEffect(() => {
        const updateCurrentRating = async () => {
            const userRating = await getUserItemRating(itemType, itemId);

            const ratingElement = ratingElementRef?.current ? (ratingElementRef.current as HTMLSelectElement) : null;

            if (ratingElement && typeof userRating === 'number') {
                ratingElement.value = userRating.toString();
                isRatedBefore.current = true;
            }

            isLoading.current = false;
        };

        if (isVisible && userSessionData.userInfo?.id) {
            updateCurrentRating();
        }
    }, [isVisible, itemType, itemId, userSessionData.userInfo?.id]);

    if (!userSessionData.userInfo?.id) {
        return (
            <GuestDialog
                isVisible={isVisible}
                userActionDescription={`rate ${itemType.toLocaleLowerCase()}s`}
                onCloseDialog={onCloseDialog}
            ></GuestDialog>
        );
    }

    return (
        <Dialog isVisible={isVisible} title={`Rate ${itemType}`} onCloseDialog={onCloseDialog}>
            <span className="me-75">Your Current Rating:</span>
            <select ref={ratingElementRef} className={classes.ratingInput}>
                <option value=""></option>
                {[...Array<number>(101).keys()].map(index => (
                    <option key={index}>{index}</option>
                ))}
            </select>
            <span> / 100</span>
            <div className={classes.footer}>
                <Button viewMode="fancy" onClick={onSaveRating}>
                    Save
                </Button>
            </div>
        </Dialog>
    );
}

export default RateDialog;
