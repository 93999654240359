import React from 'react';

import Link from '../Link/Link';

import classes from './Breadcrumbs.module.css';

export type BreadcrumbsItem = {
    text: string;
    href?: string;
    textColor?: string;
};

export type BreadcrumbsProps = {
    breadcrumbsItems: BreadcrumbsItem[];
};

function Breadcrumbs(props: BreadcrumbsProps) {
    return (
        <>
            {props.breadcrumbsItems.map((breadcrumbsItem, index) => (
                <span key={index} className={classes.breadcrumbsItem}>
                    {breadcrumbsItem.href ? (
                        <Link href={breadcrumbsItem.href} textColor={breadcrumbsItem.textColor ?? 'dark-blue'}>
                            {breadcrumbsItem.text}
                        </Link>
                    ) : (
                        <span>{breadcrumbsItem.text}</span>
                    )}
                </span>
            ))}
        </>
    );
}

export default Breadcrumbs;
